import {DisableContextHelper} from 'global-bundle';

export default class Accordion extends HTMLElement {
	static get CLASS_ACCORDION_ITEM() {
		return 'axs-j-accordion__item';
	}
	static get CLASS_ACCORDION_ITEM_HEADER() {
		return 'axs-j-accordion__item-clickarea';
	}
	static get CLASS_ACCORDION_ITEM_CONTENT() {
		return 'axs-j-accordion__item-content';
	}

	constructor() {
		super();
		this.handleClickOnItemHeader = this._handleClickOnItemHeader.bind(this);
		this.handleKeydownOnItemHeader = this._handleKeydownOnItemHeader.bind(this);
		this.openAccordionItem = undefined;
		this.randomIDNumber = Math.floor(Math.random() * 10000);
		this.items = [];
	}

	connectedCallback() {
		this._initialize();
	}

	_initialize() {
		const accordionItems = this.querySelectorAll('.' + Accordion.CLASS_ACCORDION_ITEM);
		const accordionItemsLength = accordionItems.length;

		this.setAttribute('role', 'presentation');

		for (let index = 0; index < accordionItemsLength; index++) {
			this._initializeAccordionItem(accordionItems[index], index);
		}
	}

	/**
	 * @param {HTMLElement} item_ - accordion item to be initialized
	 * @param {HTMLElement} childIndex_ - childIndex of item in accordion (this.items);
	 */
	_initializeAccordionItem(item_, childIndex_) {
		item_.setAttribute('data-item-index', childIndex_);

		if (!item_.hasAttribute('is-closed')) {
			item_.setAttribute('is-closed', true);
		}

		const itemObj = this._initObjectForAccordionItem(item_);

		this._setIDsOnHeaderAndContent(itemObj);
		this._setWAIAriaAttributesForItem(itemObj);

		itemObj.header.setAttribute('tabindex', '0');
		itemObj.header.addEventListener('click', this.handleClickOnItemHeader);
		itemObj.header.addEventListener('keydown', this.handleKeydownOnItemHeader);
	}

	/**
	 * All accordion actions (open/close) need header, content and the container of an item.
	 * Thatswhy the references are stored in an object for preventing costs of query-selection every time
	 * @param {HTMLElement} item_ - accordion item
	 * @returns {Object} - object with stored references for container, header and content
	*/
	_initObjectForAccordionItem(item_) {
		const itemObj = {
			container: item_,
			header: item_.querySelector('.' + Accordion.CLASS_ACCORDION_ITEM_HEADER),
			content: item_.querySelector('.' + Accordion.CLASS_ACCORDION_ITEM_CONTENT),
			disableContentHelper: null
		};

		itemObj.disableContentHelper = new DisableContextHelper(itemObj.content);

		this.items.push(itemObj);

		return itemObj;
	}

	/**
	 * sets id for item header and item content if they are not set.
	 * IDs are needed for coupling by wai-aria-attributes
	 * @param {Object} item_ - accordion item data object
	 * @property {HTMLElement} item_.container - item container
	 * @property {HTMLElement} item_.header - item header
	 * @property {HTMLElement} item_.content - item content
	 */
	_setIDsOnHeaderAndContent(item_) {
		const index = this._getIndexOfItem(item_.container);

		if (!item_.header.id) {
			item_.header.id = `axs-j-accordion-${this.randomIDNumber}-item-${index}-header`;
		}

		if (!item_.content.id) {
			item_.content.id = `axs-j-accordion-${this.randomIDNumber}-item-${index}-content`;
		}
	}

	/**
	 * @param {Object} item_ - accordion item data object
	 * @property {HTMLElement} item_.container - item container
	 * @property {HTMLElement} item_.header - item header
	 * @property {HTMLElement} item_.content - item content
	 */
	_setWAIAriaAttributesForItem(item_) {
		item_.header.setAttribute('role', 'button');
		item_.header.setAttribute('aria-controls', item_.content.getAttribute('id'));
		item_.content.setAttribute('role', 'region');
		item_.content.setAttribute('aria-labelledby', item_.header.getAttribute('id'));

		if (this._itemIsClosed(item_.container)) {
			this._closeAccordionItem(item_);
		}
		else {
			this._openAccordionItem(item_);
		}
	}

	/**
	 * @param {HTMLElement} item_ - accordion item
	 * @returns {boolean} - is item closed (true) or not (false)
	*/
	_itemIsClosed(item_) {
		return item_.getAttribute('is-closed') === 'true';
	}

	/**
	 * callback function for click on item header
	 * @param {Event} event_ - click event
	 */
	_handleClickOnItemHeader(event_) {
		const item = this._getClosestAccordionItem(event_.target);
		const itemIndex = this._getIndexOfItem(item);

		this._focusItemByIndex(itemIndex);
		this._toggleAccordionItemState(item);
	}

	/**
	 * @param {HTMLElement} item_ - accordion item
	 * @returns {number} - childindex
	*/
	_getIndexOfItem(item_) {
		return parseInt(item_.getAttribute('data-item-index'), 10);
	}

	/**
	 * @param {HTMLElement} clickTarget_ - the clicked child item
	 * @returns {HTMLElement} the clostet Accordion Item element
	 */
	_getClosestAccordionItem(clickTarget_) {
		return clickTarget_.closest('.' + Accordion.CLASS_ACCORDION_ITEM);
	}

	/**
	 * @param {number} index_ - child index of item in accordion (index of array 'this.items')
	 */
	_focusItemByIndex(index_) {
		const toFocusedItemHeader = this.items[index_].header;

		toFocusedItemHeader.focus();
	}

	/**
	 * @param {Object} item_ - accordion item data object of to be toggled item
	 * @property {HTMLElement} item_.container - item container
	 * @property {HTMLElement} item_.header - item header
	 * @property {HTMLElement} item_.content - item content
	 */
	_toggleAccordionItemState(item_) {
		const childIndex = this._getIndexOfItem(item_);
		const item = this.items[childIndex];

		if (!this.openAccordionItem || !item.container.isEqualNode(this.openAccordionItem)) {
			if (this.openAccordionItem) {
				const indexOpenItem = this._getIndexOfItem(this.openAccordionItem);

				this._closeAccordionItem(this.items[indexOpenItem]);
			}

			this._openAccordionItem(item);
		}
		else {
			this._closeAccordionItem(item);
			this.openAccordionItem = null;
		}
	}

	/**
	 * @param {Object} item_ - accordion item data object of item to be closed
	 * @property {HTMLElement} item_.container - item container
	 * @property {HTMLElement} item_.header - item header
	 * @property {HTMLElement} item_.content - item content
	 */
	_closeAccordionItem(item_) {
		item_.container.setAttribute('is-closed', true);
		item_.header.setAttribute('aria-expanded', false);
		item_.content.setAttribute('aria-hidden', true);
		item_.disableContentHelper.disableContext();
	}

	/**
	 * @param {Object} item_ - accordion item data object of item to be opened
	 * @property {HTMLElement} item_.container - item container
	 * @property {HTMLElement} item_.header - item header
	 * @property {HTMLElement} item_.content - item content
	 */
	_openAccordionItem(item_) {
		item_.container.setAttribute('is-closed', false);
		item_.header.setAttribute('aria-expanded', true);
		item_.content.setAttribute('aria-hidden', false);
		item_.disableContentHelper.enableContext();

		this.openAccordionItem = item_.container;
	}

	/**
	 * callback for keydown on accordion item header
	 * @param {Event} event_ - keydown event
	 */
	_handleKeydownOnItemHeader(event_) {
		let item;

		if ([13, 32, 35, 36, 38, 40].includes(event_.keyCode)) {
			event_.stopPropagation();
			event_.preventDefault();
		}

		switch (event_.keyCode) {
			case 13: /* enter */
			case 32: /* space */
				item = this._getClosestAccordionItem(event_.target);
				this._toggleAccordionItemState(item); break;
			case 35: /* end */ this._focusLastItem(); break;
			case 36: /* home */ this._focusFirstItem(); break;
			case 38: /* up */ this._focusPreviousItem(); break;
			case 40: /* down */this._focusNextItem(); break;
			default: break;
		}
	}

	_focusFirstItem() {
		this._focusItemByIndex(0);
	}

	_focusLastItem() {
		this._focusItemByIndex(this.items.length - 1);
	}

	_focusNextItem() {
		let index = this._getFocusedItemIndex();

		if (index === this.items.length - 1) {
			index = 0;
		}
		else {
			index++;
		}

		this._focusItemByIndex(index);
	}

	/**
	 * @returns {number} - index of focused item
	 */
	_getFocusedItemIndex() {
		const focusedItemHeader = this.querySelector('.' + Accordion.CLASS_ACCORDION_ITEM_HEADER + ':focus');
		const focusedItem = this._getClosestAccordionItem(focusedItemHeader);

		return this._getIndexOfItem(focusedItem);
	}

	_focusPreviousItem() {
		let index = this._getFocusedItemIndex();

		if (index === 0) {
			index = this.items.length - 1;
		}
		else {
			index--;
		}

		this._focusItemByIndex(index);
	}
}

if (window.customElements.get('audi-axs-accordion') === undefined) {
	window.customElements.define('audi-axs-accordion', Accordion);
}
