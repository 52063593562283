
import {swipe} from 'core-utils';

export default class CarousselSwipe {

	constructor(caroussel) {
		this.caroussel = caroussel;
		this.onCarousselSwipe = this._onCarousselSwipe.bind(this);
		this._initialize();
	}

	_initialize() {
		const carousselSelector = '#' + this.caroussel.carousselId;

		swipe.register(this.onCarousselSwipe, carousselSelector, {
			'swipedistance': 50
		});
	}

	_onCarousselSwipe(position) {
		const direction = position.direction.horizontal;

		if (direction === swipe.RIGHT) {
			this.caroussel.moveBack(direction);
		}

		if (direction === swipe.LEFT) {
			this.caroussel.moveForward(direction);
		}
	}
}

