import {dom} from 'core-utils';

var __ = {},
	exports = {__};

__.oDefaults = {
	classAccordion: 'axs-md-faq',
	classAccordionTabs: 'axs-md-faq-tabs',
	classAccordionTab: 'axs-md-faq-tab',
	classAccordionPanel: 'axs-md-faq-panel',
	classAccordionLabel: 'axs-md-accordion-label',
	classAccordionInput: 'axs-md-accordion-input'

};

__.onClickTab = function() {
	var _inputs = dom.getElementsArray('.' + __.oDefaults.classAccordionInput),
		i, closestItm,
		target = this,
		nextInput,
		isChecked = false;

	closestItm = dom.closest(target, '.axs-md-accordion-item');
	nextInput = dom.getElement('.axs-md-accordion-input', closestItm);

	if (nextInput.checked) {
		isChecked = true;
	}

	for (i = 0; i < _inputs.length; i++) {
		_inputs[i].checked = false;
	}

	if (isChecked) {
		nextInput.click();
	}
};

__.init = function() {
	dom.getEventDelegate('body').on('click', '.' + __.oDefaults.classAccordionLabel, __.onClickTab);
};

/**
 * public initialize method
 * @param {EventBus} globalEventBus - tbd
 * @returns {Promise} returns Promise
 */
exports.initialize = function(globalEventBus) {
	return new Promise(resolve => {
		__.globalEventBus = globalEventBus;
		__.init();

		resolve('accordion.js');
	});
};

export {exports as accordion};
