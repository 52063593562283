import DropDownList from './_dropdown-list';
import Select from './_select';
import {dom} from 'core-utils';

export default class DropDown extends HTMLElement {

	static get EVENT_DROPDOWN_ITEM_SELECTED() {
		return 'dropdown-item-selected';
	}

	/**
	 * constructor
	 */
	constructor() {
		super();
		this.modelfilter = null;
		this.toggleOpenStatus = this._toggleOpenStatus.bind(this);
		this.handleClickOutside = this._handleClickOutside.bind(this);
	}

	/**
	 * connectedCallback - callback for adding element to dom
	 */
	connectedCallback() {
		this._initialize();
		this._addEvents();
	}

	disconnectedCallback() {
		this._removeEvents();
	}

	_initialize() {
		this.modelfilter = dom.closest(this, '.axs-ondemand-configurator__modelfilter');
		this._initializeDropDownElements();
		this._initializeDropDownList();
	}

	_initializeDropDownElements() {
		this.currentSelectionContainer = this.querySelector('.axs-ondemand-configurator__modelfilter-dropdown-selection-container');
		this.currentSelectionValue = this.querySelector('.axs-ondemand-configurator__modelfilter-dropdown-selection-value');
		this.flyoutElement = this.parentElement.querySelector('.axs-ondemand-configurator__modelfilter-flyout');
	}

	_initializeDropDownList() {
		let dropDownListElement = this.querySelector('.axs-ondemand-configurator__modelfilter-dropdown-list');

		if (dropDownListElement) {
			this.isMobile = this._isMobileDevice();
			this.dropDownList = new DropDownList(this, dropDownListElement);
			this.selectElement = this.querySelector('.axs-ondemand-configurator__modelfilter-dropdown-select');

			if (this.selectElement) {
				this.select = new Select(this, this.selectElement);
			}
		}
	}

	_addEvents() {
		if (this.currentSelectionContainer) {
			this.currentSelectionContainer.addEventListener('click', this.toggleOpenStatus);
		}

		document.addEventListener('mousedown', this.handleClickOutside);
		document.addEventListener('touchstart', this.handleClickOutside);
	}

	_removeEvents() {
		this.currentSelectionContainer.removeEventListener('click', this.toggleOpenStatus);
		document.removeEventListener('mousedown', this.handleClickOutside);
		document.removeEventListener('touchstart', this.handleClickOutside);
	}

	/**
	 * @param {Event} event_ - the clickevent
	 */
	_handleClickOutside(event_) {
		if (this._isElementOutsideDropDown(event_.target)) {
			if (this.id !== 'bodytype' || this._isElementInAnyDropDown(event_.target)) {
				this.isOpen = false;
			}
		}
	}

	/**
	 *
	 * @param {HTMLElement} element_ - HTMLElement to be checked
	 * @returns {boolean} - whether the given element outside of the dropdown
	 */
	_isElementOutsideDropDown(element_) {
		let flyoutCondition = true;

		if (this.flyoutElement) {
			flyoutCondition = !this.flyoutElement.contains(element_);
		}

		return (!this.contains(element_) && flyoutCondition);
	}

	/**
	 * @param {HTMLElement} element_ - HTMLElement to be checked
	 * @returns {boolean} - whether the given element is part of any dropdown
	 */
	_isElementInAnyDropDown(element_) {
		let parent = dom.closest(element_, '.axs-ondemand-configurator__modelfilter-dropdown');

		return (!!parent);
	}

	/**
	 * @returns {boolean} whether the device is mobile or not
	 */
	_isMobileDevice() {
		return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i).test(navigator.userAgent);
	}

	/**
	 * @param {Object} selectedItemValues_ - values of selected item
	 * @param {Object} selectedItemValues_.label - label of selected item
	 */
	updateDropdownStyleAfterItemSelection(selectedItemValues_) {
		this._changeSelection(selectedItemValues_.label);
		this._toggleOpenStatus();
	}

	/**
	 * @param {CustomEvent} selectedItemValues_ - in dropdown-list/select selected items
	 */
	_informModelfilterAboutSelectedValue(selectedItemValues_) {
		let dropdownSelectItemEvent;
		let eventData = {
			detail: {
				dropdownId: this.id,
				selectedItemValues: {
					label: selectedItemValues_.label,
					value: selectedItemValues_.value
				}
			}
		};

		dropdownSelectItemEvent = new CustomEvent(DropDown.EVENT_DROPDOWN_ITEM_SELECTED, eventData);
		this.modelfilter.dispatchEvent(dropdownSelectItemEvent);
	}

	/**
	 * @param {string} label_ - the label to be set
	 */
	_changeSelection(label_) {
		this.currentSelectionValue.innerHTML = label_;
	}

	_toggleOpenStatus() {
		this.isOpen = !this.isOpen;
	}

	get isOpen() {
		return this.getAttribute('is-open') === 'true';
	}
	set isOpen(val_) {
		this.setAttribute('is-open', val_);
	}
	get isMobile() {
		return this.getAttribute('is-mobile') === 'true';
	}
	set isMobile(val_) {
		this.setAttribute('is-mobile', val_);
	}
	get id() {
		return this.getAttribute('dropdown-id');
	}
}

if (window.customElements.get('audi-axs-ondemand-configurator-modelfilter-dropdown') === undefined) {
	window.customElements.define('audi-axs-ondemand-configurator-modelfilter-dropdown', DropDown);
}
