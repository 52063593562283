class IframeMessageHandler {

	constructor() {
		this.iframeHeightSetters = [];
		this.handleWindowMessage = this._handleWindowMessage.bind(this);
		this._addEvents();
	}

	_addEvents() {
		window.addEventListener('message', this.handleWindowMessage);
	}

	/**
	 * @param {HTMLElement} iframe_ - iframe
	 * @param {Function} callback_ - function to call if message comes from given iframe
	 */
	registerIframe(iframe_, callback_) {
		const id = iframe_.iframeContent.getAttribute('id');

		this.iframeHeightSetters[id] = callback_;
	}

	/**
	 * @param {HTMLElement} iframe_ - iframe
	 */
	unregisterIframe(iframe_) {
		const id = iframe_.iframeContent.getAttribute('id');

		if (this.iframeHeightSetters[id]) {
			delete this.iframeHeightSetters[id];
		}
	}

	/**
	 * @param {MessageEvent} event_ - MessageEvent
	 * @property {string} event_.data - message data as stringified JSON
	 */
	_handleWindowMessage(event_) {
		const iframe = this._getIframeByEvent(event_);

		if (!iframe) {
			// message does not come from an iframe
			return;
		}

		const iframeHeight = this._getNewHeightFromEvent(event_);
		const callback = this.iframeHeightSetters[iframe.id];

		if (callback && iframeHeight) {
			callback(iframeHeight);
		}
	}

	/**
	 * @param {MessageEvent} event_ - message event
	 * @returns {(HTMLElement|undefined)} - iframe that posted message (event source)
	*/
	_getIframeByEvent(event_) {
		const iframes = document.getElementsByTagName('iframe');
		const iframesLength = iframes.length;

		for (let i = 0; i < iframesLength; i++) {
			if (iframes[i].contentWindow === event_.source) {
				return iframes[i];
			}
		}
	}

	/**
	 * @param {MessageEvent} event_ - message event
	 * @returns {(number|undefined)} - new iframe height
	 */
	_getNewHeightFromEvent(event_) {
		let parsedHeight;

		try {
			const payLoad = window.JSON.parse(event_.data);

			if (payLoad.height) {
				parsedHeight = parseInt(payLoad.height, 10);
			}

			return isNaN(parsedHeight) ? undefined : parsedHeight;
		}
		catch {
			return undefined;
		}
	}
}

const messageHandler = new IframeMessageHandler(); // --> always same instance of IframeMessageHandler is used!

export default messageHandler;
