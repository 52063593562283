const template = `
<li class="axs-md-socialreader-item" id="asoc_{{=it.id}}">
    <a href="{{=it.url}}" target="_blank">
        {{? !!it.gridImage }}
        <div class="axs-md-socialreader-media">
            <img class="axs-md-asoc-img" src="{{=it.gridImage}}" />
            {{? it.contentType == "video" }}
            <div class="axs-button-overlay">
                <svg class="axs-icon-system-play-large">
                    <use xlink:href="{{=SETUPS.get('DocrootJs')}}/../includes/core-ci/assets/icons/svg/play-large.svg#nm-icon-play-large"></use>
                </svg>
            </div>
            {{??}}
            <!--div class="axs-asoc-preview"><span class="axs-el-lbl axs-el-lbl-01 axs-at-lbl-inv">{{=window.i18n['preview']||''}}</span></div-->
            {{?}}
        </div>
        {{?}}
        <div class="axs-md-socialreader-text">            
            {{? it.title }}<h3 class="audi-headline-order-3 axs-el-hdl axs-el-hdl-03 axs-at-hdl-b">{{=it.title}}</h3>{{?}}
            <p class="audi-copy-m axs-el-pg axs-el-pg-03">{{=it.description}}</p>
        </div>
        <div class="axs-md-socialreader-via">                
            <svg class="axs-icon-system-{{=it.icon}}-large">
                <use xlink:href="{{=SETUPS.get('DocrootJs')}}/../includes/core-ci/assets/icons/svg/system-{{=it.icon}}-large.svg#system-{{=it.icon}}-large"></use>
            </svg>            
            <div class="axs-md-socialreader-infos audi-copy-s axs-el-pg axs-el-pg-04">
                <span class="axs-md-socialreader-date">{{=it.date}}</span>
                <span class="axs-md-socialreader-author">{{=it.author}}</span>
                <span class="axs-md-socialreader-gototext">{{=window.i18n['gotoarticle']||''}}</span>
            </div>                            
        </div>
    </a>
</li>
`;

export {template as socialreaderTemplate};
