export default class HubSearchFilter {
	static get CLASS_FILTER_CHECKBOX() { return 'axs-module-hub-search-filter-input'; }

	/**
	 * @param {Object} container_ - parent container
	 */
	constructor(container_) {
		this.parentContainer = container_;
		this._init();
	}

	_init() {
		this.filterChangeEvent = new CustomEvent('hubsearch_filter_change');
		this.filterNames = ['public', 'private'];

		this._initFilterState();
		this._initFilterCheckboxes();
	}

	/**
	 * initialize filterState from data-attribute
	 */
	_initFilterState() {
		this.filterState = {};

		this.filterNames.forEach(filterName => {
			this.filterState[filterName] = this.parentContainer.getAttribute(`data-show-${filterName}`) === 'true';
		});
	}

	_initFilterCheckboxes() {
		this.filterCheckboxes = Array.from(this.parentContainer.querySelectorAll(`.${HubSearchFilter.CLASS_FILTER_CHECKBOX}`));

		this.filterCheckboxes.forEach(filterCheckbox => {
			filterCheckbox.checked = this.filterState[filterCheckbox.value];
			filterCheckbox.addEventListener('change', this._handleFilterCheckboxChange.bind(this));
		});
	}

	/**
	 * @param {Event} event_ - change event
	 */
	_handleFilterCheckboxChange(event_) {
		const changedCheckbox = event_.target;
		const filterName = changedCheckbox.value;
		const isFilterActive = changedCheckbox.checked;

		this.filterState[filterName] = isFilterActive;

		const filterCheckboxesToAdjust = this.filterCheckboxes
			.filter(checkbox_ =>
				(changedCheckbox !== checkbox_) && // we don't change the clicked checkbox
				(filterName === checkbox_.value)); // we only want checkboxes for that filter type

		filterCheckboxesToAdjust.forEach(checkbox_ => {
			checkbox_.checked = isFilterActive;
		});

		event_.preventDefault();

		document.dispatchEvent(this.filterChangeEvent);
	}

	/**
	 * @returns {Array} - return active filters
	 */
	getActiveFilters() {
		const activeFilters = this.filterNames.filter(filterName => this.filterState[filterName]);

		return (activeFilters.length > 0) ? activeFilters : this.filterNames;
	}
}
