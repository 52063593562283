export default class HubSearchTemplateOpeningHours {

	static get SELECTOR_HUB_DETAIL_OPENING_TIMES() { return '.axs-module-hub-search__opening-times-wrapper'; }
	static get SELECTOR_HUB_DETAIL_OPENING_TIMES_INNER_WRAPPER() { return 'axs-module-hub-search__opening-times'; }
	static get SELECTOR_HUB_DETAIL_OPENING_TIMES_NOTES() { return 'axs-module-hub-search__opening-times-notes'; }
	static get SELECTOR_HUB_DETAIL_OPENING_TIMES_TOGGLE_CONTENT() { return 'axs-module-hub-search-opening-times-toggle-content'; }
	static get CLASS_HUB_DETAIL_OPENING_TIMES_SINGLE_DAY() { return 'axs-module-hub-search__opening-times-day'; }
	static get CLASS_HUB_DETAIL_OPENING_TIMES_SINGLE_DAY_TOGGLE() { return 'axs-module-hub-search__opening-times-toggle'; }
	static get CLASS_HUB_DETAIL_OPENING_TIMES_SINGLE_DAY_TIMES() { return 'axs-module-hub-search__opening-times-day-times'; }
	static get CLASS_HUB_DETAIL_WEBSITE_LINK() { return 'axs-module-hub-search-detail-link-choose'; }
	static get WEEKDAYS() { return ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']; }

	constructor(container_) {
		this.parentContainer = container_;
	}

	/**
	 * @param {Object} detailViewData_ - data object
	 */
	renderOpeningHoursForHub(detailViewData_) {
		let containerOpeningHours = this.parentContainer.querySelector(HubSearchTemplateOpeningHours.SELECTOR_HUB_DETAIL_OPENING_TIMES);
		let note = null;
		let openingHours = this._createOpeningHours(detailViewData_.openingHours.openingHours);

		containerOpeningHours.innerHTML = '';

		containerOpeningHours.appendChild(openingHours);

		if (detailViewData_.openingHours.note) {
			note = this._createOpeningHoursNote(detailViewData_.openingHours.note);
			containerOpeningHours.appendChild(note);
		}
	}

	/**
	 * @param {Array} openingHours_ - array der opening hours
	 * @returns {HTMLElement} - opening hours block
	 */
	_createOpeningHours(openingHours_) {
		let container = document.createElement('axs-module-hub-search-opening-times');
		let containerToggleContent = document.createElement('div');

		container.classList.add(HubSearchTemplateOpeningHours.SELECTOR_HUB_DETAIL_OPENING_TIMES_INNER_WRAPPER);
		containerToggleContent.classList.add(HubSearchTemplateOpeningHours.SELECTOR_HUB_DETAIL_OPENING_TIMES_TOGGLE_CONTENT);

		openingHours_.forEach((openingHoursItemArray, index) => {
			if (openingHoursItemArray.length > 0) {
				if (index === 0) {
					let item = this._createOpeningHoursToggleItem(openingHoursItemArray);

					container.appendChild(item);
				}
				else {
					let item = this._createOpeningHoursItem(openingHoursItemArray);

					containerToggleContent.appendChild(item);
				}
			}
		});

		container.appendChild(containerToggleContent);
		return container;
	}

	/**
	 * @param {Array} openingHoursItemArray_ - array of the opening hours of one day
	 * @returns {HTMLElement} - opening hours item
	 */
	_createOpeningHoursItem(openingHoursItemArray_) {
		let item = document.createElement('div');

		item.classList.add(HubSearchTemplateOpeningHours.CLASS_HUB_DETAIL_OPENING_TIMES_SINGLE_DAY);
		item.classList.add('audi-copy-s');
		item.innerHTML = this._getItemDayNameSnippet(openingHoursItemArray_) + this._getItemOpeningHoursSnippet(openingHoursItemArray_);

		return item;
	}

	/**
	 * @param {Array} openingHoursItemArray_ - array of the opening hours of one day
	 * @returns {HTMLElement} - opening hours item
	 */
	_createOpeningHoursToggleItem(openingHoursItemArray_) {
		let item = document.createElement('div');

		item.classList.add(HubSearchTemplateOpeningHours.CLASS_HUB_DETAIL_OPENING_TIMES_SINGLE_DAY);
		item.classList.add(HubSearchTemplateOpeningHours.CLASS_HUB_DETAIL_OPENING_TIMES_SINGLE_DAY_TOGGLE);
		item.innerHTML = this._getItemDayNameSnippet(openingHoursItemArray_) + this._getItemOpeningHoursSnippet(openingHoursItemArray_);

		item.classList.add('audi-copy-s');
		item.innerHTML += this._getItemOpeningHoursToggleSnippet();

		return item;
	}

	/**
	 * @returns {string} - HtmlString for the toggle icon
	 */
	_getItemOpeningHoursToggleSnippet() {
		return `<span class="axs-module-hub-search__opening-times-toggle-icon-wrapper">
					<svg class="audi-down-small axs-module-hub-search__opening-times-toggle-icon">
						<use xlink:href="${SETUPS.get('DocrootJs')}/../includes/core-ci/assets/icons/svg/down-small.svg#audi-down-small"></use>
					</svg >
				</span >`;
	}

	/**
	 *
	 * @param {Array} openingHoursItemArray_ - array of the items of a day
	 * @returns {string} - HTMLString of the day name
	 */
	_getItemDayNameSnippet(openingHoursItemArray_) {
		if (openingHoursItemArray_[0] && openingHoursItemArray_[0].dow) {
			let dayName = HubSearchTemplateOpeningHours.WEEKDAYS[openingHoursItemArray_[0].dow - 1];
			let dayNamei18N = window.i18n[`axs.ui.hubsearch.openhours.day.${dayName}`];

			return `<div class="axs-module-hub-search__opening-times-day-name">${dayNamei18N}</div>`;
		}

		return '';
	}

	/**
	 * @param {Array} openingHoursItemArray_ - array of the items of a day
	 * @returns {string} - HTMLString of the hours of a day
	 */
	_getItemOpeningHoursSnippet(openingHoursItemArray_) {
		let hoursSnippet = document.createElement('div');

		hoursSnippet.classList.add(HubSearchTemplateOpeningHours.CLASS_HUB_DETAIL_OPENING_TIMES_SINGLE_DAY_TIMES);

		openingHoursItemArray_.forEach((item) => {
			if (item.openTime && item.closeTime) {
				hoursSnippet.innerHTML += `<div>${this._getFormattedTimeRange(item.openTime, item.closeTime)}</div>`;
			}
			else {
				hoursSnippet.innerHTML = window.i18n['axs.ui.hubsearch.openhours.closed'];
			}
		});

		return hoursSnippet.outerHTML;
	}

	/**
	 * @param {string} openTime - a string of the opentime
	 * @param {string} closeTime - a string of the closeTime
	 * @returns {string} - combined string of openTime and closeTime
	 */
	_getFormattedTimeRange(openTime, closeTime) {
		return `${openTime.substring(0, 5)} - ${closeTime.substring(0, 5)}`;
	}

	/**
	 *
	 * @param {string} noteText_ - text for the node
	 * @returns {HTMLElement} - Note Element
	 */
	_createOpeningHoursNote(noteText_) {
		let note = document.createElement('div');

		note.classList.add(HubSearchTemplateOpeningHours.SELECTOR_HUB_DETAIL_OPENING_TIMES_NOTES);
		note.classList.add('audi-copy-s');
		note.innerHTML = noteText_;

		return note;
	}
}
