import HubSearchAPI from './api';
import HubSearchOffCanvas from './off-canvas';
import HubSearchMap from './map';
import HubSearchFilter from './filter';
import HubSearchTemplate from './template';
import './opening-hours';
import {getMainMarkerStyle, getDetailedMarkerStyle} from './markers';
import {dom} from 'core-utils';

export default class HubSearch extends HTMLElement {
	// constants
	static get SELECTOR_MAP_MAIN() { return '.axs-module-hub-search-map-main'; }
	static get OFF_CANVAS_WRAPPER() { return '.off-canvas-wrapper'; }

	/**
	* constructor (private)
	*/
	constructor() {
		super();
		this.isDesktopView = null;
		this.visibleHubs = [];
		this.templateData = [];
		this.mapMainContainer = null;
		this.detailViewIsShown = false;
	}

	/**
	* connectedCallback - callback for adding element to dom/shadow dom
	*/
	connectedCallback() {
		this._initializeModule();
	}

	/**
	* disconnectedCallback - callback for removing element from dom/shadow dom
	*/
	disconnectedCallback() {
		// TODO: check removal
		// console.log('remove <audi-hub-search> from DOM');
	}

	/**
	* _initializeModule (private) - inits module, bind events
	*/
	_initializeModule = () => {
		this.isDesktopView = HubSearchTemplate.isDesktopView;
		this.mapMainContainer = this.querySelector(HubSearch.SELECTOR_MAP_MAIN);

		this.HS_API = new HubSearchAPI();
		this.HS_API.getHubSearchData()
			.then(() => {
				this.HS_FILTER = new HubSearchFilter(this);
				this._updateVisibleHubs();

				this.HS_TEMPLATE = new HubSearchTemplate(this);
				this._updateTemplate();

				this.HS_OFF_CANVAS = new HubSearchOffCanvas(this);
				this._updateOffCanvas();

				this.HS_MAP_MAIN = new HubSearchMap(this);
				this.HS_MAP_MAIN.initMainMap();
				this._updateMainMapMarkers();

				this.HS_MAP_DETAIL = new HubSearchMap(this);
				this.HS_MAP_DETAIL.initDetailMap();
			});
		this._bindEvents();
	};

	/**
	* _updateVisibleHubs (private) - get filtered hubs
	*/
	_updateVisibleHubs() {
		let filters = this.HS_FILTER.getActiveFilters();

		this.visibleHubs = this.HS_API.getHubsByTypes(filters);
	}

	/**
	* _updateTemplate (private)
	*/
	_updateTemplate() {
		this.templateData = this._getHubsTemplateData(this.visibleHubs);
		this.HS_TEMPLATE.renderResultListForHubs(this.templateData);
	}

	/**
	* _updateOffCanvas (private)
	*/
	_updateOffCanvas() {
		if (this.isDesktopView) {
			this.HS_OFF_CANVAS.initCanvasForDesktop();
		}
		else {
			this.HS_OFF_CANVAS.initCanvasForMobile();
		}
	}

	/**
	* _updateMainMapMarkers (private)
	*/
	_updateMainMapMarkers() {
		let markersOfHubs = this.HS_API.getMarkerDataOfHubs(this.visibleHubs);

		this.HS_MAP_MAIN.setMarkersOnMap(markersOfHubs);
	}

	/**
	* _updateDetailMapForHub (private)
	* @param {Object} hub_ - hub that should be shown inside detail map
	*/
	_updateDetailMapForHub(hub_) {
		let markerDataOfHub = this.HS_API.getMapMarkerDataOfHub(hub_);
		let markers = [];

		markers.push(markerDataOfHub);
		this.HS_MAP_DETAIL.setMarkersOnMap(markers);
		this.HS_MAP_DETAIL.centerMarker(0);
	}

	/**
	* _getHubsTemplateData (private) - returns the data that is need at template for rendering result list and detail view
	* @param {Array} hubs_ - array of hub objects from api
	* @returns {Array} hubsTemplateData - array with template data for all hubs
	*/
	_getHubsTemplateData(hubs_) {
		let hubsTemplateData = [];
		let hubsLength = hubs_.length;

		for (let i = 0; i < hubsLength; i++) {
			let data = this._createTemplateDataObjectForHub(hubs_[i]);

			hubsTemplateData.push(data);
		}

		return hubsTemplateData;
	}

	/**
	* _createTemplateDataObjectForHub (private) - returns the data for one single hub
	* @param {Object} hub_ - hub object from api
	* @returns {Object} hubDetails - template data for single hub
	*/
	_createTemplateDataObjectForHub(hub_) {
		let hubDetails = {};

		hubDetails.openingHours = this.HS_API.getOpeningHoursOfHub(hub_);
		hubDetails.type = this.HS_API.getTypeOfHub(hub_);
		hubDetails.imageUrl = this.HS_API.getImageUrlOfHub(hub_);
		hubDetails.street = this.HS_API.getStreetOfHub(hub_);
		hubDetails.zipCode = this.HS_API.getZipCodeOfHub(hub_);
		hubDetails.city = this.HS_API.getCityOfHub(hub_);
		hubDetails.phoneInternational = this.HS_API.getPhoneNumberInternationalOfHub(hub_);
		hubDetails.phoneURL = this.HS_API.getPhoneUrlOfHub(hub_);
		hubDetails.email = this.HS_API.getEmailOfHub(hub_);
		hubDetails.name = this.HS_API.getNameOfHub(hub_);
		hubDetails.url = this.HS_API.getUrlOfHub(hub_);

		return hubDetails;
	}

	/**
	* _bindEvents (private)
	*/
	_bindEvents() {
		this.throttleResizeHandler = dom.throttle(this._handleWindowResizeEvent, 250);
		window.addEventListener('resize', this.throttleResizeHandler);
		document.addEventListener('hubsearch_filter_change', this._handleFilterChangeEvent);
		document.addEventListener('hubsearch_select_result_item', this._handleResultItemSelect);
		document.addEventListener('hubsearch_mouse_over_result_item', this._handleMouseOverOrMouseOffResultItem);
		document.addEventListener('hubsearch_mouse_off_result_item', this._handleMouseOverOrMouseOffResultItem);
		document.addEventListener('on_marker_click', this._handleOnMarkerClick);
		document.addEventListener('hubsearch_close_detailview', this._closeDetailView);
		document.addEventListener('on_marker_hover', this._handleOnMarkerHover);
		document.addEventListener('on_marker_hover_end', this._handleOnMarkerHoverEnd);
		document.addEventListener('offcanvas_panel_change', this._handleMobileOffcanvasPanelFlow);
	}

	/**
	* _handleOnMarkerHover (private) - callback function for start hovering on marker
	* @param {Event} event_ - mouseon event fired by document
	*/
	_handleOnMarkerHover = (event_) => {
		if (!this.detailViewIsShown) {
			let hoveredMarkerIndex = event_.detail.markerIndex; // ID that marker has in maps.markersOnMapArray;

			this.HS_TEMPLATE._highlightResultListEntryForHoveredMarker(hoveredMarkerIndex);
		}
	};

	/**
	* _handleOnMarkerHoverEnd (private) - callback function for end of hovering on marker
	*/
	_handleOnMarkerHoverEnd = () => {
		this.HS_TEMPLATE._removeHighlightFromResultListEntry();
	};

	/**
	* _handleWindowResizeEvent (private)
	*/
	_handleWindowResizeEvent = () => {
		let overBreakpoint = (window.innerWidth >= HubSearchTemplate.BREAKPOINT);

		if (overBreakpoint && this.isDesktopView === false || !overBreakpoint && this.isDesktopView === true) {
			// execute only for changes on breakpoint
			this.isDesktopView = !this.isDesktopView;
			this._closeDetailView();
			this._updateOffCanvas();
		}
	};

	/**
	* _handleFilterChangeEvent (private)
	*/
	_handleFilterChangeEvent = () => {
		this._updateVisibleHubs();
		this._updateTemplate();
		this._updateMainMapMarkers();
	};

	/**
	* _handleResultItemSelect (private)
	*/
	_handleResultItemSelect = () => {
		let selectedHubIndex = this.HS_TEMPLATE.getSelectedEntryIndex();

		this._openDetailView({selectedHubIndex, wasDetailViewOpenFromMap: false});
	};

	/**
	* _handleMouseOverOrMouseOffResultItem (private)
	*/
	_handleMouseOverOrMouseOffResultItem = () => {
		if (this.isDesktopView) {
			let markerIndex = this.HS_TEMPLATE.getSelectedEntryIndex();

			this.HS_MAP_MAIN.toggleHighlightOnMarkerByMarkerIndex(markerIndex);
		}
	};

	/**
	* _handleOnMarkerClick (private)
	* @param {Event} event_ - click event on marker from utils/map
	*/
	_handleOnMarkerClick = (event_) => {
		// ID that marker has in maps.markersOnMapArray;
		const selectedHubIndex = event_.detail.markerIndex;

		if (this.isDesktopView || !this.detailViewIsShown) {
			// click on a marker means the user reaches the detailview from map
			this._openDetailView({selectedHubIndex, wasDetailViewOpenFromMap: true});
		}
	};

	/**
	* _closeDetailView (private) - close the detail view on desktop
	* @param {Event} event - Custom event
	* @param {Object} event.detail - Object
	* @param {Object} event.detail.forceDestination - 'hub' or 'map'
	*/
	_closeDetailView = (event) => {
		this.detailViewIsShown = false;
		this._updateVisibleHubs();

		if (this.isDesktopView) {
			this._initMainMapForDesktop();
		}
		else {
			this._updateTemplate();
		}

		this.HS_OFF_CANVAS.toggleBackward();

		if (!this.isDesktopView
			&& event
			&& event.detail
			&& event.detail.forceDestination
			&& event.detail.forceDestination === 'map') {
			this.HS_OFF_CANVAS.toggleBackward();
		}

		this.HS_TEMPLATE._removeHighlightFromResultListEntry();
	};

	_initMainMapForDesktop() {
		this.HS_MAP_MAIN.initMarkersStyle(getMainMarkerStyle());
		this._updateMainMapMarkers();
		this.HS_MAP_MAIN.zoomMap();
		this.HS_MAP_MAIN.moveMap();
	}

	/**
	* _openDetailView (private) - Open detail view for hub with given index (also, at desktop view change markers on map)
	* @param {number} hubIndex_ - hub index of hub inside visibleHubs array
	*/
	_openDetailView = ({selectedHubIndex: hubIndex_, wasDetailViewOpenFromMap}) => {
		const detailData = this.templateData[hubIndex_];
		const detailLogic = {wasDetailViewOpenFromMap};

		this.detailViewIsShown = true;

		this.HS_TEMPLATE.renderDetailViewForHub(detailData, detailLogic);

		if (this.isDesktopView) {
			this._showSelectedHubOnMainMap(hubIndex_);
			this.HS_OFF_CANVAS.toggleForward();
		}
		else {
			this._updateDetailMapForHub(this.visibleHubs[hubIndex_]);
			this.HS_OFF_CANVAS.toggleToLast();
			this._handleMobileOffcanvasPanelFlow();
		}

		this.visibleHubs = [this.visibleHubs[hubIndex_]];
	};

	/**
	 * _getActualPanelIndex (private) - reads and returns the index of the
	 * off-canvas panel actually being shown
	 * @returns {number} - the index (0 - n)
	 */
	_getActualPanelIndex = () => {
		let offCanvasWrapper = this.querySelector(HubSearch.OFF_CANVAS_WRAPPER);

		if (offCanvasWrapper) {
			return offCanvasWrapper.getAttribute('data-active-index');
		}
	};

	/**
	 * _handleMobileOffcanvasPanelFlow (private) - evaluates the component state (desktop/mobile)
	 * and triggers full-screen actions according to the off-canvas panel hierarchy
	 */
	_handleMobileOffcanvasPanelFlow = () => {
		if (!this.isDesktopView) {
			if (this._getActualPanelIndex() > 0) {
				this._setFullscreen(this);
			}
			else {
				this._removeFullscreen(this);
			}
		}
	};

	/**
	 * _setFullscreen (private) - sets a data - attribute flagging the
	 * full-screen state of the element
	 * @param {HTMLElement} element_ - the element to manipulate
	 */
	_setFullscreen = (element_) => {
		element_.setAttribute('data-full-screen', 'active');
	};

	/**
	 * _removeFullscreen(private) removes the data - attribute flagging
	 * the full-screen state of the element
	 * @param {HTMLElement} element_ - the element to manipulate
	 */
	_removeFullscreen = (element_) => {
		element_.removeAttribute('data-full-screen');
	};

	/**
	* _showSelectedHubOnMainMap - change zoomlevel, set new center like in detail map on mobile
	* @param {number} hubIndex_ - index of selected hub
	*/
	_showSelectedHubOnMainMap(hubIndex_) {
		this._resetMap();
		this._highlightDetailMarker(hubIndex_);
	}

	/**
	 *
	 * @param {number} hubIndex_ - the number of the marker to highlight
	 */
	_highlightDetailMarker(hubIndex_) {
		this.HS_MAP_MAIN.setMarkerStyleByIndex(hubIndex_, getDetailedMarkerStyle());
		this.HS_MAP_MAIN.zoomMap(HubSearchMap.ZOOM_LEVEL_STREET);
		this.HS_MAP_MAIN.centerMarker(hubIndex_);
	}

	_resetMap() {
		this.HS_MAP_MAIN.setInitialValues();
		this.HS_MAP_MAIN.resetMapHovers();
		this.HS_MAP_MAIN.initMarkersStyle(getMainMarkerStyle());
	}
}

customElements.define('audi-hub-search', HubSearch);
