const template = `
{
	"pin": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAqCAYAAADFw8lbAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA2ZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDoxMjk5OThEQTQwMjA2ODExOTk0QzhGQTU2ODQ1Rjg0QiIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo0NzNEMzlFRTMxRjAxMUU0QTY3NDg0MTVCMURDNDI4QSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo0NzNEMzlFRDMxRjAxMUU0QTY3NDg0MTVCMURDNDI4QSIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ1M1IE1hY2ludG9zaCI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjEyOTk5OERBNDAyMDY4MTE5OTRDOEZBNTY4NDVGODRCIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjEyOTk5OERBNDAyMDY4MTE5OTRDOEZBNTY4NDVGODRCIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+A48hrwAAAMVJREFUeNpi/P//P8NQAEwMQwQMGYeyIHPOMpoEAKl+IFYYLA40/n+GEcWhUEeuHwpR3z9U0qjCaGYadeioQ0cdOurQUYeOOnTUoaMOHXXoqENHHTrq0FGHjmiHHhgKDv0AxIUwDguJmi9ADaA1ANkz0fj/mQfkOLQRqLEBXfAsowlFLgKaSZQ6Yh2aCDRwwWDPTAPuSEIhCkqLjkBHXhjMxdOgciQuh14YbI7EFvUwR35gGGSAaSg4EgQYR6dvRqpDAQIMAIe1NQM36mVkAAAAAElFTkSuQmCC",
	"pin_inactive": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAqCAYAAADFw8lbAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA2ZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDoxMjk5OThEQTQwMjA2ODExOTk0QzhGQTU2ODQ1Rjg0QiIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo2RDM2REMwNzMxRjAxMUU0QTY3NDg0MTVCMURDNDI4QSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo2RDM2REMwNjMxRjAxMUU0QTY3NDg0MTVCMURDNDI4QSIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ1M1IE1hY2ludG9zaCI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjEyOTk5OERBNDAyMDY4MTE5OTRDOEZBNTY4NDVGODRCIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjEyOTk5OERBNDAyMDY4MTE5OTRDOEZBNTY4NDVGODRCIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+qQgpqQAAAMRJREFUeNpi/P//P8NQAEwMQwQMGYeyIHP6p84MAFFArDBYHFiYnc6I4lCoI9cPhajvHyppVGE0M406dNShow4ddeioQ0cdOurQUYeOOnTUoaMOHXXoqENHtEMPDAWHfgDiQhiHhUTNF6AG0BqA7JlYmJ3+gByHNgI1NqAL9k+dSZGLgGYSpY5YhyYCDVww2DPTgDuSUIiC0qIj0JEXBnPxNKgcicuhFwabI7FFPcyRHxgGGWAaCo4EAcbR6ZuR6lCAAAMA6J4vKwWOtO8AAAAASUVORK5CYII=",
	"clusterMarker": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMDE0IDc5LjE1Njc5NywgMjAxNC8wOC8yMC0wOTo1MzowMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTQgKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RTczMTNFNzk0MTFEMTFFNjgxNEFFQTBDQzE2NzI5MDkiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RTczMTNFN0E0MTFEMTFFNjgxNEFFQTBDQzE2NzI5MDkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpFNzMxM0U3NzQxMUQxMUU2ODE0QUVBMENDMTY3MjkwOSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpFNzMxM0U3ODQxMUQxMUU2ODE0QUVBMENDMTY3MjkwOSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PgKLHUEAAAFxSURBVHjaxJcxbsJAFETtL+5g5CYNHZKLFJQ0qVOk8BHQHoCk5wBxnTgcgYILpIKOggKJjhrBKZxZaWytyCKMY/+M9Aq0aMff3v07G35Hj0ENxWAMRiABAxBx7AwOYAc2YAWOtybs3RgfghS80NCnB/LE3/YBlmAB9vcah8CACaj1Shwl5BnMQQ6KOsZ98Aqmwd9kH/iDn+UdnNxB8ZjOWjB1NeWc/WvGISs1QfsynDv0GZuWK/VVbi6Nh1xIXWtCr8o4bbB6my64tDSOuU+1ZL1iYUdKFI2t11jYBrU1EuVqq6qFnUVbA3FOGU1FEvyThOepts7CQ1xbB+HBra2dMK5oayPMSJpVW6+VMJgtFY2t17HcTjaYbRVMt/SqjsU9g1nXmpfJ020gNg1mHZpm9PgVfQqmwbwD05xzF9dS5omJMGu50tllvPXlavuHN3a0JoHeXUh3BfrytX+CdY0rjG+fNr7CBM5qt3y1fWn7EWAA7/lPM34SSeMAAAAASUVORK5CYII=",
	"clusterMarkerInactive": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAqCAYAAADFw8lbAAAAqElEQVRYhe3SwQnCQBCF4VeCJViCpViCpeQmiNmBTHS2m5SwJViCJegtICYbEXR2yfvhHRc+hgUYA8563YfebqGPj1I2g/SHLUJLu2QG6o8ilFBCCSWUUEIJJZTQ0kYooYSuCqo2lA/VeD/JZfctNAW14ddruyhHse0b8hNoq9ZMPvx3WWRnB2/fWBVIYAI695m9qwIJvEBTsUhghKZGZONtyVYFkq2xJxD2se7YIytOAAAAAElFTkSuQmCC",
	"pin_audi_active_red": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB0AAAAqCAYAAABP7FAaAAAAAXNSR0IArs4c6QAABBVJREFUWAm1V1tsDFEY/v+ZdnVtSxG3laxSl4RQTRPqEnEpEg+aSDx6kxA8EU+ISDYi3jy4hXcPEsETbZWIS9uk2ZCoSxAhJCqqqtvqMj3ONzuzdmfOmdkt/pc55/+///vm3P4zw1SCtVUtX0DWrwUyJU48OoWE8UW2P5JZ9rLpe9fLYqk4DHinurHG+vljr8Q1C0EQVBozQfSaWV5xbn1/x1slyHFqRR9OWDl52Bo+Mipon8RGgkg8sYzBdCZqRpOrBh71eWJ2VynaHquvswTdIBIJVVJxPn5nMm3bkE499uINr6N9/LKtlhAP/k4QrCIBHvB5NQpGmh0hBCnmBf5FP20yr84fcW6kWMPslP5TQbxrDLzgd1+8zG1g08h26BrGFtXSxBVLKTJlEmW+fKVvnU8o3fPapdE8RcLhPwCAPb04Fr8yP17IvnaXRmtm0aILx2nSmgYf8df73dSz+xgNv/3gi+U5MmWRioU4Tvb0OudQKzht+yZq7LqiFAQpXgRx4AIs4uiQu6bNOjBGuPj8cTJjUR3E9iMOHPABZuswSpuwMphapTXcuqQdoSoBU929ZZcqZPvYjCw0nFqqBGHTqNZQCXacwCNPa7J2Y3rjOkB1Y50uFOjH7g6wuGHfFhpE+eRqTSTYjeOkNXk7Gc71pMT87OtX+sOcOL9ak9ehQSY90wH6O3y1Wgct8KNg6EyY3GPMTNR2MvGwCoRKg91YigGvq1DQiSfmdhmLn17JyBvhpo4YlcZKK9/JlwIc8HoTN6FnFwc26LIOiNL2dE+4MASBCyqFro4tOmP2/OuyCvfqhHuvtlLH8h3aqcaUIg6c1iS/rSMBufu0tXJZkkbpsDbJCYztliEyDE5uHEwdBU1OtC22YroQmTdyfceHCZce5yHmyNymdOcn5LoFn+CQX3TnSicMzwCvK1ggik6Uo6fk4IfCaUpB8FCW909ObqRwrR582EvMZ/+E/0FL8tm8eVQFovCPY3FKCg/kYcbelDw2n4fBJ7p2MPVZTnHSgxtjl5NZvsJ0nyjC8Zra01L4VSG01B6/yvL483JHxhtqr6rfZlniutdfbN80uXnD99QNFV4rCnBbrL5FCBH4taUkZW5tSqc2q2LwKac3BzbK98v2SK5fXGOEsnladKAo/jnldXRCm60IAB/2rxooCs6Zc+adlEfouYLf75I4G++PFHgC19RF3q6sXytGxV2RV6vdmPuURIINXieL+j3Xp3uGjhSJDtFFHYnjv1iMILBFiQJYWTHukBzoe7T9xu+zcX9E5SlatLGvc8BgQ/npDj/iKgGVr2hRJG9Md7fI3Xkhnwh9+PN9Ye2SREE2tWrqQSlk/5DiiX6YiDdesmjdp5a0/FbeKYlG8LT7Xtb/1W+NNSwZK/dvIFlnG/2lJI8AAAAASUVORK5CYII=",
	"pin_audi_inactive": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB0AAAAqCAYAAABP7FAaAAAAAXNSR0IArs4c6QAABBxJREFUWAmtV89L1EEUn/2qq7bioZVEDxIK6yk66KUSyUMdOuif4CX80QZi0qUShDyEIFK2u2onT90qPUkeiiDCFQ9dyhZXskBh0Q7iKrqofT7LzvL1uzOz39V9sMy89z7vfXbezLzZ9YgCJBgMBo6PjwOnp6f1CPPjs+PxeDZLSkpioVAo5jaVJx+wr6/vKkge4NMFbMCAJ+kHy7IiU1NTvw04oSUdHBy8nEwmnyE4iI/XlMThO4Ie8vl8oxMTE/8cvrSqJO3t7b2Olc0D0aAKcmn7g9J3Tk9Pf3fiLacBhPdA+BX2ixAybQPzMJ+T48xKMyskoc8JvICexIpv2VecXSn3MFPSYhLyu/qYl/nlFy+Vk8yhyVvS+vp60djYKKqqqsTe3p5YX18Xm5ubMo1ubMjkf0RAury8FicnJ7+ga09pTU2N6O7uFoFA7q2JxWJidnZWbG9v60hpP8J1auZ1KqHW0tLyBEM75yqBXwwMDIja2lqVW/j9ftHW1iYSiYTY2tpSYmAkV2plZWUxvaeoOS++UuQKy8vLlX5ppJ+VIF4nksfD1pZKpVhapQwNDSlLqgTDyFKPj4/r3KKsrKzZYi/VIXhoVHuow9NOPON0Qj4LS9YimpqadLFGO0+3TsjHPeVroRT0T6U9n5HXySB+ku7oALhbOpfRzvtrkB1cHeunDhCPx3Uuo50NQyco7w8Ld28JvfFABWKn4WksRIjXdSjy4JBFrZGRkSMkXdAlZqc5PDzUuc/YiSPeIAvkk83hrQ7I1uaGWBKaWiFKm+ZJN3wseQ7tKwHjFRU5WpfY2Ni4UO9FaRN1dXVzzJ99T3t6ekahP1WR2m28+Od4ZQRIR/GmDjNX9mmrrKycPDg4GITtkp3EOech0R0UJ9am71dUVLyWevqVobK0tJTEa8KH9qZ0FnF8FQ6H38t86YOUVSxrDPN9qRdp3EcvYN6snCHFA5tA7cNZbxEmzMe89lRnSOnwer1jAO7aQeedMw/zOeOzeyod0Wh0H3t7DP2OtF1gHI5EIovO+JyVEoBr8RLDmhNcoL6WyZMTlr2nTk9/f38nHtz0ZXb63Oj4U9WFVc6rsFpSgvHj+yO6VMFlxl4uohHcVRHSpiyvBJeWlj5EAnfdPhNEPONkDtWYc5DsoOXl5Z3W1lZ+sQ673TQH6XOU9Z0JY1wpA9GkX2BYNSWx+VYzeJspd2rcUwnH3rZjbz9DN+FPscrb2MsvMk43Gssrg/C0baDM/NXYIm3OEYRvQBhy2lV63vLKIHSWx0j8V+r2kXb67TbT3DXp5OTkLkp8X5WMdvpVPpXNVXllIMocR4usg94qbRinZ2Zm2MFci+uVyozV1dVDmMvfpvGMLt2uRtNp1CbAab4B5yd8OnB4vmmBxXbgN9W18+b8DxJmg0ubrhl/AAAAAElFTkSuQmCC",
	"pin_audi_active_black": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB0AAAAqCAYAAABP7FAaAAAAAXNSR0IArs4c6QAAAnxJREFUWAm9lz+rE0EUxfcp2Ag+MFHhVSHFg0DEOiksjYiYynyTV4lWWvgHa/0S9tqlSb5AUJ7aaCG8kBRCFE1hPL/gLGG5M/tv4oGTmbn3zjk7u7Oz5CAph2OVwyOxIS7Fb+LHf1QTBy3JPBNPxU2A5J+KLbEyLmvmS/G3GDLL5qhnHvNL4Yaqv4hZwTJj5qNTCHdUtRLLGPhq0UEvCK4slqG7EPS8K+YZ1L2lzijbops+4/MaODxR57Yb+Nput5sMh8NkMBgknU4nWa/XyXw+95W7+KE6F8S3LkDbEoO7tN1ub8bj8cYCcfLSCBF9fFLwHnonjEajzWq1svzSGHnqQjrK4ZPiVD1zAivIM3TO1OWsGJ8tONZMQ+K+W+qMsi31IT3l8Evu+oq0abKahcbM82nid04/HN4m+v2+Gc8L9nq9UMkRpnwtTDQa3pRZ74LNZtN1rbaBKZ8nE8ulN2XWu+BisXBdq11i+sHKEJtMJr5UMD6dTkP59yQ5KX6K5sOPvHvxwW+LN/o1TSO/p/ikuK+eaUo84omETwqWfCZ6jVmx71YTzzmJ0EV/e2sP1HF4rM4DN/C1fGV4D3kt2KVsmtls5ivfjaP/cDdA/5r4Q/SutkYOXfRNvFB0H6boenFVmdirRQ/dIJ4rG3O16OXiiiq+izGM0UGvEE5UFcMUncLgffok1jFmfnrkFXW+V9OU+ZXwTrOqrJZ5lXGsmb/EMsbUM68WHml2GVPqa4PNwIe+iDF1pTeP7wpvKvEnx5g8dVHxWmqh1ZKPjktS/CpaxsTJ7wW3pGqZEt8rXkl915jx3nFRDp9FjGkZ/xfwv4FDIPj/YR9Xcr2q6F+rIYja43tTMAAAAABJRU5ErkJggg==",
	"location": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAPCAYAAADtc08vAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MzYzQkU3RUEzMUVGMTFFNEE2NzQ4NDE1QjFEQzQyOEEiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NEJBNjA4MTgzMUVGMTFFNEE2NzQ4NDE1QjFEQzQyOEEiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDozNjNCRTdFODMxRUYxMUU0QTY3NDg0MTVCMURDNDI4QSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDozNjNCRTdFOTMxRUYxMUU0QTY3NDg0MTVCMURDNDI4QSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PnsPubcAAAEbSURBVHjaYmTADiyBOBCIzYCYB4jvA/FxIF4AxO8Y8AB+IJ4DxP9x4FdQg7ECaSB+gkczMp6GzYDtRGqG4UxkzQnoCqKiov4fOHDg//nz5//Pnz//v5ycHLoBb6FeBoPDyJJFRUX/0cHbt2//S0tLoxsSC9LMhizIycn5/9u3b/+xgTlz5qAbMJkJSIgi+0VLS4sBaAjWUNbV1UUXkgAZ8B1Z5P79+wx///7FasCjR4/QhV7DGFeRnTZ58mQM5//8+fM/0AXoXsiBGVCNHg4tLS3/P3z4ANYMigl7e3t0zT+haYcBFpCXSEwHXej+MQDib0RqPgy1FAOoA/FJPBr/AHELLs0wwAwNnL1A/Bmq8R4QLwJiY3TFAAEGAE7g3+YISVmBAAAAAElFTkSuQmCC"
}
`;

export {template as mapDataUris};
