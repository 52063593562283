const template = `
[
	{
		"featureType":"transit.line",
		"elementType":"geometry.fill",
		"stylers":[
			{
				"saturation":-79
			}
		]
	},
	{
		"featureType":"administrative.province",
		"elementType":"all",
		"stylers":[
			{
				"visibility":"simplified"
			}
		]
	},
	{
		"featureType":"administrative.country",
		"elementType":"all",
		"stylers":[
			{
				"visibility":"on"
			}
		]
	},
	{
		"featureType":"administrative.locality",
		"elementType":"all",
		"stylers":[
			{
				"visibility":"off"
			}
		]
	},
	{
		"featureType":"administrative.land_parcel",
		"elementType":"all",
		"stylers":[
			{
				"visibility":"on"
			}
		]
	},
	{
		"featureType":"landscape.man_made",
		"elementType":"all",
		"stylers":[
			{
				"visibility":"simplified"
			}
		]
	},
	{
		"featureType":"landscape.natural",
		"elementType":"all",
		"stylers":[
			{
				"visibility":"on"
			},
			{
				"saturation":-100
			},
			{
				"lightness":20
			}
		]
	},
	{
		"featureType":"water",
		"elementType":"all",
		"stylers":[
			{
				"visibility":"on"
			},
			{
				"color":"#c6e2fa"
			}
		]
	},
	{
		"featureType":"poi.business",
		"elementType":"all",
		"stylers":[
			{
				"saturation":-100
			}
		]
	},
	{
		"featureType":"poi.government",
		"elementType":"all",
		"stylers":[
			{
				"saturation":-100
			}
		]
	},
	{
		"featureType":"poi.medical",
		"elementType":"all",
		"stylers":[
			{
				"saturation":-100
			}
		]
	},
	{
		"featureType":"poi.park",
		"elementType":"all",
		"stylers":[
			{
				"visibility":"on"
			},
			{
				"saturation":-100
			},
			{
				"lightness":50
			}
		]
	},
	{
		"featureType":"poi.place_of_worship",
		"elementType":"all",
		"stylers":[
			{
				"saturation":-100
			}
		]
	},
	{
		"featureType":"poi.school",
		"elementType":"all",
		"stylers":[
			{
				"saturation":-100
			}
		]
	},
	{
		"featureType":"poi.sports_complex",
		"elementType":"all",
		"stylers":[
			{
				"saturation":-100
			}
		]
	},
	{
		"featureType":"road",
		"elementType":"all",
		"stylers":[
			{
				"visibility":"on"
			}
		]
	},
	{
		"featureType":"road.highway",
		"elementType":"geometry",
		"stylers":[
			{
				"visibility":"on"
			},
			{
				"saturation":-100
			}
		]
	},
	{
		"featureType":"road.highway",
		"elementType":"labels",
		"stylers":[
			{
				"visibility":"simplified"
			},
			{
				"saturation":-50
			}
		]
	},
	{
		"featureType":"road.arterial",
		"elementType":"labels",
		"stylers":[
			{
				"visibility":"on"
			},
			{
				"hue":"#ffff00"
			},
			{
				"gamma":9.99
			}
		]
	},
	{
		"featureType":"road.local",
		"elementType":"geometry",
		"stylers":[
			{
				"visibility":"simplified"
			}
		]
	},
	{
		"featureType":"administrative.country",
		"elementType":"labels",
		"stylers":[
			{
				"visibility":"on"
			},
			{
				"lightness":20
			}
		]
	},
	{
		"featureType":"poi.attraction",
		"elementType":"labels",
		"stylers":[
			{
				"visibility":"off"
			}
		]
	},
	{
		"featureType":"poi.attraction",
		"elementType":"geometry",
		"stylers":[
			{
				"saturation":-100
			}
		]
	},
	{
		"featureType":"landscape.natural.terrain",
		"elementType":"all",
		"stylers":[
			{
				"visibility":"off"
			},
			{
				"lightness":91
			}
		]
	},
	{
		"featureType":"poi.park",
		"elementType":"labels",
		"stylers":[
			{
				"visibility":"off"
			}
		]
	},
	{
		"featureType":"poi.school",
		"elementType":"labels",
		"stylers":[
			{
				"visibility":"on"
			}
		]
	},
	{
		"featureType":"poi.sports_complex",
		"elementType":"labels",
		"stylers":[
			{
				"visibility":"on"
			}
		]
	},
	{
		"featureType":"transit",
		"elementType":"geometry",
		"stylers":[
			{
				"visibility":"on"
			}
		]
	},
	{
		"featureType":"transit.line",
		"elementType":"all",
		"stylers":[
			{
				"visibility":"on"
			}
		]
	},
	{
		"featureType":"administrative.locality",
		"elementType":"labels.text",
		"stylers":[
			{
				"visibility":"on"
			}
		]
	}
]
`;

export {template as dshMapstylesDefault};
