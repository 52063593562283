import {mapStyle} from './map-style';

class MapUtils {

	static get ZOOM_LEVEL_STREET() { return 13; }
	static get AUDI_MAP_STYLE() { return mapStyle.__.mapStylesDefault; }

	constructor(container_, options_) {
		this.mapContainer = container_;
		this.latLngBounds = null;
		this.map = null;
		this.markerIcons = null;
		this.selectedMarker = null;
		this.markersOnMap = [];
		this.zoomIn = null;
		this.zoomOut = null;

		this._init(options_);
	}

	/**
	* _init
	* @param {Object} options_ - google maps options
	* @returns {Object} - this
	*/
	_init(options_) {
		if (!options_.zoom) {
			options_.zoom = MapUtils.ZOOM_LEVEL_STREET;
		}

		if (!options_.center) {
			this.latLngBounds = new google.maps.LatLngBounds();
			options_.center = new google.maps.LatLng(0, 0);
		}

		this.map = new google.maps.Map(this.mapContainer, {
			center: options_.center,
			zoom: options_.zoom,
			styles: MapUtils.AUDI_MAP_STYLE,
			disableDefaultUI: true
		});

		return this;
	}

	/**
	* initZoomInAndZoomOutByIDs
	* @param {string} zoomInID_ - id of zoom in control
	* @param {string} zoomOutID_ - id of zoom out control
	*/
	initZoomInAndZoomOutByIDs(zoomInID_, zoomOutID_) {
		let zoomInControl;
		let zoomOutControl;

		this.zoomIn = zoomInID_;
		zoomInControl = document.querySelector('#' + zoomInID_);
		zoomInControl.addEventListener('click', this._zoomInMap);

		this.zoomOut = zoomOutID_;
		zoomOutControl = document.querySelector('#' + zoomOutID_);
		zoomOutControl.addEventListener('click', this._zoomOutMap);
	}

	/**
	*  _zoomInMap
	*/
	_zoomInMap = () => {
		let actualZoom = this.map.getZoom();
		let newZoom = actualZoom + 1;

		this.map.setZoom(newZoom);
	};

	/**
	*  _zoomOutMap
	*/
	_zoomOutMap = () => {
		let actualZoom = this.map.getZoom();
		let newZoom = actualZoom - 1;

		this.map.setZoom(newZoom);
	};

	/**
	* initMarkerIcons - set marker icon set by given icons object
	* @param {Object} iconSetObject_ - JSON Object with icon urls
	*/
	initMarkerIcons(iconSetObject_) {
		this.markerIcons = iconSetObject_;
	}

	/**
	* initAndRenderMarkers - set markers array and render them
	* @param {Array} markers_ - markers array
	*/
	initAndRenderMarkers(markers_) {
		if (this.markersOnMap.length > 0) {
			this._clearMarkers();
		}

		this._renderMarkers(markers_);
	}

	/**
	* _clearMarkers
	*/
	_clearMarkers() {
		let markersLength = this.markersOnMap.length;

		for (let index = 0; index < markersLength; index++) {
			this.markersOnMap[index].setMap(null);
		}

		this.markersOnMap = [];
	}

	/**
	 * set map center to given marker
	 * @param {Object} marker_ - google marker object
	 */
	setMapCenterToMarker(marker_) {
		this.map.panTo(marker_.getPosition());
	}

	/**
	 * moveMapCenter
	 * @param {Object} centerPosition_ - object with lat and lng value
	 */
	moveMapCenter(centerPosition_) {
		this.map.panTo(centerPosition_);
	}

	/**
	 * getMapCenter
	 * @returns {Object} - position with lat and lng value
	 */
	getMapCenter() {
		return this.map.getCenter();
	}

	/**
	* setMapZoom
	* @param {number} zoom_ - zoom Level
	*/
	setMapZoom(zoom_) {
		this.map.setZoom(zoom_);
	}

	/**
	* getMapZoom
	* @returns {number} - zoom Level
	*/
	getMapZoom() {
		return this.map.getZoom();
	}

	/**
	* _renderMarkers - renders all markers of given marker array in map
	* @param {Array} markers_ - array with markers
	*/
	_renderMarkers(markers_) {
		let markerLength = markers_.length;

		for (let i = 0; i < markerLength; i++) {
			let marker = this._createMarker(markers_[i].lat, markers_[i].lng, markers_[i].type);

			if (this.latLngBounds) {
				this.latLngBounds.extend(marker.getPosition());
			}
		}

		if (!this.latLngBounds) {
			return;
		}

		if (this.markersOnMap.length === 1) {
			this.map.panTo(this.markersOnMap[0].getPosition());
		}
		else {
			this.map.fitBounds(this.latLngBounds);
		}
	}

	/**
	* createMarker - init marker with google map api, set hover event handlers
	* @param {string} lat_ - latitude
	* @param {string} lng_ - longitude
	* @param {string} type_ - marker type (privat/public)
	* @returns {Object} marker - google map marker
	*/
	_createMarker(lat_, lng_, type_) {
		let marker;

		marker = new google.maps.Marker({
			position: {lat: parseFloat(lat_), lng: parseFloat(lng_)},
			map: this.map,
			optimized: false, // Tell Gmaps to render markers in the DOM instead of canvas
			title: `Unique_${lat_}_${lng_}` // Used to be able to target the marker on the DOM
		});

		if (type_) {
			marker.type = type_;
			this._setMarkerIconByMarkerType(marker, type_);
			this._handleHoverOfMarker(marker, type_);
			this._handleClickOfMarker(marker);
		}

		this.markersOnMap.push(marker);

		return marker;
	}

	/**
	* setMarkerIconByMarkerType - set icon for given marker by type
	* @param {Object} marker_ - google maps marker
	* @param {string} type_ - key for getting the right marker src from icon set
	* @param {Object} markerIcons_ - the availabale icons for the marker
	*/
	_setMarkerIconByMarkerType(marker_, type_, markerIcons_ = this.markerIcons) {
		marker_.setIcon(markerIcons_[type_].icon);
	}

	/**
	 *
	 * @param {Object} marker_ - google maps marker
	 * @param {Object} markerIcons_ - the availabale icons for the marker
	 */
	updateIconForMarkerOnMap(marker_, markerIcons_) {
		this._setMarkerIconByMarkerType(marker_, marker_.type, markerIcons_);
	}

	/**
	 *
	 * @param {Object} marker_ - google maps marker
	 */
	setSelectedMarker(marker_) {
		this.selectedMarker = marker_;
	}

	/**
	* updateIconsForMarkersOnMap - reset all icons for markers on map
	*/
	updateIconsForMarkersOnMap() {
		let markersOnMapLength = this.markersOnMap.length;

		for (let i = 0; i < markersOnMapLength; i++) {
			let marker = this.markersOnMap[i];
			let {type} = marker;

			this._setMarkerIconByMarkerType(marker, type);
		}
	}

	/**
	* handleHoverOfMarker - add event handlers for changing icon of marker while hovering
	* @param {Object} marker_ - google maps marker
	* @param {string} type_ - key for getting the right marker src
	*/
	_handleHoverOfMarker(marker_, type_) {
		let indexOfMarker = this.markersOnMap.length;
		const hasEvents = this.markerIcons[marker_.type].events;

		if (!this.markerIcons) {
			console.warn('no icon set for markers set');
			return;
		}

		if (hasEvents) {
			let onMarkerHover = new CustomEvent('on_marker_hover', {
				detail: {
					markerIndex: indexOfMarker,
					markersOnMap: this.markersOnMap
				}
			});

			let onMarkerHoverEnd = new CustomEvent('on_marker_hover_end', {
				detail: {
					markerIndex: indexOfMarker,
					markersOnMap: this.markersOnMap
				}
			});

			Object.keys(hasEvents).forEach(key_ => {
				google.maps.event.addListener(marker_, key_, () => {
					if (marker_ !== this.selectedMarker) {
						let element_ = document.querySelector(`[title="${marker_.title}"]`);

						element_.className = '';
						element_.classList.add(hasEvents[key_].cssClass);

						if (key_ === 'mouseover') {
							document.dispatchEvent(onMarkerHover);
						}

						if (key_ === 'mouseout') {
							document.dispatchEvent(onMarkerHoverEnd);
						}
					}
				});
			});
		}
		else {
			console.warn('no listeners / hover-changes for marker type: ' + type_);
		}
	}

	/**
	 * resetMapHovers - unset classes for marker elements on map
	 */
	resetMapHovers() {
		let markersOnMapLength = this.markersOnMap.length;

		for (let i = 0; i < markersOnMapLength; i++) {
			let marker = this.markersOnMap[i];
			let element = document.querySelector(`[title="${marker.title}"]`);

			// Prevents an error if the marker is not currently on the map (when far out
			// of the viewport google maps removes the marker from the dom)
			if (element) {
				element.className = '';
			}
		}
	}

	/**
	* handleClickOfMarker - add event handlers for click on marker
	* @param {Object} marker_ - google maps marker
	*/
	_handleClickOfMarker(marker_) {
		let indexOfMarker = this.markersOnMap.length;
		let onMarkerClickEvent = new CustomEvent('on_marker_click', {
			detail: {
				markerIndex: indexOfMarker,
				markersOnMap: this.markersOnMap,
				mapContainer: this.mapContainer
			}
		});

		google.maps.event.addListener(marker_, 'click', () => {
			document.dispatchEvent(onMarkerClickEvent);
		});
	}

	/**
	* getMarkerFromMapByIndex
	* @param {number} index_ - index
	* @returns {Object} - google marker object
	*/
	getMarkerFromMapByIndex(index_) {
		return this.markersOnMap[index_];
	}
}

export {MapUtils};
