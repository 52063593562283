import {dom} from 'core-utils';
import {scroll} from 'application-bundle';

var __ = {},
	exports = {__};

__.oDefaults = {
	selectorMenuToggleContainer: '.axs-md-header-main-nav-toggle-container',
	selectorCookieNotification: '.axs-md-cookie',
	selectorHeader: '.axs-md-header',
	selectorContent: '.axs-content',
	selectorContinueButton: '.axs-cookie-continue',
	valueNotificationCanceled: 'cn-canceled',
	mobileNavigationToggle: 'axs-md-header-main-nav-toggle'
};


__.scrollUpHandler = function () {
	if (window.pageYOffset < 20) {
		__.removeHeaderClass();
	}
	else {
		__.addHeaderClass();
	}
};

__.scrollDownHandler = function () {
	if (window.pageYOffset < 20) {
		__.removeHeaderClass();
	}
	else {
		__.addHeaderClass();
	}
};

/**
 * addHeaderClass
 */
__.addHeaderClass = function () {
	dom.getElement('.axs-md-header').classList.add('fixed-navigation');
};

/**
 * removeHeaderClass
 */
__.removeHeaderClass = function () {
	dom.getElement('.axs-md-header').classList.remove('fixed-navigation');
};

__.scrollToTop = function () {
	var itemToRegister = 'body';

	scroll.register(itemToRegister, {
		notifyWhen: scroll.NOTIFY.ONSCROLL,
		mode: scroll.MODE.SCROLLUP,
		eventName: 'scrollup'
	}, __.scrollUpHandler);

	scroll.register(itemToRegister, {
		notifyWhen: scroll.NOTIFY.ONSCROLL,
		mode: scroll.MODE.SCROLLDOWN,
		eventName: 'scrolldown'
	}, __.scrollDownHandler);
};

/**
 * onToggleClick
 */
__.onToggleClick = function () {
	var toggle = dom.getElement(__.oDefaults.selectorMenuToggleContainer);

	if (!!toggle && !toggle.classList.contains('animated')) {
		toggle.classList.add('animated');
		__.setMaxHeightForHeaderMenu(); // available space should be scrollable
		dom.getEventDelegate('body').off('click', __.oDefaults.selectorMenuToggleContainer, __.onToggleClick);
	}
};

__.setMaxHeightForHeaderMenu = function () {
	const header = document.querySelector('.axs-md-header');
	const availableSpace = window.innerHeight - header.offsetTop - header.clientHeight;

	document.querySelector('.axs-md-header-menu').style.maxHeight = availableSpace + 'px';
};

/**
 * initCookieNotification
 */
__.initCookieNotification = function () {
	var notification = dom.getElement(__.oDefaults.selectorCookieNotification);

	if (dom.isElement(notification) && !__.readSessionStorageFlag(__.oDefaults.valueNotificationCanceled)) {
		notification.classList.remove('axs-hidden');
	}

	// call resize function to set header/body top margin/padding
	__.cookieNotificationResizeHandler();
};

/**
 * onCookieContinueClick
 */
__.onCookieContinueClick = function () {
	var notification = document.querySelector(__.oDefaults.selectorCookieNotification);

	if (!!notification && !__.readSessionStorageFlag(__.oDefaults.valueNotificationCanceled)) {
		__.writeSessionStorageFlag(__.oDefaults.valueNotificationCanceled);
		notification.classList.add('axs-hidden');

		// call resize function to clear header/body top margin/padding
		__.cookieNotificationResizeHandler();
	}
};

/**
 * sets header/body top margin/padding if cookie-notification is visible
 */
__.cookieNotificationResizeHandler = function () {
	var notification = dom.getElement(__.oDefaults.selectorCookieNotification),
		header = dom.getElement(__.oDefaults.selectorHeader),
		cookieNotificationHeight = 0;

	if (dom.isElement(notification)) {
		if (notification.classList.contains('axs-hidden')) {
			// reset inline style values
			header.style.top = '';
			document.body.style.paddingTop = '';

			__.setMaxHeightForHeaderMenu(); // menu gets more space

			// remove listener because it is not needed anymore
			window.removeEventListener('resize', __.cookieNotificationResizeHandler);
			window.removeEventListener('load', __.cookieNotificationResizeHandler);
		}
		else {
			cookieNotificationHeight = notification.clientHeight;
			header.style.top = cookieNotificationHeight + 'px';
			document.body.style.paddingTop = cookieNotificationHeight + 'px';
		}

		__.dispatchCookieNotificationSizeChangeEvent();
	}
};

__.dispatchCookieNotificationSizeChangeEvent = function () {
	let event = new CustomEvent('axs-header-cookie-notification-size-change');

	document.dispatchEvent(event);
};

/**
 * Resize handler for the mobile menu
 */
__.menuDropdownDesktopResizeHandler = function () {
	let mobileNavigationToggle = document.getElementById(__.oDefaults.mobileNavigationToggle);
	let mobileNavigationIsOpen = (mobileNavigationToggle && mobileNavigationToggle.checked) || false;
	let viewport = document.documentElement.clientWidth;
	const desktopViewBreakpoint = 1024;

	if (mobileNavigationIsOpen && viewport >= desktopViewBreakpoint && !__.isNavigationBurgerMenuOnly()) {
		__.resetMenuDropdown(mobileNavigationToggle);
	}
};

/**
 * Unchecks the input responsible for the Menu-Visibility
 * @param {HTMLElement} menuToggleElement_ - The Checkbox-Input for the Mobile-Menu
 */
__.resetMenuDropdown = function (menuToggleElement_) {
	menuToggleElement_.checked = false;
};

/**
 * Checks if the Navigation is Dropdown Only
 * @returns {boolean} - wether or not the Navigaiton is Dropdown Only
 */
__.isNavigationBurgerMenuOnly = function () {
	let header = dom.getElement(__.oDefaults.selectorHeader);

	return header.classList.contains('axs-md-header-burgermenu');
};


/**
 * read an item from session storage
 * @param {string} key - key of the item to read
 * @returns {Object} item from session storage
 */
__.readSessionStorageFlag = function (key) {
	if (__.sessionStorageAlternative) {
		return __.sessionStorageAlternative[key];
	}
	else {
		return sessionStorage.getItem(key);
	}
};

/**
 * write a boolean to session storage
 * @param {string} key - key of the item to set
 */
__.writeSessionStorageFlag = function (key) {
	try {
		sessionStorage.setItem(key, true);
	}
	catch (err) {
		__.sessionStorageAlternative = __.sessionStorageAlternative ? __.sessionStorageAlternative : {};
		__.sessionStorageAlternative[key] = true;
	}
};

__.addEvents = function () {
	dom.getEventDelegate('body').on('click', __.oDefaults.selectorMenuToggleContainer, __.onToggleClick);
	dom.getEventDelegate('body').on('click', __.oDefaults.selectorContinueButton, __.onCookieContinueClick);
	window.addEventListener('resize', __.cookieNotificationResizeHandler);
	// resize cookie notification on load again because of slow font loading
	window.addEventListener('load', __.cookieNotificationResizeHandler);
	window.addEventListener('resize', __.menuDropdownDesktopResizeHandler);
	window.addEventListener('resize', __.setMaxHeightForHeaderMenu);
};

/**
 * public initialize method
 * @param {EventBus} globalEventBus - tbd
 * @returns {Promise} returns Promise
 */
exports.initialize = function (globalEventBus) {

	return new Promise(resolve => {
		if (document.querySelector('.axs-md-header')) {
			__.globalEventBus = globalEventBus;
			__.scrollToTop();
			__.initCookieNotification();
			__.addEvents();
			resolve('header.js');
		}
	});
};

export {exports as header};
