
import CountrySelectorDropdowns from './country-selector-dropdowns';
import CountrySelectorMobileCTA from './country-selector-mobile-cta';
import CountrySelectorModal from './country-selector-modal';
import CountrySelectorZoomButton from './country-selector-zoom-button';
import CountrySelectorMap from './country-selector-map';

export default class CountrySelector extends HTMLElement {

	/**
	* constructor
	*/
	constructor() {
		super();
	}

	/**
	* connectedCallback - callback for adding element to dom/shadow dom
	*/
	connectedCallback() {
		this.activeContinentsAndCountries = [];
		this.continentSelect = this.querySelector('.axs-country-selector-continent-option');
		this.countrySelect = this.querySelector('.axs-country-selector-country-option');
		this.countryDropdown = this.querySelector('.axs-country-selector-dropdown');
		this.Dropdowns = new CountrySelectorDropdowns(this);

		this._initializeModule();
	}

	/**
	* initialize elements
	*/
	_initializeModule() {
		if (this.Dropdowns.hasCountrySelect()) {
			this.activeContinentsAndCountries = this.Dropdowns.getActiveContinentsAndCountries();
			this.ZoomButton = new CountrySelectorZoomButton(this);
			this.Modal = new CountrySelectorModal(this);
			this.MobileCta = new CountrySelectorMobileCTA(this);
			this.Map = new CountrySelectorMap(this);
		}
	}

	/**
	* disconnectedCallback - callback for removing element from dom/shadow dom
	*/
	disconnectedCallback() {
		this._removeEvents();
	}

	/**
	* eventListener Cleanup
	*/
	_removeEvents() {
		this.Dropdowns.removeEventListeners();
		this.ZoomButton.removeEventListeners();
		this.Modal.removeEventListeners();
		this.Map.removeMapEventsForContinents(this.activeContinentsAndCountries);
	}

	/**
	* trigger all needed actions to update view and controls based on continent selection
	*/
	handleContinentSelection = () => {
		if (this.Dropdowns.hasCountrySelect()) {
			let selectedContinent = this.Dropdowns.getSelectedContinent();

			if (selectedContinent !== 'none') {
				this._updateModuleAfterContinentSelection(selectedContinent);
			}
		}
	};

	/**
	* ordered callbacks execution after Continent Selection
	*/
	_updateModuleAfterContinentSelection() {
		const selectedContinent = this.Dropdowns.getSelectedContinent();

		this.Modal.hide();
		this.Map.zoomToContinent(selectedContinent);
		this.MobileCta.deactivate();
		this.ZoomButton.show();
		this.Dropdowns.activateCountrySelect();
	}

	/** updates continent dropdown selection triggered by map click
	* @param  {string} continentID_ - passthrough ID for Event-Trigger
	*/
	updateContinentDropdown = (continentID_) => {
		if (this.getContinentSelect()) {
			this.Dropdowns.setSelection(this.getContinentSelect(), (continentID_) ? continentID_ : 'none');
			this._triggerEventForGivenElement(this.getContinentSelect());
		}
		else {
			this.ZoomButton.show();
		}
	};

	/**
 	* trigger all needed actions to update view and controls based on country selection
 	*/
	handleCountrySelection = () => {
		const selectedCountry = this.Dropdowns.getSelectedCountry();

		this.Map.zoomToCountry(selectedCountry, 'selected');
		this.Modal.show(selectedCountry);
		this.MobileCta.activateForCountry(selectedCountry);
	};

	/** updates country dropdown selection and map zoom triggered by map click
	* @param  {string} countryID_ - passthrough ID for Event-Trigger
	*/
	updateCountryDropdown = (countryID_='none') => {
		this.Dropdowns.setSelection(this.getCountrySelect(), countryID_);
		this._triggerEventForGivenElement(this.getCountrySelect());

		if (countryID_ === 'none') {
			if (this.Dropdowns.getSelectedContinent() !== 'none') {
				this.Map.zoomToContinent(this.Dropdowns.getSelectedContinent());
			}
			else {
				this.Map.zoomOut();
				this.ZoomButton.hide();
			}
		}
	};

	/**
	* handleMapZoomOut - updates all elements to zoom out of map
	*/
	handleMapZoomOut = () => {
		if (this.Dropdowns.hasContinentSelect()) {
			this.updateContinentDropdown('none');
		}

		this.updateCountryDropdown('none');
		this.MobileCta.deactivate();
	};

	/**
	* _triggerEventForGivenElement - trigger event for given element
	* @param  {Element} element_ - {description}
	* @fires {Event} triggeredChangeEvent - fire change Event to start connected handler
	*/
	_triggerEventForGivenElement(element_) {
		const triggeredChangeEvent = document.createEvent('Event');

		triggeredChangeEvent.initEvent('change', false, true);
		element_.dispatchEvent(triggeredChangeEvent);
	}

	/**
	* @returns {Element} - continents select element
	*/
	getContinentSelect() {
		return this.continentSelect;
	}

	/**
	* @returns {Element} - country select element
	*/
	getCountrySelect() {
		return this.countrySelect;
	}

	/**
	* @returns {Element} - country select element
	*/
	getCountryDropdown() {
		return this.countryDropdown;
	}

	/**
	* @returns {string} - returns selected continent ID
	*/
	getSelectedContinent() {
		return this.Dropdowns.getSelectedContinent();
	}

	/**
	* @returns {string} - returns selected country ID
	*/
	getSelectedCountry() {
		return this.Dropdowns.getSelectedCountry();
	}
}

if (window.customElements.get('audi-axs-country-selector') === undefined) {
	window.customElements.define('audi-axs-country-selector', CountrySelector);
}
