import {appEvents} from 'application-bundle';
import {carousel} from 'global-bundle';
import {dom, swipe} from 'core-utils';

var __ = {},
	exports = {__};

__.oDefaults = {
	selectorClassPlayButton: '.axs-md-stage-play-button',
	selectorVideoPlayer: '.axs-md-video-player',
	selectorVideo: '.axs-stage-video',
	selectorSkipButton: '.axs-skip-video',
	selectorStage: '.axs-md-stage',
	selectorStageSlider: '.axs-md-stage-slider'
};
// store carousel instance
__.stages = [];

/**
 * swipe finish handler
 * @param {Object} data_ - Data
 * @param {Object} target_ - Target
 */
__.onSwipeFinishHandler = function (data_, target_) {
	var checkTarget = __.getCarouselByTarget(target_);

	if (data_.direction && data_.direction.horizontal !== '' && data_.move !== 0 && !!checkTarget) {
		switch (data_.direction.horizontal) {
			case swipe.LEFT:
				checkTarget.next();
				break;
			default:
				checkTarget.previous();
				break;
		}
	}
};

/**
 * get carousel by target
 * @param {Event} target_ - tbd
 */
__.getCarouselByTarget = function (target_) {
	var i;

	for (i = 0; i < __.stages.length; i++) {
		if (target_ === __.stages[i].context) {
			return __.stages[i].carousel;
		}
	}
};

/**
 * get video container of a contained element
 * @param {HTMLElement} element - the contained element (e.g. play button, or video)
 * @returns {HTMLElement} - the video container
 */
__.getVideoContainer = function (element) {
	return dom.closest(element, __.oDefaults.selectorVideoPlayer);
};

/**
 * just a shortcut to get the play button of a video
 * @param  {HTMLElement} video - the corresponding video
 * @returns {HTMLElement} - the play button
 */
__.getPlayButton = function (video) {
	var videoContent = __.getVideoContainer(video);

	return dom.getElement(__.oDefaults.selectorClassPlayButton, videoContent);
};

/**
 * just a shortcut to get the skip button of a video
 * @param  {HTMLElement} video - the corresponding video
 * @returns {HTMLElement} - the skip button
 */
__.getSkipButton = function (video) {
	var videoContent = __.getVideoContainer(video);

	return dom.getElement(__.oDefaults.selectorSkipButton, videoContent);
};

/**
 * get stage headline for video
 * @param {HTMLElement} video - the video to get the headline for
 * @returns {HTMLElement} the headline element
 */
__.getStageText = function (video) {
	var stageSlider = dom.closest(video, __.oDefaults.selectorStageSlider);
	var stageInnerContent = stageSlider.firstElementChild;
	var stageInnerContentHelp = stageInnerContent.firstElementChild;

	return stageInnerContentHelp.firstElementChild;
};

/**
 * play video
 * @param {HTMLElement} video - the video to play
 */
__.playVideo = function (video) {
	var playButton = __.getPlayButton(video);

	video.play();
	playButton.style.display = 'none';
};

/**
 * stop video
 * @param {HTMLElement} video - the video to stop
 */
__.stopVideo = function (video) {
	var playButton = __.getPlayButton(video);

	video.pause();
	playButton.style.display = '';

	swipe.register(__.onSwipeFinishHandler, __.oDefaults.selectorStageSlider, {
		'swipedistance': 50
	});

};

/**
 * video ended handler
 * @param {HTMLElement} video - the video to set the handler for
 */
__.setVideoEndedHandler = function (video) {
	var playButton = __.getPlayButton(video);
	var skipButton = __.getSkipButton(video);

	video.addEventListener('ended', function () {
		playButton.style.display = '';
		__.showHeadline(video);
		__.disableVideoFullStageMode(this);

		skipButton.classList.add('axs-hidden');

		swipe.register(__.onSwipeFinishHandler, __.oDefaults.selectorStageSlider, {
			'swipedistance': 50
		});

	});
};

/**
 * @returns {boolean} - whether the viewport is bigger 768 or not
 */
__.hideHeadlineBreakpointFulfilled = function () {
	var w = window.innerWidth || screen.width;

	if (w >= 768) {
		return true;
	}

	return false;
};

/**
 * hide headline of video
 * @param {HTMLElement} video - the video to hide headline for
 */
__.hideHeadline = function (video) {
	var stageText = __.getStageText(video);

	if (__.hideHeadlineBreakpointFulfilled() === true) {
		stageText.style.display = 'none';
	}
};

/**
 * show headline of video
 * @param {HTMLElement} video - the video to show headline for
 */
__.showHeadline = function (video) {
	var stageText = __.getStageText(video);

	if (__.hideHeadlineBreakpointFulfilled() === true) {
		stageText.style.display = '';
	}
};

/**
 * perform necessary actions on video start
 * @param {HTMLElement} playBtn - the clicked play button
 */
__.performVideoStartActions = function (playBtn) {
	var videoCon = __.getVideoContainer(playBtn);
	var video = dom.getElement(__.oDefaults.selectorVideo, videoCon);

	__.playVideo(video);
	__.setVideoEndedHandler(video);
	__.hideHeadline(video);
	__.enableVideoFullStageMode(video);
	swipe.unregister(__.oDefaults.selectorStage);
};

/**
 * perform necessary actions on video skip
 * @param {HTMLElement} clickedBtn - the clicked button
 */
__.performVideoSkipActions = function (clickedBtn) {
	var videoCon = __.getVideoContainer(clickedBtn);
	var video = dom.getElement(__.oDefaults.selectorVideo, videoCon);
	var skipButton = __.getSkipButton(video);

	__.stopVideo(video);
	__.showHeadline(video);
	__.disableVideoFullStageMode(video);

	skipButton.classList.add('axs-hidden');
};

/**
 * enable video skip for video
 * @param {HTMLElement} video - the video to enable skip btn for
 */
__.enableVideoSkip = function (video) {
	var skipButton;
	var videoContainer = __.getVideoContainer(video);

	if (!!videoContainer) {
		skipButton = dom.getElement(__.oDefaults.selectorSkipButton, videoContainer);
		skipButton.classList.remove('axs-hidden');
	}
};

__.enableVideoFullStageMode = function (video) {
	var stage = dom.closest(video, __.oDefaults.selectorStage);

	stage.classList.add('axs-stage-full');
};

__.disableVideoFullStageMode = function (video) {
	var stage = dom.closest(video, __.oDefaults.selectorStage);

	stage.classList.remove('axs-stage-full');
};

__.addVideoEvents = function (video, triggerAutoplay) {
	var videoPlayer = dom.closest(video, __.oDefaults.selectorVideoPlayer);
	var playBtn = dom.getElement(__.oDefaults.selectorClassPlayButton, videoPlayer);

	if (triggerAutoplay && videoPlayer.getAttribute('data-loop') !== null) {
		video.setAttribute('loop', '');
	}

	if (triggerAutoplay && videoPlayer.getAttribute('data-autoplay') !== null) {
		__.performVideoStartActions(playBtn);
	}

	playBtn.addEventListener('click', (event_) => {
		var videoCon = __.getVideoContainer(event_.target);
		var localVideo = dom.getElement(__.oDefaults.selectorVideo, videoCon);

		__.enableVideoSkip(localVideo);
		__.performVideoStartActions(event_.target);
	});

	if (videoPlayer.getAttribute('data-autoplay') === null) {
		dom.getEventDelegate('body').on('click', __.oDefaults.selectorSkipButton, (event_) => {
			__.performVideoSkipActions(event_.target);
		});
	}
};

/**
 * add video events
 * @param {HTMLElement} element - the html element containing the video
 */
__.addEventsForVideos = function (element) {
	var videos = dom.getElementsArray(__.oDefaults.selectorVideo, element);
	var i, video;

	for (i = 0; i < videos.length; i++) {
		video = videos[i];

		if (dom.isElement(video)) {
			if (i === 0) {
				__.addVideoEvents(video, true);
			}
			else {
				__.addVideoEvents(video, false);
			}
		}
	}
};

/**
 * get context
 * @param {HTMLElement} data_ - tbd
 * @returns {HTMLElement} - tbd
 */
__.getContext = function (data_) {
	var contextElement;

	if (data_) {
		if (data_.domElement && data_.domElement[0]) {
			contextElement = data_.domElement[0];
		}
		else if (data_.element) {
			contextElement = data_.element;
		}
		else {
			contextElement = document;
		}
	}
	else {
		contextElement = document;
	}

	return contextElement;
};

/**
 * init
 * @param {HTMLElement} data_ - tbd
 */
__.init = function (data_) {
	var context = __.getContext(data_),
		stageCarousel = dom.getElementsArray(__.oDefaults.selectorStage, context),
		carouselInstance;

	stageCarousel.forEach((element) => {
		// Video Control
		__.addEventsForVideos(element);
		carouselInstance = carousel.getCarouselInstance(element, {
			// 'autoAnimation' : Boolean(DOM_UTILS.item(__.oDefaults.selectorStage).getDataAttr('rotation')),
			'pauseAnimation': true,
			'animateCarousel': true,
			'alwaysShowArrows': true
		});
		__.stages.push({
			'context': carouselInstance.context,
			'carousel': carouselInstance
		});
	});

	swipe.register(__.onSwipeFinishHandler, __.oDefaults.selectorStage, {
		'swipedistance': 50
	});
};

/**
 * public initialize method
 * @param {EventBus} globalEventBus - globalEventBus
 * @returns {Promise} returns Promise
 */
exports.initialize = function (globalEventBus) {
	return new Promise(resolve => {
		__.globalEventBus = globalEventBus;
		__.globalEventBus.on(appEvents.LAYER_LOADED, __.init);
		__.init();
		resolve('stage.js');
	});
};

export {exports as stage};
