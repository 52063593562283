export default class CountrySelectorMapAnimation {

	constructor(map_) {
		this.Map = map_;
	}

	/**
	* triggers animation to the given targetViewBox value
	* @param {string} targetViewBox_ - the target viewbox after animation end
	*/
	updateViewBoxChange(targetViewBox_) {
		const startViewBox = this.Map.getAttribute('viewBox').split(' ');
		const endViewBox = targetViewBox_.split(' ');
		const animationDuration = 500;

		this._animateViewBoxChange(startViewBox, endViewBox, animationDuration);
	}

	/**
	* every continent has the corresponding targetViewBox defined as 'data-target-viewbox' attribute
	* @param {Array} startViewbox_ - the inital viewBox to animate from
	* @param {Array} endViewbox_ - the target viewBox to animate to
	* @param {number} animationDuration_ - the duration of the animation
	* @param {number} startedTime_ - a timestamp of the first call
	*/
	_animateViewBoxChange(startViewbox_, endViewbox_, animationDuration_, startedTime_) {
		const now = +new Date();
		let startedTime = startedTime_;

		if (!startedTime) {
			startedTime = now;
		}

		const state = (now - startedTime) / animationDuration_;

		if (state >= 1) {
			this._setViewboxAttribute(endViewbox_);
			window.cancelAnimationFrame(this.animationId);
			return;
		}

		const newViewBox = this._getNewViewBox(startViewbox_, endViewbox_, state);

		this._setViewboxAttribute(newViewBox);
		this.animationId = window.requestAnimationFrame(() => {
			this._animateViewBoxChange(startViewbox_, endViewbox_, animationDuration_, startedTime);
		});
	}

	/**
	 *
	 * @param {Array} startViewbox_ - the inital viewBox to animate from
	 * @param {Array} endViewbox_ - the target viewBox to animate to
	 * @param {number} state_ - the animation progress rate ( 0 <= state_ <= 1)
	 * @returns {Array<number>} viewbox
	 */
	_getNewViewBox(startViewbox_, endViewbox_, state_) {
		return [
			this._computeNewViewBoxValue(startViewbox_[0], endViewbox_[0], state_),
			this._computeNewViewBoxValue(startViewbox_[1], endViewbox_[1], state_),
			this._computeNewViewBoxValue(startViewbox_[2], endViewbox_[2], state_),
			this._computeNewViewBoxValue(startViewbox_[3], endViewbox_[3], state_)
		];
	}
	/**
	* set viewBox value
	* @param {number} start_ - startvalue (can be any of the know viewBox parameters)
	* @param {number} end_ - targetvalue
	* @param {number} state_ - the animation progress rate ( 0 <= state_ <= 1)
	* @returns {number} - the animation value for the actual partial progress
	*/
	_computeNewViewBoxValue(start_, end_, state_) {
		let start = parseInt(start_, 10);
		let end = parseInt(end_, 10);
		let diff = end - start;

		return start + diff * state_;
	}

	/**
	* set viewBox value
	* @param {Array} viewBoxArray_ - the values to be set as Array (x-min, y-min, width, height)
	*/
	_setViewboxAttribute(viewBoxArray_) {
		this.Map.setAttribute('viewBox', '' + viewBoxArray_[0] + ' ' + viewBoxArray_[1] + ' ' + viewBoxArray_[2] + ' ' + viewBoxArray_[3]);
	}
}
