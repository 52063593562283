import {dom} from 'core-utils';

var __ = {},
	exports = {__};

__.oDefaults = {
	selectorForm: 'form.axs-wega-form',
	selectorDependency: '.form-depend-on'

};

__.onClickDependend = function() {
	var dataId = this.getAttribute('id'),
		inputsWithDependencies = dom.getElementsArray(__.oDefaults.selectorForm+' input[data-depend=' + dataId + ']'),
		i, disabled = true;

	if (this.checked) {
		disabled = false;
	}

	for (i = 0; i < inputsWithDependencies.length; i++) {
		inputsWithDependencies[i].disabled = disabled;
	}
};

__.setDependencies = function() {
	var inputsWithDependencies = dom.getElementsArray(__.oDefaults.selectorForm+' '+__.oDefaults.selectorDependency),
		i, lastDependId = '',
		dependId;

	// console.log('form --> setDependencies --> inputsWithDependencies: ', inputsWithDependencies);
	for (i = 0; i < inputsWithDependencies.length; i++) {
		// form-depend-on-DataUseAgreementTos
		dependId = dom.getElement('input', inputsWithDependencies[i]).getAttribute('data-depend');

		if (lastDependId !== dependId) {
			dom.getEventDelegate('body').on('change', 'input#' + dependId, __.onClickDependend);
		}

		lastDependId = dependId;
	}
};

__.init = function() {
	if (document.querySelector(__.oDefaults.selectorForm)) {
		__.setDependencies();
	}
};

/**
 * public initialize method
 * @param {EventBus} globalEventBus - tbd
 * @returns {Promise} returns Promise
 */
exports.initialize = function(globalEventBus) {
	return new Promise(resolve => {
		__.globalEventBus = globalEventBus;
		__.init();

		resolve('form.js');
	});
};

export {exports as form};
