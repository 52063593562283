export default class CountrySelectorModal {

	constructor(countrySelector_) {
		this.CountrySelector = countrySelector_;
		this.offCanvasModal = this.CountrySelector.querySelector('.country-selector-overlay-container');
		this.mapAndContentWrapper = this.CountrySelector.querySelector('.country-selector-map-and-overlay-wrapper');
		this.modalInfoLayerContents = this.CountrySelector.querySelectorAll('.country-selector-overlay-single-country');
		this.closeButton = this.CountrySelector.querySelector('.country-selector-modal-close-button');
	}

	/**
	* trigger all needed actions to blend the offCanvas Modal in and show the right Content
	* @param {string} selectedCountry_ - the ID of the CountryInfo to be shown
	*/
	show(selectedCountry_) {
		this._updateCountryRelatedContentVisibility(selectedCountry_);
		this._setMinHeight(this._getCountryRelatedContentHeight(selectedCountry_));
		this._setElementVisibility(this.offCanvasModal, 'visible');
		this._addEventListeners();
	}

	/**
	* show only content for the selected Country within Modal-Wrapper
	* @param {string} selectedCountry_ - the selected country
	*/
	_updateCountryRelatedContentVisibility(selectedCountry_) {
		let belongingCountry;

		this._resetCountryRelatedContentVisibility();
		Array.from(this.modalInfoLayerContents).forEach(contentBox => {
			belongingCountry = contentBox.getAttribute('data-country-id');

			if (belongingCountry === selectedCountry_) {
				this._setElementVisibility(contentBox, 'visible');
			}
		});
	}

	/**
	* hide all country related content within Modal-Wrapper
	*/
	_resetCountryRelatedContentVisibility() {
		Array.from(this.modalInfoLayerContents).forEach(contentBox => {
			this._setElementVisibility(contentBox, 'hidden');
		});
	}

	/**
	* @param {string} selectedCountry_ - CountryID
	* @returns {number} - the DOM Height of the CountryID related content
	*/
	_getCountryRelatedContentHeight(selectedCountry_) {
		let minHeight = 0;
		const pictureRatio = 5 / 16;

		// the tab has 2 childNodes [picture, div.country-selector-overlay-content-wrapper]
		// the iteration calculates the sum of the image height (through a ratio since not known on execution)
		// and the content directly following it
		Array.from(this.modalInfoLayerContents).forEach(tab => {
			if (tab.getAttribute('data-country-id') === selectedCountry_) {
				minHeight = tab.clientWidth * pictureRatio;
				const content = tab.querySelector('.country-selector-overlay-content-wrapper');

				minHeight += content.clientHeight;
				return minHeight;
			}
		});
		return minHeight;
	}

	/**
	* sets inline style for Modal growth
	* @param {number} minHeight_ - the height to increase to
	*/
	_setMinHeight(minHeight_) {
		this.mapAndContentWrapper.setAttribute('style', 'min-height: ' + minHeight_ + 'px');
	}

	/**
	* trigger all needed actions to blend the offCanvas Modal out and hide all contents within
	*/
	hide() {
		this.mapAndContentWrapper.removeAttribute('style');
		this._setElementVisibility(this.offCanvasModal, 'hidden');
		this.removeEventListeners();
	}

	/**
	* add click event for the close button within Modal
	*/
	_addEventListeners() {
		this.closeButton.addEventListener('click', this._handleCloseButtonClick);
	}

	/**
	* remove click event for the close button within Modal
	*/
	removeEventListeners() {
		this.closeButton.removeEventListener('click', this._handleCloseButtonClick);
	}

	/**
	* triggers different callbacks for blending off the Modal based on the 'ShowContinent' Flag in data model
	*/
	_handleCloseButtonClick = () => {
		this.CountrySelector.updateCountryDropdown('none');
		this.hide();
	};

	/**
	* sets/updates the data-visibility attribute value of a given element
	* @param {HTMLElement} element_ - the DOM element to change the visibility of
	* @param {string} visibility_ - the value to set for the visibility-attribute
	*/
	_setElementVisibility(element_, visibility_) {
		element_.setAttribute('data-visibility', visibility_);
	}

}
