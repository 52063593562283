const template = `
<article>

    <div class="dsh-detail-header clearfix">
        <div class="axs-button-close axs-b-icon axs-icon-close">
            {{var close = window.i18n["mssc.ui.common.icon.close"] || "X";}}
            <span>{{=close}}</span>
        </div>
    	<h2 class="axs-el-hdl axs-el-hdl-03 axs-at-hdl-b">{{=it.name}}</h2>
    </div>

    <div class="dsh-detail-main">

        <ul class="dsh-detail-info">
			{{?!!it.address.display}}
            <li>
                <p class="axs-el-lbl axs-el-lbl-01">{{=it.address.display[0]}}<br/>
                {{=it.address.display[1]}}</p>
            </li>
			{{?}}

            <li>
                {{var phone = window.i18n["dsh.label.phone"] || "Telefon";}}
                {{var fax = window.i18n["dsh.label.fax"] || "Telefax";}}
				{{?!!it.contactDetails.phone.display || it.contactDetails.fax.display}}
                <p class="axs-el-lbl axs-el-lbl-01">
					{{?!!it.contactDetails.phone.display}}
						{{=phone}}: {{=it.contactDetails.phone.display}}<br/>
					{{?}}
					{{?!!it.contactDetails.fax.display}}
						{{=fax}}: {{=it.contactDetails.fax.display}}
					{{?}}
                </p>
				{{?}}
            </li>

            <li>
                {{var dealer = window.i18n["dsh.details.label.dealer"] || "Audi Partner (Verkauf)";}}
                {{var service = window.i18n["dsh.details.label.service"] || "Audi Partner (Service)";}}
                {{?!!it.dealer}}<p class="dsh-result-type dsh-icon-dealer axs-el-lbl axs-el-lbl-01">{{=dealer}}</p>{{?}}
                {{?!!it.service}}<p class="dsh-result-type dsh-icon-service axs-el-lbl axs-el-lbl-01">{{=service}}</p>{{?}}
            </li>

            {{?it.dshConfig.DealerSearchEnableAskMode === 'on'}}

            {{var kvpsid = window.i18n["dsh.details.label.dealer.ask.kvpsid"] || "Betriebsnummer";}}
            {{var regionNumber = window.i18n["dsh.details.label.dealer.ask.regionNumber"] || "Regionsnummer";}}
            {{var distributionCenter = window.i18n["dsh.details.label.dealer.ask.distributionCenter"] || "VZ-Nummer";}}
            {{var contractStatusGvo = window.i18n["dsh.details.label.dealer.ask.contractStatusGvo"] || "Vertragsstatus";}}

            <li>
                <p class="axs-el-lbl axs-el-lbl-01 dsh-ask-mode-values">
                {{?!!it.kvpsid}}
                    {{=kvpsid}}: {{=it.kvpsid}}<br/>
                {{?}}
                {{?!!it.address.regionNumber}}
                    {{=regionNumber}}: {{=it.address.regionNumber}}<br/>
                {{?}}
                {{?!!it.address.distributionCenter}}
                    {{=distributionCenter}}: {{=it.address.distributionCenter}}
                {{?}}
                {{?!!it.contractStatusGvo}}
                    {{=contractStatusGvo}}: {{=it.contractStatusGvo}}
                {{?}}
                </p>
            </li>
            {{?}}
    	</ul>

        <p class="dsh-dealer-links">

			{{?it.dshConfig.DealerSearchShowRouteplannerLink === 'on'}}
				{{var calcRoute = window.i18n["dsh.details.label.dealer.calculate.route"] || "Route berechnen";}}
				<span><a href="{{=it.directionsLink}}" class="axs-el-lk axs-el-lk-04 axs-el-lk-icn axs-at-lk-variant axs-icon-routing">{{=calcRoute}}</a></span>
            {{?}}

            {{?!!it.url}}
            {{var website = window.i18n["dsh.details.label.dealer.website"] || "Website";}}
            <span><a href="{{=it.url}}" class="axs-el-lk axs-el-lk-04 axs-el-lk-icn axs-at-lk-variant axs-icon-url" target="_blank">{{=website}}</a></span>
            {{?}}

            {{?!!it.contactDetails.email}}
            {{var enquiry = window.i18n["dsh.details.label.dealer.email"] || "E-Mail";}}
			{{var subject = window.i18n["dsh.details.label.dealer.email.subject"] || "";}}
            <span><a href="mailto:{{=it.contactDetails.email}}?subject={{=subject.replace(/ /g, '%20')}}" class="axs-el-lk axs-el-lk-04 axs-el-lk-icn axs-at-lk-variant axs-icon-enquiry">{{=enquiry}}</a></span>
            {{?}}

			{{?it.dshConfig.DealerSearchShowTestDriveLink !== 'off'}}
				{{var testdrive = window.i18n["dsh.details.label.dealer.testdrive"] || "Probefahrt anfragen";}}
				<span><a href="#" class="axs-el-lk axs-el-lk-04 axs-el-lk-icn axs-at-lk-variant axs-icon-testdrive">{{=testdrive}}</a></span>
            {{?}}

			{{?it.dshConfig.DealerSearchShowPrintLink !== 'off'}}
				{{var print = window.i18n["dsh.details.label.print"] || "Drucken";}}
				<span><a href="#" class="axs-el-lk axs-el-lk-04 axs-el-lk-icn axs-at-lk-variant axs-icon-print">{{=print}}</a></span>
            {{?}}

			{{?it.dshConfig.DealerSearchShowVcfLink !== 'off'}}
				{{var vcard = window.i18n["dsh.details.label.dealer.vcard"] || "Download vCard";}}
				<span><a href="#" class="axs-el-lk axs-el-lk-04 axs-el-lk-icn axs-at-lk-variant axs-icon-vcard">{{=vcard}}</a></span>
            {{?}}

        </p>

        <div class="dsh-tab-section clearfix">
            <ul class="dsh-tabs">
                {{var location = window.i18n["dsh.details.label.dealer.location"] || "Standort";}}
                {{?!!it.notes && it.notes.openingHoursHTML}}
                <li class="axs-el-lbl axs-el-lbl-03 active-item" data-tab="">{{=location}}</li>
                {{?}}
                <li class="axs-el-lbl axs-el-lbl-03 active" data-tab="dsh-location">{{=location}}</li>
                {{?!!it.notes && it.notes.openingHoursHTML}}
				{{var openinghours = window.i18n["dsh.details.label.dealer.openinghours"] || "&Ouml;ffnungszeiten";}}
                <li class="axs-el-lbl axs-el-lbl-03" data-tab="dsh-opening-hours">{{=openinghours}}</li>
                {{?}}
            </ul>

            <div class="dsh-panels">
                <div class="dsh-panel active" data-panel="dsh-location">
					<div class="dsh-partner-map"></div>
                </div>
                {{?!!it.notes && it.notes.openingHoursHTML}}
                <div class="dsh-panel dsh-opening-hours axs-el-lbl axs-el-lbl-01" data-panel="dsh-opening-hours">
                    {{=it.notes.openingHoursHTML}}
                </div>
                {{?}}

            </div>
        </div>

    </div>
</article>
`;

export {template as dshPartnerDetailsTemplate};
