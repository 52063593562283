/* global MarkerClusterer */

import {mapStylesDefault as MAPSTYLES_DEFAULT} from './map-mapstyles-default';
import {mapStylesToplevel as MAPSTYLES_TOPLEVEL} from './map-mapstyles-toplevel';
import {mapDataUris as DATA_URIS} from './map-data-uris';

var __, exports;

__ = {};
exports = {
	__
};
__.dataURIs = JSON.parse(DATA_URIS);
__.clusterStyles = [{
	url: __.dataURIs.clusterMarker,
	height: 30,
	width: 30,
	anchor: [0, 0],
	textColor: '#ffffff',
	textSize: 12
}, {
	url: __.dataURIs.clusterMarker,
	height: 30,
	width: 30,
	anchor: [0, 0],
	textColor: '#ffffff',
	textSize: 12
}, {
	url: __.dataURIs.clusterMarker,
	height: 30,
	width: 30,
	anchor: [0, 0],
	textColor: '#ffffff',
	textSize: 12
}, {
	url: __.dataURIs.clusterMarker,
	height: 30,
	width: 30,
	anchor: [0, 0],
	textColor: '#ffffff',
	textSize: 12
}];
__.clusterStylesInactive = [{
	url: __.dataURIs.clusterMarkerInactive,
	height: 50,
	width: 42,
	anchor: [8, 0],
	textColor: '#ffffff',
	textSize: 12
}, {
	url: __.dataURIs.clusterMarkerInactive,
	height: 50,
	width: 42,
	anchor: [8, 0],
	textColor: '#ffffff',
	textSize: 12
}, {
	url: __.dataURIs.clusterMarkerInactive,
	height: 50,
	width: 42,
	anchor: [8, 0],
	textColor: '#ffffff',
	textSize: 12
}, {
	url: __.dataURIs.clusterMarkerInactive,
	height: 50,
	width: 42,
	anchor: [8, 0],
	textColor: '#ffffff',
	textSize: 12
}];
__.topLevelMapStyle = JSON.parse(MAPSTYLES_TOPLEVEL);
__.defaultMapStyle = JSON.parse(MAPSTYLES_DEFAULT);
__.mapTypeControl = false;
__.panControl = false;
__.streetViewControl = false;
__.zoomControl = false;
__.fullscreenControl = false;
__.map = null;
__.markerCluster = null;

exports.createCluster = function(map, markers) {
	__.markerCluster = new MarkerClusterer(__.map, markers, {
		gridSize: 75,
		maxZoom: 8,
		styles: __.clusterStyles,
		averageCenter: true
	});
	return __.markerCluster;
};

exports.updateCluster = function(markers) {
	__.markerCluster.clearMarkers();
	__.markerCluster.addMarkers(markers, false);
};

/*
	* marker highlighting
	*/
exports.highlightMarkers = function(markers, id) {
	var styles, l, m, iconName;

	styles = !!id ? __.clusterStylesInactive : __.clusterStyles;
	l = markers.length;

	while (l--) {
		m = markers[l];
		iconName = (!!id) ? m.data.inactiveIconKey : m.data.activeIconKey;

		if (m.data.kvpsid === id) {
			iconName = m.data.activeIconKey;
		}

		m.setIcon({
			url: __.dataURIs[iconName]
		});
	}

	__.markerCluster.setStyles(styles);
	__.markerCluster.resetViewport();
	__.markerCluster.redraw();
};

/*
	* marker highlighting --> reset
	*/
exports.resetMarkers = function(markers) {
	var styles, l, m, iconName;

	styles = __.clusterStyles;
	l = markers.length;

	while (l--) {
		m = markers[l];
		iconName = m.data.activeIconKey;
		m.setIcon({
			url: __.dataURIs[iconName]
		});
	}

	__.markerCluster.setStyles(styles);
	__.markerCluster.resetViewport();
	__.markerCluster.redraw();
};

exports.highlightCluster = function(index) {
	var c = __.markerCluster.clusters_[index];

	c.clusterIcon_.remove();
	c.clusterIcon_ = new ClusterIcon(c, __.clusterStylesInactive, __.markerCluster.getGridSize());
	c.updateIcon();
};

exports.getMarkerIcon = function(data, inactive) {
	var inactivePostfix;

	inactivePostfix = !!inactive ? '_inactive' : '';
	return __.itemIsSales(data) ? __.dataURIs['pin_audi_active' + inactivePostfix] : __.dataURIs['pin_service_active' + inactivePostfix];
};

/* polling for Google Availability
	* @returns {Promise}
	*/
exports.waitForMaptoBeAvailable = function() {
	return new Promise((resolve, reject) => {
		var interval, runsFor, intervalTime, timeout;

		intervalTime = 100;
		timeout = 2000;
		runsFor = 0;

		if (!!window.google) {
			resolve(window.google);
		}
		else {
			interval = setInterval(() => {
				if (!!window.google) {
					clearInterval(interval);
					resolve(window.google);
				}
				else if (runsFor > timeout) {
					clearInterval(interval);
					reject(new Error('google not available'));
				}

				runsFor += intervalTime;
			}, intervalTime);
		}
	});
};

exports.renderMap = function(opts) {
	return new Promise((resolve, reject) => {
		var mapOpts, center, axsMapContainer;

		exports.waitForMaptoBeAvailable().then(() => {
			axsMapContainer = document.querySelector('.map-map');

			if (axsMapContainer) {
				__.defaultStyledMap = new google.maps.StyledMapType(__.defaultMapStyle, {
					name: 'Default Style'
				});
				__.topLevelStyledMap = new google.maps.StyledMapType(__.topLevelMapStyle, {
					name: 'Top Level Style'
				});
				center = opts.defaultMapCenter.split(',');
				mapOpts = {
					center: new google.maps.LatLng(center[0], center[1]),
					zoom: opts.defaultZoomLevel,
					mapTypeId: google.maps.MapTypeId.ROADMAP,
					styles: __.topLevelMapStyle,
					panControl: __.panControl,
					mapTypeControl: __.mapTypeControl,
					streetViewControl: __.streetViewControl,
					zoomControl: __.zoomControl,
					fullscreenControl: __.fullscreenControl
				};
				__.map = new google.maps.Map(axsMapContainer, mapOpts);
				__.map.mapTypes.set('default', __.defaultStyledMap);
				__.map.mapTypes.set('topLevel', __.topLevelStyledMap);
				resolve(__.map);
			}
		}).catch((err) => {
			console.error(err);
			reject('could not load Google Maps');
		});
	});
};

exports.addListener = function(eventStr, handler) {
	return google.maps.event.addListener(__.map, eventStr, handler);
};

exports.removeListener = function(listener) {
	google.maps.event.removeListener(listener);
};

exports.topLevelMapStyle = __.topLevelMapStyle;
exports.defaultMapStyle = __.defaultMapStyle;

exports.getMap = function() {
	return __.map;
};

export {exports as map};
