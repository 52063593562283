import {dom, template} from 'core-utils';
import {socialreaderTemplate as SOCIALREADER_ITEM} from './socialreader-template';

var __ = {},
	exports = {__};

__.oDefaults = {
	'selectorSocialreader': '.axs-md-socialreader',
	'sSelectorContainer': '.axs-md-socialreader-container',
	'sDataJsonApi': 'data-base',
	'gridRange': [600, 800],
	'detailRange': [1000, 1600]
};

/**
 * Quicksort
 * @param {Array} arr_ - array to be sorted
 * @param {string} key_ - attribute to be sorted by
 * @returns {Array} - sorted array
 */
__.sortHashedArray = function (arr_, key_) {
	arr_.sort((a, b) => {
		var ret;

		if (a[key_] > b[key_]) {
			ret = -1;
		}
		else if (a[key_] < b[key_]) {
			ret = 1;
		}
		else {
			ret = 0;
		}

		// return a[key_] > b[key_] ? -1 : (a[key_] < b[key_] ? 1 : 0);
		return ret;
	});
	return arr_;
};

/**
 * find images for detail and list view that match the following
 * conditions: gridView (maxWidth:600 and ratio closest to 16:9)
 * detailView (maxWidth:1600,minWidth:1000,ratio closest to 16:9)
 * @param {Array} imagesArr_ - Array of Images
 * @returns {Object} - Object with urls
 */
__.findMatchingImageSizes = function (imagesArr_) {
	var gridViewImages = [],
		detailViewImages = [],
		w, imgData, i, j;

	for (i = 0, j = imagesArr_.length; i < j; i++) {
		imgData = imagesArr_[i];
		w = imgData.width;

		// filter grid Images by width (range from 600…800)
		if (w >= __.oDefaults.gridRange[0] && w <= __.oDefaults.gridRange[1]) {
			gridViewImages.push(imagesArr_[i]);
		}

		// filter detail Images by width (range from 1000...1600)
		if (w >= __.oDefaults.detailRange[0] && w <= __.oDefaults.detailRange[1]) {
			detailViewImages.push(imagesArr_[i]);
		}
	}

	// quick sort by width
	gridViewImages = __.sortHashedArray(gridViewImages, 'width');
	detailViewImages = __.sortHashedArray(detailViewImages, 'width');
	return {
		'gridView': gridViewImages[0] || imagesArr_[0] || null,
		'detailView': detailViewImages[0] || imagesArr_[0] || null
	};
};

/**
 * processTemplateData
 * @param {Object} data_ - Template Data
 * @returns {Object} returns Items
 */
__.processTemplateData = function (data_) { // eslint-disable-line max-statements
	var item, items = [],
		oItem, len = data_.length || 0,
		images, i;

	for (i = 0; i < len; i++) {
		oItem = data_[i];
		item = {};
		images = __.findMatchingImageSizes(oItem.images ? oItem.images[0] : []);
		item.id = oItem.id;
		item.url = oItem.url;
		item.gridImage = !!images.gridView && !!images.gridView.src ? images.gridView.src : null;
		item.detailImage = !!images.detailView && !!images.detailView.src ? images.detailView.src : null;
		// item.attachment = oItem.attachment;
		item.description = oItem.description || '';
		item.title = oItem.title || '';
		item.author = !!oItem.author && oItem.author.name ? oItem.author.name : '';
		item.source = !!oItem.source && oItem.source.network ? oItem.source.network : '';
		item.contentType = !!oItem.source && !!oItem.source.type ? oItem.source.type : 'photo';
		item.videoEmbed = item.contentType === 'video' && !!oItem.attachment[0] && oItem.attachment[0].content && !!oItem.attachment[0].content.embed ? oItem.attachment[0].content.embed : null;
		item.videoRatio = !!item.videoEmbed ? (oItem.attachment[0].thumbnail.height / oItem.attachment[0].thumbnail.width * 100) : '56.25';
		item.icon = !!oItem.source && oItem.source.icon ? oItem.source.icon : 'default';
		item.date = !!oItem.date ? oItem.date : '';
		item.postUrl = oItem.url || '';
		items.push(item);
	}

	return items;
};

/**
 * update socialmedia content within the context container
 * TODO: DOM_UTILS (Version in Stockcar) provides Template Rendering. Include that here.
 * @param {Object} context_ - tbd
 * @param {Object} data_ - tbd
 * @returns {Promise} returns Promise
 */
__.updateFragments = function (context_, data_) {
	var container = dom.getElement(__.oDefaults.sSelectorContainer, context_),
		i, len, item,
		tplHtml, tplDataArr;

	tplDataArr = __.processTemplateData(data_);

	if (!!data_ && tplDataArr.length) {
		if (tplDataArr.length > 3) {
			len = tplDataArr.length;

			for (i = 0; i < len; i++) {
				// save dateil content to internal
				// __.detailDataMap[tplDataArr[i].id] = tplDataArr[i];
				// limit display to 8 items
				if (i < 8) {
					tplHtml = template.render(SOCIALREADER_ITEM, tplDataArr[i]);
					item = document.createElement('div');
					item.innerHTML = tplHtml;
					container.appendChild(item.firstChild);
				}
			}
		}
	}

	return Promise.resolve({
		container
	});
};

/**
 * fetchContent
 * @param {string} url_ - url to fetch
 * @returns {Promise} - content of module
 */
__.fetchContent = function (url_) {
	return fetch(url_, {
		method: 'get',
		credentials: 'omit'
	}).then((response_) => {
		return response_.json();
	}).catch((error_) => {
		console.error('content loading for socialreader failed:', error_);
	});
};

/**
 * loadModule
 * @param {HTMLElement} srModule - selected Module
 * @param {string} url - url
 * @returns {Promise} returns Promise
 */
__.loadModule = function (srModule, url) {
	var promise = __.fetchContent(url).then((data) => {
		return __.updateFragments(srModule, data);
	}).then(undefined, (err) => {

		console.error(err);
		throw new TypeError(err);

	});

	return promise;
};

__.findModules = function () {
	var srModules = dom.getElementsArray('body ' + __.oDefaults.selectorSocialreader),
		promise, url, srModule,
		i,
		pomisesArr = [];

	for (i = 0; i < srModules.length; i++) {
		srModule = srModules[i];
		url = srModule.getAttribute(__.oDefaults.sDataJsonApi);

		promise = __.loadModule(srModule, url);

		pomisesArr.push(promise);
	}
};

__.init = function () {
	if (dom.isElement(__.oDefaults.selectorSocialreader)) {
		__.findModules();
	}
};

/**
 * public initialize method
 * @param {EventBus} globalEventBus - globalEventBus
 * @returns {Promise} returns Promise
 */
exports.initialize = function (globalEventBus) {

	return new Promise(resolve => {
		__.globalEventBus = globalEventBus;
		__.init();

		resolve('socialreader.js');
	});
};

export {exports as socialreader};
