import {signal} from 'application-bundle';
import {dom} from 'core-utils';

export default class AnchorList {
	static get EVENT_ANCHOR_ACTIVATED() {
		return 'axs-anchor-navigation-anchor-activated';
	}
	static get MARKER() {
		return 0.75;
	}

	constructor() {
		this.anchorList = [];
		this.activeAnchorReference = '';
		this._initialize();
	}

	_initialize() {
		this._initializeAnchors();

		let lastClosestAnchorReference = this._calculateClosestAnchorReferenceAboveMarker() || (this.firstAnchorInDom && this.firstAnchorInDom.name);

		if (lastClosestAnchorReference) {
			this._activateAnchorReference(lastClosestAnchorReference);

			window.addEventListener('scroll', dom.throttle(this._onScrollHandler.bind(this), 100));
		}
	}

	_initializeAnchors() {
		let element;
		const anchors = document.getElementsByClassName('axs-module-anchor-navigation-anchor');

		for (let key = 0; key < anchors.length; key++) {
			element = anchors[key];
			this.anchorList[element.name] = element;

			if (!this.firstAnchorInDom || this._isAboveAnchor(element, this.firstAnchorInDom)) {
				this.firstAnchorInDom = element;
			}
		}
	}

	/**
	 *
	 * @param {HTMLElement} anchor1 - anchor element
	 * @param {HTMLElement} anchor2 - anchor element
	 * @returns {boolean} whether anchor1 is positioned above anchor2
	 */
	_isAboveAnchor(anchor1, anchor2) {
		return anchor1.getBoundingClientRect().top < anchor2.getBoundingClientRect().top;
	}

	_onScrollHandler() {
		let lastClosestAnchorReference = this._calculateClosestAnchorReferenceAboveMarker() || this.firstAnchorInDom.name;

		this._activateAnchorReference(lastClosestAnchorReference);
	}

	/**
	 * @returns {string} the closest anchor reference above the marker
	 */
	_calculateClosestAnchorReferenceAboveMarker() {
		let distanceToMarker;
		let lastClosestAnchorReference;
		let lastClosestMarkerDistance = -1;

		for (let key in this.anchorList) {
			if (Object.prototype.hasOwnProperty.call(this.anchorList, key)) {
				distanceToMarker = this._getDistanceToMarker(this.anchorList[key]);

				if (this._isDistanceCloserToMarkerThanLastDistance(distanceToMarker, lastClosestMarkerDistance)) {
					lastClosestAnchorReference = key;
					lastClosestMarkerDistance = distanceToMarker;
				}
			}
		}

		return lastClosestAnchorReference;
	}

	/**
	 * @param {string} anchorReference - the anchorReference to be activated
	 */
	_activateAnchorReference(anchorReference) {
		if (this.activeAnchorReference !== anchorReference) {
			this.activeAnchorReference = anchorReference;
			signal.getEmitter().emit(AnchorList.EVENT_ANCHOR_ACTIVATED, {reference: this.activeAnchorReference});
		}
	}

	/**
	 * @param {number} distanceToMarker - distance to marker
	 * @param {number} lastClosestMarkerDistance - the last closest distance to marker
	 * @returns {boolean} -
	 */
	_isDistanceCloserToMarkerThanLastDistance(distanceToMarker, lastClosestMarkerDistance) {
		return distanceToMarker >= 0 && (distanceToMarker < lastClosestMarkerDistance || lastClosestMarkerDistance === -1);
	}

	/**
	 *
	 * @param {HTMLElement} anchorElement - the anchor element to be checked
	 * @returns {number} - the distance to the marker
	 */
	_getDistanceToMarker(anchorElement) {
		const markerPositionInDocument = window.innerHeight * AnchorList.MARKER;

		return markerPositionInDocument - anchorElement.getBoundingClientRect().top;
	}
}
