import {dom} from 'core-utils';
import OndemandConfigurator from '../configurator/_configurator';

export default class Details extends HTMLElement {
	// constants
	static get CLASS_PRICE_SELECTED() { return 'axs-ondemand-configurator__details-price-option--selected'; }

	/**
	 * constructor
	 */
	constructor() {
		super();
		this.ondemandConfigurator = null;
		this.handleChangedModelFilter = this._handleChangedModelFilter.bind(this);
	}

	/**
	 * connectedCallback - callback for adding element to dom/shadow dom
	 */
	connectedCallback() {
		this._initialize();
		this._bind();
	}

	disconnectedCallback() {
		this._unbindEvents();
	}

	_initialize() {
		this.ondemandConfigurator = dom.closest(this, '.axs-ondemand-configurator');
		this.setAttribute('data-first-image-loaded', 'false');

		// firstVisibleImage = second image of carousel
		const firstVisibleImage = this.querySelector('.axs-ondemand-configurator__detail-gallery-item:nth-child(2) .audi-axs-caroussel-item-image');

		firstVisibleImage.onload = () => this.setAttribute('data-first-image-loaded', 'true');
	}

	_bind() {
		this.ondemandConfigurator.addEventListener(OndemandConfigurator.EVENT_MODELFILTER_CHANGED, this.handleChangedModelFilter);
	}

	_unbindEvents() {
		this.ondemandConfigurator.removeEventListener(OndemandConfigurator.EVENT_MODELFILTER_CHANGED, this.handleChangedModelFilter);
	}

	/**
	 * @param {Event} filterChangedEvent_ - filter change event
	 * @param {string} filterChangedEvent_.detail.filterProperty - filter property
	 * @param {string} filterChangedEvent_.detail.filterValue.value - filer value (price id)
	 */
	_handleChangedModelFilter(filterChangedEvent_) {
		if (filterChangedEvent_.detail.filterProperty === 'price') {
			let priceId = filterChangedEvent_.detail.filterValue.value;

			if (priceId) {
				this._showSelectedPriceOptions(priceId);
			}
		}
	}

	/**
	 * @param {string} selectedPriceId_ - selected price id
	 */
	_showSelectedPriceOptions(selectedPriceId_) {
		const priceOptions = this.querySelectorAll('.axs-ondemand-configurator__details-price-option');
		const priceOptionsLength = priceOptions.length;

		for (let i = 0; i < priceOptionsLength; i++) {
			let option = priceOptions[i];
			let priceId = this._getPriceIdOfOption(option);

			if (priceId === selectedPriceId_) {
				this._selectPriceOption(option);
			}
			else {
				this._unselectPriceOption(option);
			}
		}
	}

	/**
	 * @param {HTMLElement} option_ - price option div
	 * @returns {string} - price-id from data attribute
	 */
	_getPriceIdOfOption(option_) {
		return option_.getAttribute('data-priceid');
	}

	/**
	 * @param {HTMLElement} option_ - price option div
	 */
	_selectPriceOption(option_) {
		option_.classList.add(Details.CLASS_PRICE_SELECTED);
	}

	/**
	 * @param {HTMLElement} option_ - price option div
	 */
	_unselectPriceOption(option_) {
		option_.classList.remove(Details.CLASS_PRICE_SELECTED);
	}
}

if (window.customElements.get('audi-ondemand-configurator-details') === undefined) {
	window.customElements.define('audi-ondemand-configurator-details', Details);
}
