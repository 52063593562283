import DropDown from './_dropdown';
import Flyout from './_flyout';
import OndemandConfigurator from '../configurator/_configurator';
import {dom} from 'core-utils';

export default class ModelFilter extends HTMLElement {
	/**
	 * constructor
	 */
	constructor() {
		super();
		this.ondemandConfigurator = null;
		this.onFilterChange = this._onFilterChange.bind(this);
		this.applyPriceFilter = this._applyPriceFilter.bind(this);
		this.dropdownPrice = null;
	}

	/**
	 * connectedCallback - callback for adding element to dom
	 */
	connectedCallback() {
		this._initialize();
		this._addEvents();
	}

	disconnectedCallback() {
		this._removeEvents();
	}

	_initialize() {
		const dropdownPrice = this.querySelector('[dropdown-id="price"]');

		if (dropdownPrice) {
			this.dropdownPrice = dropdownPrice;
		}

		this.ondemandConfigurator = dom.closest(this, '.axs-ondemand-configurator');
		this.models = this.parentElement.querySelectorAll('.axs-ondemand-configurator__modelfinder-modellist-item');
	}

	_addEvents() {
		this.addEventListener(DropDown.EVENT_DROPDOWN_ITEM_SELECTED, this.onFilterChange);
		this.addEventListener(Flyout.EVENT_FLYOUT_SELECTION_CHANGE, this.onFilterChange);

		if (this.dropdownPrice) {
			this.ondemandConfigurator.addEventListener(OndemandConfigurator.EVENT_DETAILVIEW_EMBEDDED, this.applyPriceFilter);
		}
	}

	_removeEvents() {
		this.removeEventListener(DropDown.EVENT_DROPDOWN_ITEM_SELECTED, this.onFilterChange);
		this.removeEventListener(Flyout.EVENT_FLYOUT_SELECTION_CHANGE, this.onFilterChange);
	}

	/**
	 * @param {CustomEvent} event_ - DropDown.EVENT_DROPDOWN_ITEM_SELECTED
	 */
	_onFilterChange(event_) {
		const eventDetail = {
			filterProperty: event_.detail.dropdownId,
			filterValue: event_.detail.selectedItemValues
		};

		this._informConfiguratorAboutFilter(eventDetail);
	}

	/**
	 * _informConfiguratorAboutFilter - fire event with filter data to configurator
	 * @param {Object} eventDetail_ - event detail data
	 */
	_informConfiguratorAboutFilter(eventDetail_) {
		const modelfilterChangedEvent = new CustomEvent(OndemandConfigurator.EVENT_MODELFILTER_CHANGED, {detail: eventDetail_});

		this.ondemandConfigurator.dispatchEvent(modelfilterChangedEvent);
	}

	/**
	 * _applyPriceFilter - read out price filter and apply it by fireing event
	 */
	_applyPriceFilter() {
		const selectedItem = this.dropdownPrice.querySelector('.axs-ondemand-configurator__modelfilter-dropdown-item-selected');

		if (selectedItem) {
			let eventDetail = {
				filterProperty: 'price',
				filterValue: {
					value : selectedItem.getAttribute('value'),
					label : selectedItem.getAttribute('label')
				}
			};

			this._informConfiguratorAboutFilter(eventDetail);
		}
	}
}

if (window.customElements.get('audi-axs-ondemand-configurator-modelfilter') === undefined) {
	window.customElements.define('audi-axs-ondemand-configurator-modelfilter', ModelFilter);
}

