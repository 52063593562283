import {dom} from 'core-utils';

export default class Flyout extends HTMLElement {

	static get EVENT_FLYOUT_SELECTION_CHANGE() {
		return 'flyout-selection-change';
	}

	/**
	 * constructor
	 */
	constructor() {
		super();
		this.modelfilter = null;
		this.changeItemSelectionHandler = this._changeItemSelectionHandler.bind(this);
	}

	/**
	 * connectedCallback - callback for adding element to dom
	 */
	connectedCallback() {
		this._initialize();
		this._addEvents();
	}

	disconnectedCallback() {
		this._removeEvents();
	}

	_initialize() {
		this.modelfilter = dom.closest(this, '.axs-ondemand-configurator__modelfilter');
		this.dropdown = this._getDropdown();
	}

	_addEvents() {
		let items = this.querySelectorAll('.axs-ondemand-configurator__modelfilter-flyout-input');
		let itemsLength = items.length;

		for (let i = 0; i < itemsLength; i++) {
			items[i].addEventListener('change', this.changeItemSelectionHandler);
		}
	}

	_removeEvents() {
		let items = this.querySelectorAll('.axs-ondemand-configurator__modelfilter-flyout-input');
		let itemsLength = items.length;

		for (let i = 0; i < itemsLength; i++) {
			items[i].removeEventListener('change', this.changeItemSelectionHandler);
		}
	}

	_changeItemSelectionHandler() {
		const selectedItemsData = this._getSelectedItemsData();

		this._dispatchSelectionChangeEvent(selectedItemsData);
		this._changeLabel(selectedItemsData);
	}

	_getSelectedItemsData() {
		const items = this.querySelectorAll('.axs-ondemand-configurator__modelfilter-flyout-input:checked');

		let itemsLength = items.length;
		let data = [];

		for (let i = 0; i < itemsLength; i++) {
			data.push(items[i].value);
		}

		return data;
	}

	/**
	 * @param {Array} selectedItemsData - selectedItemsData
	 */
	_changeLabel(selectedItemsData) {
		if (this.dropdown) {
			let label = this.dropdown.querySelector('.axs-ondemand-configurator__modelfilter-dropdown-selection-value');

			if (label) {
				if (selectedItemsData.length > 0) {
					label.innerHTML = window.i18n['axs.configurator.ondemand.filter.bodytype.yourselection'];
				}
				else {
					label.innerHTML = window.i18n['axs.configurator.ondemand.filter.bodytype.allvehicles'];
				}
			}
		}
	}

	/**
	 * @param {Object} selectedItemsData - the data to be emitted
	 */
	_dispatchSelectionChangeEvent(selectedItemsData) {
		const data = {
			detail: {dropdownId: this.id, selectedItemValues: selectedItemsData}
		};
		let selectionChangeEvent = new CustomEvent(Flyout.EVENT_FLYOUT_SELECTION_CHANGE, data);

		this.modelfilter.dispatchEvent(selectionChangeEvent);
	}

	/**
	 * @returns {HTMLElement} - the dropdown for the flyout
	 */
	_getDropdown() {
		return document.querySelector(`.axs-ondemand-configurator__modelfilter-dropdown[dropdown-id="${this.id}"]`);
	}

	get id() {
		return this.getAttribute('dropdown-id');
	}
}

if (window.customElements.get('audi-ondemand-configurator-modelfilter-flyout') === undefined) {
	window.customElements.define('audi-ondemand-configurator-modelfilter-flyout', Flyout);
}
