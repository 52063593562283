const template = `
[
	{
    "featureType": "landscape"  },{
    "featureType": "landscape.man_made",
    "stylers": [
      { "color": "#cccccc" },
      { "visibility": "on" }
    ]
  },{
		"featureType": "water",
		"elementType": "geometry",
		"stylers": [
			{ "color": "#e5e5e5" }
		]
	},{
	"featureType": "water",
	"elementType": "labels",
	"stylers": [
		{ "visibility": "off" }
	]
},{
	"featureType": "administrative.locality",
	"stylers": [
		{ "visibility": "simplified" }
	]
},{
	"featureType": "administrative.province",
	"stylers": [
		{ "visibility": "off" }
	]
},{
	"featureType": "administrative.neighborhood",
	"stylers": [
		{ "visibility": "off" }
	]
},{
	"featureType": "administrative.land_parcel",
	"stylers": [
		{ "visibility": "off" }
	]
},{
	"featureType": "landscape.natural.terrain",
	"stylers": [
		{ "visibility": "off" }
	]
},{
	"featureType": "landscape.natural.landcover",
	"elementType": "geometry",
	"stylers": [
		{ "color": "#cccccc" }
	]
},{
	"featureType": "administrative.country",
	"elementType": "geometry.stroke",
	"stylers": [
		{ "color": "#e5e5e5" },
		{ "weight": 2 }
	]
},{
	"featureType": "administrative.country",
	"elementType": "labels.text.fill",
	"stylers": [
		{ "color": "#999999" }
	]
},{
	"featureType": "administrative.country",
	"elementType": "labels.text.stroke",
	"stylers": [
		{ "visibility": "off" }
	]
},{
	"featureType": "poi",
	"stylers": [
		{ "visibility": "off" }
	]
},{
	"featureType": "road",
	"stylers": [
		{ "visibility": "on" }
	]
},{
	"featureType": "transit",
	"stylers": [
		{ "visibility": "on" }
	]
},{
	"featureType": "landscape.natural",
	"elementType": "geometry",
	"stylers": [
		{ "color": "#cccccc" }
	]
}
]
`;

export {template as mapStylesDefault};

