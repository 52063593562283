import IframeMessageHandler from './_iframe-message-handler';

export default class IFrame extends HTMLElement {

	constructor() {
		super();
		this.showIframe = this._showIFrame.bind(this);
		this.setIframeHeight = this._setIframeHeight.bind(this);
	}

	connectedCallback() {
		this._init();
		IframeMessageHandler.registerIframe(this, this.setIframeHeight);
	}

	disconnectedCallback() {
		IframeMessageHandler.unregisterIframe(this);
	}

	_init() {
		this.iframeContent = this.querySelector('.axs-iframe__iframe-content');
		this._addEvents();
		this._triggerLoading();
	}

	_addEvents() {
		this.iframeContent.addEventListener('load', this.showIframe);
	}

	/**
	 * resetting the src attribute triggers loading of the iframe and firing of load event
	 * necessary because maybe iframe was loaded before event listener for load event was added
	 */
	_triggerLoading() {
		const sourceUrl = this.iframeContent.getAttribute('src');

		this.iframeContent.setAttribute('src', sourceUrl);
	}

	_showIFrame() {
		this.iframeContent.classList.remove('axs-iframe__iframe-content--hidden');
	}

	/**
	 * remove css classes, that define an fix aspect-ratio for the iframe
	 */
	_removeClassesFromModule() {
		this.classList.remove('axs-iframe--rules-for-padding-bottom');
		[...this.classList].forEach((className_) => {
			if (className_.includes('axs-iframe--pageformat-')) {
				this.classList.remove(className_);
			}
		});
	}

	/**
	 * @param {string} height_ - height
	 */
	_setIframeHeight(height_) {
		const iframe = this.iframeContent;

		iframe.style.height = height_ + 'px';
		iframe.style.overflow = 'hidden';

		this._removeClassesFromModule(iframe);
	}
}

if (window.customElements.get('audi-axs-iframe') === undefined) {
	window.customElements.define('audi-axs-iframe', IFrame);
}

