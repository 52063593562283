import {carousel} from 'global-bundle';
import {swipe} from 'core-utils';

var __ = {},
	exports = {__};

__.oDefaults = {
	selectorInfoModule: '.axs-md-info-module',
	selectorInfoModuleSlider: '.axs-md-info-module-slider',
	selectorSliderContent: '.axs-slider-content',
	selectorInputs: '.axs-at-slider-input'
};
// store carousel instance
__.items = [];

/**
 * swipe finish handler
 * @param {Object} data_ - Data
 * @param {Object} target_ - Target
 */
__.onSwipeFinishHandler = function(data_, target_) {
	var checkTarget = __.getCarouselByTarget(target_);

	if (data_.direction && data_.direction.horizontal !== '' && data_.move !== 0 && !!checkTarget) {
		switch (data_.direction.horizontal) {
			case swipe.LEFT:
				checkTarget.next();
				break;
			default:
				checkTarget.previous();
				break;
		}
	}
};

__.getCarouselByTarget = function(target_) {
	var i;

	for (i = 0; i < __.items.length; i++) {
		if (target_ === __.items[i].context) {
			return __.items[i].carousel;
		}
	}
};

__.init = function() {
	var itemCarousel = [].slice.call(document.querySelectorAll(__.oDefaults.selectorInfoModule)),
		carouselInstance;

	itemCarousel.forEach((element) => {
		carouselInstance = carousel.getCarouselInstance(element, {
			'pauseAnimation': true,
			'animateCarousel': true,
			'alwaysShowArrows': true
		});
		__.items.push({
			'context': carouselInstance.context,
			'carousel': carouselInstance
		});
	});
	swipe.register(__.onSwipeFinishHandler, __.oDefaults.selectorInfoModule, {
		'swipedistance': 50
	});
};

/**
 * public initialize method
 * @param {EventBus} globalEventBus - globalEventBus
 * @returns {Promise} returns Promise
 */
exports.initialize = function(globalEventBus) {
	return new Promise(resolve => {
		__.globalEventBus = globalEventBus;
		__.init();
		resolve('info-module.js');
	});
};

export {exports as infomodule};
