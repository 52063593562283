export default class CountrySelectorDropdowns {

	static get CLASS_AUDI_DROPDOWN_DISABLED() { return 'nm-form-disabled'; }

	/**
	* constructor
	* @param {Object} container_ - parent container
	*/
	constructor(container_) {
		this.CountrySelector = container_;
		this.continentSelect = this.CountrySelector.getContinentSelect();
		this.countrySelect = this.CountrySelector.getCountrySelect();
		this.continents = [];
		this.countries = [];
		this.countriesByContinent = [];
		this.currentContinent = 'none';
		this.currentCountry = 'none';
		this.changeSelectedContinent = this._handleContinentSelectionChange.bind(this);
		this.changeSelectedCountry = this._handleCountrySelectionChange.bind(this);

		this._initializeSelects();
	}

	/**
	* _initializeSelects
	*/
	_initializeSelects() {
		this._addActiveSelectItems();

		if (this.hasContinentSelect()) {
			this._removeCountriesFromCountrySelect();
		}

		this._addEventListeners();
	}

	_addActiveSelectItems() {
		let parentContinent;

		// save all countries incl. countries with reference to continent
		Array.from(this.countrySelect).forEach(option => {
			parentContinent = option.getAttribute('data-parent-continent');
			this._addActiveContinent(parentContinent);
			this._addActiveCountry(option);
		});
	}

	/**
	* _addActiveContinent - saves given continent in array for later use
	* @param {string} continent_ - continent to add
	*/
	_addActiveContinent(continent_) {
		if (continent_ !== 'none' && this.continents.indexOf(continent_) === -1) {
			this.continents.push(continent_);
		}
	}

	/**
	* _addActiveCountry - saves given country incl. details for later use and then removes option field
	* @param {Element} optionItem_ - country option Element
	*/
	_addActiveCountry(optionItem_) {
		if (optionItem_.value !== 'none') {
			const country = {
				'value': optionItem_.value,
				'name': optionItem_.innerHTML,
				'continent': optionItem_.getAttribute('data-parent-continent')
			};

			this.countries.push(optionItem_.value);
			this.countriesByContinent.push(country);
		}
	}

	/**
	* _addEventListeners - add event handlers
	*/
	_addEventListeners() {
		if (this.hasContinentSelect()) {
			this.continentSelect.addEventListener('change', this.changeSelectedContinent);
		}

		if (this.hasCountrySelect()) {
			this.countrySelect.addEventListener('change', this.changeSelectedCountry);
		}
	}

	/**
	* removeEventListeners - remove events
	*/
	removeEventListeners() {
		if (this.hasContinentSelect()) {
			this.continentSelect.removeEventListener('change', this.changeSelectedContinent);
		}

		if (this.hasCountrySelect()) {
			this.countrySelect.removeEventListener('change', this.changeSelectedCountry);
		}
	}

	/**
	* _updateCountrySelect - updates option fields for country select
	*/
	_updateCountrySelect() {
		if (this.countrySelect.hasChildNodes() && this.countrySelect.childNodes.length > 1) {
			this._removeCountriesFromCountrySelect();
		}

		this._populateCountrySelectForContinent();
	}

	/**
	* _removeCountriesFromCountrySelect - remove all but the first ("please select") option field
	*/
	_removeCountriesFromCountrySelect() {
		while (this.countrySelect.childNodes.length > 2) {
			this.countrySelect.removeChild(this.countrySelect.lastChild);
		}

		this.countrySelect.selectedIndex = 0;
	}

	/**
	* _populateCountrySelectForContinent - adds countries to country select for currently selected continent
	*/
	_populateCountrySelectForContinent() {
		for (let i = 0; i < this.countriesByContinent.length; i++) {
			if (this.countriesByContinent[i].continent === this.currentContinent) {
				const option = this._getNewOptionElement(this.countriesByContinent[i]);

				this.countrySelect.appendChild(option);
			}
		}
	}

	/**
	* _getNewOptionElement
	* @param {Object} propertyItem_ - contains attributes for new option element
	* @returns {Element} new option element filled with properties from argument
	*/
	_getNewOptionElement(propertyItem_) {
		let option = document.createElement('option');

		option.classList.add('available-country-option');
		option.value = propertyItem_.value;
		option.setAttribute('data-parent-continent', propertyItem_.continent);
		option.innerHTML = propertyItem_.name;
		return option;
	}

	/**
	* _handleContinentSelectionChange
	* @param {Event} event_ - change event
	*/
	_handleContinentSelectionChange(event_) {
		const selectItem = event_.target;

		this.currentContinent = selectItem.options[selectItem.selectedIndex].value;

		this._updateCountrySelect();
		this.CountrySelector.handleContinentSelection();
	}

	/**
	* _handleCountrySelectionChange
	* @param {Event} event_ - change event
	*/
	_handleCountrySelectionChange(event_) {
		const selectItem = event_.target;

		this.currentCountry = selectItem.options[selectItem.selectedIndex].value;

		if (this.currentCountry !== 'none') {
			this.CountrySelector.handleCountrySelection();
		}
	}

	/**
	* activates the Country Selection
	*/
	activateCountrySelect() {
		let dropdown = this.CountrySelector.getCountryDropdown();

		dropdown.classList.remove(CountrySelectorDropdowns.CLASS_AUDI_DROPDOWN_DISABLED);
		this.countrySelect.setAttribute('data-state', 'active');
	}

	/**
	* sets the select item value
	* @param {Element} selectItem_ - item for which to set the value
	* @param {string} value_ - new item value
	*/
	setSelection(selectItem_, value_) {
		selectItem_.value = value_ ? value_ : 'none';
	}

	/**
	* @returns {Array} - array of countries and continents activated in map
	*/
	getActiveContinentsAndCountries() {
		let results = [];

		results.continents = this.continents.slice();
		results.countries = this.countries.slice();
		return results;
	}

	/**
	*
	* @returns {boolean} true / false
	*/
	hasContinentSelect() {
		return (!!this.continentSelect && this.continentSelect.length > 0);
	}

	/**
	*
	* @returns {boolean} true / false
	*/
	hasCountrySelect() {
		return (!!this.countrySelect && this.countrySelect.length > 0);
	}

	/**
	* @returns {string} - ContinentID
	*/
	getSelectedContinent() {
		return this.currentContinent;
	}

	/**
	* @returns {string} - CountryID
	*/
	getSelectedCountry() {
		return this.currentCountry;
	}
}
