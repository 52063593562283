import {JScrollReveal} from 'global-bundle';

export default class IconTextCondition extends HTMLElement {

	static get CLASS_MODULE_ANIMATED() { return 'axs-icon-text-condition--with-animation'; }
	static get CLASS_ITEM() { return 'axs-icon-text-condition__item'; }

	constructor() {
		super();
	}

	connectedCallback() {
		this._initialize();
	}

	disconnectedCallback() {
		if (this.scrollReveal) {
			this.scrollReveal.destroy();
		}
	}

	_initialize() {
		if (this._hasAnimation()) {
			this.scrollReveal = new JScrollReveal({container: this, itemClass: this.constructor.CLASS_ITEM, percentage: 25});
		}
	}

	/**
	 * @returns {boolean} - should items be animated (true) or not (false)
	*/
	_hasAnimation() {
		return this.classList.contains(this.constructor.CLASS_MODULE_ANIMATED);
	}
}

if (window.customElements.get('audi-axs-icon-text-condition') === undefined) {
	window.customElements.define('audi-axs-icon-text-condition', IconTextCondition);
}
