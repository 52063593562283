const selectorContinueButton = '.axs-cookie-notification__button-continue';
const selectorHeaderPlaceholder = '.axs-header-navigation__placeholder';
const selectorTopBar = '.axs-header-navigation__top-bar';
const cookieNotificationCanceled = 'cookieNotificationCanceled';

export default class CookieNotification extends HTMLElement {

	constructor() {
		super();

		if (!this._isCookieNotificationCanceled()) {
			this.adjustHeaderPosition = this._adjustHeaderPosition.bind(this);
			this.processCookieAcceptance = this._processCookieAcceptance.bind(this);

			this.continueButton = this.querySelector(selectorContinueButton);
			this.headerPlaceholder = document.querySelector(selectorHeaderPlaceholder);
			this.topBar = document.querySelector(selectorTopBar);

			this._addEventListener();

			this._createStyleTag();

			this._showCookieNotification();

			this.adjustHeaderPosition();
		}
	}

	_showCookieNotification() {
		this.classList.remove('axs-hidden');
	}

	_addEventListener() {
		this.continueButton.addEventListener('click', this.processCookieAcceptance);

		window.addEventListener('resize', this.adjustHeaderPosition);
		// resize cookie notification on load again because of slow font loading
		window.addEventListener('load', this.adjustHeaderPosition);
	}

	_processCookieAcceptance() {
		this._writeSessionCookieCanceledFlag();
		this.classList.add('axs-hidden');

		this.adjustHeaderPosition();
	}

	_adjustHeaderPosition() {
		this.headerPlaceholder.style.paddingTop = `${this.clientHeight}px`;

		this.styleTag.innerText =`.axs-header-navigation__main-nav-toggle-input:checked ~ .axs-header-navigation__main-navigation-wrapper .axs-header-navigation__main-navigation {max-height: calc(100vh - ${this.clientHeight}px - ${this.topBar.clientHeight}px);}`;

		this._dispatchCookieNotificationSizeChangeEvent();
	}

	/**
	 * creates a style tag which will eventually be filled in _adjustHeaderPosition()
	 */
	_createStyleTag() {
		const head = document.head || document.getElementsByTagName('head')[0];

		this.styleTag = document.createElement('style');
		head.appendChild(this.styleTag);
	}


	_dispatchCookieNotificationSizeChangeEvent() {
		const event = new CustomEvent('axs-header-cookie-notification-size-change');

		document.dispatchEvent(event);
	}

	/**
	 * checks session storage for cookie notification cancel flag and returns true if found
	 * @returns {boolean} true if cancel flag set
	 */
	_isCookieNotificationCanceled() {
		return !!sessionStorage.getItem(cookieNotificationCanceled);
	}

	_writeSessionCookieCanceledFlag() {
		sessionStorage.setItem(cookieNotificationCanceled, 'true');
	}
}

customElements.define('audi-axs-cookie-notification', CookieNotification);
