import HubSearchTemplateOpeningHours from './template-opening-hours';

export default class HubSearchTemplate {

	static get BREAKPOINT() { return 1024; }
	static get SELECTOR_DETAIL_CLOSE_BUTTON() { return '.axs-module-hub-search-detail-close'; }
	static get SELECTOR_HUB_DETAIL_LINK_WRAPPER() { return '.axs-module-hub-search-detail-link-wrapper'; }
	static get SELECTOR_HUB_DETAIL_VIEW() { return '.axs-module-hub-search-detail'; }
	static get CLASS_HUB_DETAIL_WEBSITE_LINK() { return 'axs-module-hub-search-detail-link-choose'; }
	static get SELECTOR_RESULTLIST() { return '.axs-module-hub-search-result-list'; }
	static get SELECTOR_DETAIL_LIST() { return '.axs-module-hub-search-detail-list'; }
	static get SELECTOR_RESULT_COUNTER() { return '.axs-module-hub-search-js-counter'; }
	static get SELECTOR_HUB() { return '.axs-module-hub-search-hub'; }
	static get SELECTOR_DETAIL_IMAGE_WRAP() { return '.axs-module-hub-search-detail-image-wrapper'; }
	static get CLASS_DETAIL_IMAGE_WRAP_NOIMAGE() { return 'axs-module-hub-search-detail-image-wrapper-no-image'; }
	static get CLASS_HUB_DETAIL_NAME() { return 'axs-module-hub-search-detail-hub-name'; }
	static get SELECTOR_HUB_DETAIL_WRAPPER() { return '.axs-module-hub-search-detail-hub-name-wrapper'; }
	static get CLASS_HUB_HIGHLIGHT() { return 'hover'; }

	// public static functions

	static get isDesktopView() {
		let isDesktop = false;

		if (window.innerWidth >= HubSearchTemplate.BREAKPOINT) {
			isDesktop = true;
		}

		return isDesktop;
	}

	constructor(container_) {
		this.resultCounters = null;
		this.detailView = null;
		this.resultList = null;
		this.detailList = null;
		this.hubNameField = null;
		this.selectedEntryIndex = null;

		this._initializeEvents();

		this.closeButtons = [];

		if (container_) {
			this.parentContainer = container_;
			this._init();
			this._bindEvents();
		}

		this.HS_TEMPLATE_OPENING_HOURS = new HubSearchTemplateOpeningHours(this.detailView);
	}

	_initializeEvents() {
		this.closeDetailViewEvent = (CustomEventInit) => new CustomEvent('hubsearch_close_detailview', CustomEventInit);
		this.selectResultItemEvent = new CustomEvent('hubsearch_select_result_item');
		this.mouseOverResultItemEvent = new CustomEvent('hubsearch_mouse_over_result_item');
		this.mouseOffResultItemEvent = new CustomEvent('hubsearch_mouse_off_result_item');
	}
	/**
	 * _init (private)
	 */
	_init() {
		this.resultCounters = this.parentContainer.querySelectorAll(HubSearchTemplate.SELECTOR_RESULT_COUNTER);
		this.detailView = this.parentContainer.querySelector(HubSearchTemplate.SELECTOR_HUB_DETAIL_VIEW);
		this.resultList = this.parentContainer.querySelector(HubSearchTemplate.SELECTOR_RESULTLIST);
		this.detailList = this.parentContainer.querySelector(HubSearchTemplate.SELECTOR_DETAIL_LIST);
		this.hubNameField = this.parentContainer.querySelector(HubSearchTemplate.SELECTOR_HUB_DETAIL_WRAPPER);
		this.closeButtons = this.parentContainer.querySelectorAll(HubSearchTemplate.SELECTOR_DETAIL_CLOSE_BUTTON);
	}

	/**
	 * _bindEvents (private)
	 */
	_bindEvents() {
		let closeButtonsLength = this.closeButtons.length;

		for (let i = 0; i < closeButtonsLength; i++) {
			this.closeButtons[i].addEventListener('click', this._handleOnCloseDetailView);
		}
	}

	/**
	 * _highlightResultListEntryForHoveredMarker (private) - add hover style in result list for hovered marker on map
	 * @param {number} markerIndex_ - index of hovered marker in maps.markersOnMap array --> same index like depending index of list item
	 */
	_highlightResultListEntryForHoveredMarker(markerIndex_) {
		let listEntries = this.resultList.querySelectorAll(HubSearchTemplate.SELECTOR_HUB);

		if (listEntries[markerIndex_]) {
			listEntries[markerIndex_].classList.add(HubSearchTemplate.CLASS_HUB_HIGHLIGHT);
		}
	}

	/**
	 * _removeHighlightFromResultListEntry (private) - remove highlight class from entry
	 */
	_removeHighlightFromResultListEntry() {
		let highlightedListEntry = this.resultList.querySelector('.' + HubSearchTemplate.CLASS_HUB_HIGHLIGHT);

		if (highlightedListEntry) {
			highlightedListEntry.classList.remove(HubSearchTemplate.CLASS_HUB_HIGHLIGHT);
		}
	}

	/**
	 * _handleOnCloseDetailView (private) - dispatch hubsearch_close_detailview event
	 * @typedef {Object} MouseEvent
	 * @param {MouseEvent} event - Click Event
	 */
	_handleOnCloseDetailView = (event) => {

		let customEventInit = {'detail': {}};

		if (!HubSearchTemplate.isDesktopView
			&& event
			&& event.currentTarget
		) {
			let {currentTarget: button} = event;

			// By default we go to the hublist view
			const forceDestination = (button.getAttribute('data-where-to-go-when-clicked') === 'map')
				? 'map'
				: 'hub';

			customEventInit.detail = {forceDestination};
		}

		document.dispatchEvent(this.closeDetailViewEvent(customEventInit));

	};

	/**
	 * renderResultListForHubs (public) - renders hub result entries with given template data
	 * @param {Array} hubsData_ - array with template data for hub items
	 */
	renderResultListForHubs(hubsData_) {
		this._clearResultList();

		for (let i = 0; i < hubsData_.length; i++) {
			let hubData = hubsData_[i];
			let resultItem = this._getResultEntryForHub(hubData, i);

			if (resultItem) {
				this.resultList.appendChild(resultItem);
			}
		}

		this.setResultCountValue(hubsData_.length);
	}

	/**
	 * _clearResultList
	 */
	_clearResultList() {
		this.resultList.innerHTML = '';
	}

	/**
	 * renderDetailView (public)
	 * @param {Object} detailViewData_ - hub template data for that detail view
	 * @param {Object} detailViewLogic_ - contain boolean parameters to control
	 * view
	 */
	renderDetailViewForHub(detailViewData_, detailViewLogic_) {
		this._renderButtonText(detailViewLogic_);
		this._renderDetailImageForHub(detailViewData_);
		this._renderDetailListForHub(detailViewData_);
		this._renderDetailHubNameForHub(detailViewData_);
		this._renderWebsiteLinkForHub(detailViewData_);

		this.HS_TEMPLATE_OPENING_HOURS.renderOpeningHoursForHub(detailViewData_);
	}

	/**
	 * _renderButtonText
	 * @param {Object} detailViewLogic_ - data object
	 * @property {boolean} detailViewLogic_.wasDetailViewOpenFromMap - flag
	 */
	_renderButtonText(detailViewLogic_) {
		const button = this.detailView.querySelector(`${HubSearchTemplate.SELECTOR_DETAIL_CLOSE_BUTTON}`);

		const buttonText = button.querySelector('span');

		const whereToGoWhenBackButtonIsClicked = (!!detailViewLogic_.wasDetailViewOpenFromMap)
			? 'map'
			: 'hub';

		const innerText = (whereToGoWhenBackButtonIsClicked === 'map')
			? window.i18n['axs.ui.hubsearch.browsemap'] || 'Browse map'
			: window.i18n['axs.ui.hubsearch.browsehubs'] || 'Browse hubs';

		button.setAttribute('data-where-to-go-when-clicked', whereToGoWhenBackButtonIsClicked);
		buttonText.innerHTML = innerText;
	}

	/**
	 * _renderDetailImageForHub
	 * @param {Object} detailViewData_ - data object
	 */
	_renderDetailImageForHub(detailViewData_) {
		let imageWrapper = this.detailView.querySelector(HubSearchTemplate.SELECTOR_DETAIL_IMAGE_WRAP);
		let {imageUrl} = detailViewData_;

		if (imageUrl !== '' && HubSearchTemplate.isDesktopView) {
			imageWrapper.style.backgroundImage = 'url(' + imageUrl + ')';
			imageWrapper.classList.remove(HubSearchTemplate.CLASS_DETAIL_IMAGE_WRAP_NOIMAGE);
		}
		else {
			imageWrapper.style.backgroundImage = 'none';
			imageWrapper.classList.add(HubSearchTemplate.CLASS_DETAIL_IMAGE_WRAP_NOIMAGE);
		}
	}

	/**
	 * _renderDetailListForHub
	 * @param {Object} detailViewData_ - data object
	 */
	/* eslint-disable max-statements */
	_renderDetailListForHub(detailViewData_) {
		let {city, street, zipCode, phoneInternational, phoneURL, email, type: hubType} = detailViewData_;
		let googleMapLink;
		let detailListHTML = '';

		if (street !== '' || city !== '' || zipCode !== '') {
			let privateClass = (hubType === 'private') ? 'axs-module-hub-search-hub-is-private' : '';

			googleMapLink = this._getGoogleMapLinkUrlByAddress(street, zipCode, city);

			if (googleMapLink !== '') {
				detailListHTML += `
				<li class="axs-module-hub-search-detail-item axs-module-hub-search-detail-item-has-link ${privateClass}">
					<a class="axs-module-hub-search-detail-item-link" href="${googleMapLink}" target="_blank">
						<svg class="axs-module-hub-search-detail-icon">
							<use xlink:href="${SETUPS.get('DocrootAssets')}/icons/svg/axs-poi.svg#axs-poi"></use>
						</svg>
						<span class="axs-visually-hidden">${window.i18n['axs.ui.hubsearch.address'] || 'address'}:</span>
						<span class="axs-module-hub-search-detail-address">${street}, ${zipCode} ${city}</span>
					</a>
				</li>`;
			}
			else {
				detailListHTML += `
				<li class="axs-module-hub-search-detail-item ${privateClass}">
					<svg class="axs-module-hub-search-detail-icon">
						<use xlink:href="${SETUPS.get('DocrootAssets')}/icons/svg/axs-poi.svg#axs-poi"></use>
					</svg>
					<span class="axs-visually-hidden">${window.i18n['axs.ui.hubsearch.address'] || 'address'}:</span>
					<span class="axs-module-hub-search-detail-address">${street}, ${zipCode} ${city}</span>
				</li>`;
			}
		}

		if (phoneInternational !== '') {
			if (phoneURL !== '') {
				detailListHTML += `
				<li class="axs-module-hub-search-detail-item axs-module-hub-search-detail-item-has-link">
					<a class="axs-module-hub-search-detail-item-link axs-module-hub-search-detail-item-link-phone" href="tel:${phoneURL}">
						<svg class="axs-module-hub-search-detail-icon axs-module-hub-search-detail-icon-phone">
							<use xlink:href="${SETUPS.get('DocrootAssetsCoreCI')}icons/svg/voice-call-1-small.svg#audi-voice-call-1-small"></use>
						</svg>
						<span class="axs-visually-hidden">${window.i18n['axs.ui.hubsearch.phone'] || 'phone'}:</span>
						<span class="axs-module-hub-search-detail-phone">${phoneInternational}</span>
					</a>
				</li>`;
			}
			else {
				detailListHTML += `
				<li class="axs-module-hub-search-detail-item">
					<svg class="axs-module-hub-search-detail-icon axs-module-hub-search-detail-icon-phone">
						<use xlink:href="${SETUPS.get('DocrootAssetsCoreCI')}icons/svg/voice-call-1-small.svg#audi-voice-call-1-small"></use>
					</svg>
					<span class="axs-visually-hidden">${window.i18n['axs.ui.hubsearch.phone'] || 'phone'}:</span>
					<span class="axs-module-hub-search-detail-phone">${phoneInternational}</span>
				</li>`;
			}
		}

		if (email !== '') {
			detailListHTML += `
				<li class="axs-module-hub-search-detail-item axs-module-hub-search-detail-item-has-link">
					<a class="axs-module-hub-search-detail-item-link" href="mailto:${email}">
						<svg class="axs-module-hub-search-detail-icon nm-icon-contact">
							<use xlink:href="${SETUPS.get('DocrootAssetsCoreCI')}icons/svg/audi-icon-email.svg#audi-icon-email"></use>
						</svg>
						<span class="axs-visually-hidden">${window.i18n['axs.ui.hubsearch.email'] || 'email'}:</span>
						<span class="axs-module-hub-search-detail-email">${email}</span>
					</a>
				</li>`;
		}

		this.detailList.innerHTML = detailListHTML;
	}
	/* eslint-enable max-statements */

	/**
	* _getGoogleMapLinkUrlByAddress
	* @param {string} street_ - streetname
	* @param {string} city_ - cityname
	* @param {string} zipCode_ - zip code / postal code
	* @returns {string} googleMapLinkUrl - url that shows given address on google maps
	*/
	_getGoogleMapLinkUrlByAddress(street_, city_, zipCode_) {
		let addressString = `${street_},${zipCode_} ${city_}`;
		let googleBaseUrl = 'http://maps.google.com/maps?q=';
		let googleMapLinkUrl;

		try {
			googleMapLinkUrl = googleBaseUrl + encodeURIComponent(addressString);
		}
		catch (error) {
			googleMapLinkUrl = '';
		}

		return googleMapLinkUrl;
	}

	/**
	 * _renderDetailHubNameForHub
	 * @param {Object} detailViewData_ - data object
	 */
	_renderDetailHubNameForHub(detailViewData_) {
		let headline;
		let hubName = this.parentContainer.querySelector('.' + HubSearchTemplate.CLASS_HUB_DETAIL_NAME);

		if (hubName) {
			hubName.innerHTML = detailViewData_.name;
		}
		else {
			headline = document.createElement('h2');
			headline.classList.add('audi-copy-m');
			headline.classList.add(HubSearchTemplate.CLASS_HUB_DETAIL_NAME);
			headline.innerHTML = detailViewData_.name;
			this.hubNameField.appendChild(headline);
		}
	}

	/**
	 * _renderWebsiteLinkForHub
	 * @param {Object} detailViewData_ - data object
	 */
	_renderWebsiteLinkForHub(detailViewData_) {
		let {url} = detailViewData_;
		let websiteLinks = this.detailView.querySelectorAll('.' + HubSearchTemplate.CLASS_HUB_DETAIL_WEBSITE_LINK);
		let linkWrapper = this.detailView.querySelector(HubSearchTemplate.SELECTOR_HUB_DETAIL_LINK_WRAPPER);
		let link;

		if (websiteLinks.length === 0) {
			link = this._createWebsiteLinkForHub();
			linkWrapper.appendChild(link);
		}
		else {
			link = websiteLinks[0];
		}

		if (url !== '') {
			link.setAttribute('href', url);
		}
		else {
			linkWrapper.removeChild(link);
		}
	}

	/**
	 * @returns {HTMLElement} - link
	 */
	_createWebsiteLinkForHub() {
		let link = document.createElement('a');

		link.setAttribute('target', 'blank');
		link.setAttribute('href', '');
		link.innerHTML = window.i18n['axs.ui.hubsearch.ctalink.selecthub'] || 'Choose Hub';
		link.classList.add('axs-button-standard');
		link.classList.add(HubSearchTemplate.CLASS_HUB_DETAIL_WEBSITE_LINK);

		return link;
	}

	/**
	 * _getResultEntryForHub - returns html for one list entry in hub-search result-list
	 * @param {Object} hubData_ - hub for that entry is generated
	 * @param {number} indexInList_ - index/child number in result list
	 * @returns {Object} listElement - result entry
	 */
	_getResultEntryForHub(hubData_, indexInList_) {
		let listItemContent = '';
		let listElement;

		if (hubData_.type !== '') {
			listElement = document.createElement('li');
			listElement.classList.add('axs-module-hub-search-result-item');
			listItemContent = `
					<section class="axs-module-hub-search-hub axs-module-hub-search-hub-is-${hubData_.type}">
						<svg class="axs-module-hub-search-result-icon">
							<use xlink:href="${SETUPS.get('DocrootAssets')}/icons/svg/axs-poi.svg#axs-poi"></use>
						</svg>
						<h3 class="axs-module-hub-search-hub-name audi-copy-m">${hubData_.name}</h3>
						<span class="axs-module-hub-search-hub-address audi-copy-s">
							${hubData_.street}, ${hubData_.zipCode} ${hubData_.city}
						</span>
					</section>`;
			listElement.innerHTML = listItemContent;
			this._bindEventsForListResultItem(listElement, indexInList_);
		}

		return listElement;
	}

	/**
	 * setResultCountValue
	 * @param {number} value_ - number to show
	 */
	setResultCountValue(value_) {
		let resultCounterLength = this.resultCounters.length;

		for (let index = 0; index < resultCounterLength; index++) {
			this.resultCounters[index].innerHTML = value_;
		}
	}

	/**
	 * @returns {number} - index of selected entry
	 */
	getSelectedEntryIndex() {
		return this.selectedEntryIndex;
	}

	/**
	 * @param {HTMLElement} listElement_  - result list item
	 * @param {number} indexInList_  - child index of li
	 */
	_bindEventsForListResultItem(listElement_, indexInList_) {
		listElement_.addEventListener('click', () => {
			this.selectedEntryIndex = indexInList_;
			document.dispatchEvent(this.selectResultItemEvent);
		});

		listElement_.addEventListener('mouseover', () => {
			this.selectedEntryIndex = indexInList_;
			document.dispatchEvent(this.mouseOverResultItemEvent);
		});

		listElement_.addEventListener('mouseout', () => {
			this.selectedEntryIndex = indexInList_;
			document.dispatchEvent(this.mouseOffResultItemEvent);
		});
	}
}
