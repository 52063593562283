import {dom} from 'core-utils';

var __ = {}, exports = {__};

/**
 * handles the click on a share-this-page-icon
 * @param {Event} event - clickevent
 */
__.handler = function(event) {
	var socialshareId, sharedInfo, link;

	event.preventDefault();

	link = dom.getElement('a', this);
	socialshareId = dom.getDataAttribute(link, 'socialshare-id');

	if (!!socialshareId) {
		sharedInfo = __.getShareInfo(link);
		__['clickShare_' + socialshareId](sharedInfo);
	}
};

/**
 * Gather all the information provided in the share buttons (via data attributes)
 * @param {HTMLElement} $el - share-this-page-link
 * @returns {Object} - information of the share link
 */
__.getShareInfo = function($el) {
	return {
		'Id': $el.getAttribute('data-socialshare-id'),
		'Image': $el.getAttribute('data-socialshare-image'),
		'Label': $el.getAttribute('data-socialshare-label'),
		'Summary': $el.getAttribute('data-socialshare-summary')
	};
};

/**
 * replaces placeholder with link information
 * @param {string} shareUrlString_ - placeholder url
 * @param {Object} shareObject - gathered Information of the link
 * @returns {string} - returns the url to be opened
 */
__.getShareString = function(shareUrlString_, shareObject) {
	var currentURLString = window.location.href;
	var shareUrlString = shareUrlString_.replace('[URL]', encodeURIComponent(currentURLString));

	if (shareObject.Image) {
		shareUrlString = shareUrlString.replace('[IMAGE]', encodeURIComponent(shareObject.Image));
	}

	if (shareObject.Label) {
		shareUrlString = shareUrlString.replace('[TITLE]', encodeURIComponent(shareObject.Label));
	}

	if (shareObject.Summary) {
		shareUrlString = shareUrlString.replace('[SUMMARY]', encodeURIComponent(shareObject.Summary));
	}

	return shareUrlString;
};

/**
 * opens a window using a specified link
 * @param {string} url - the url to be opened
 */
__.openLinkInWindow = function(url) {
	window.open(url, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=no,height=500,width=980');
};

/**
 * click handler facebook
 * @param {Object} shareObject - information of the share link
 */
__.clickShare_facebook = function(shareObject) {
	var shareUrlString = __.getShareString('http://www.facebook.com/share.php?s=100&p[title]=[TITLE]&p[summary]=[SUMMARY]&p[images][0]=[IMAGE]&p[url]=[URL]', shareObject);

	__.openLinkInWindow(shareUrlString);
};

/**
 * click handler twitter
 * @param {Object} shareObject - information of the share link
 */
__.clickShare_twitter = function(shareObject) {
	var shareUrlString = __.getShareString('http://twitter.com/share?text=[TITLE]&url=[URL]', shareObject);

	if (shareUrlString.indexOf('#') !== -1) {
		shareUrlString = shareUrlString.split('#').join('%23');
	}

	__.openLinkInWindow(shareUrlString);
};

/**
 * click handler googleplus
 * @param {Object} shareObject - information of the share link
 */
__.clickShare_googleplus = function(shareObject) {
	var shareUrlString = __.getShareString('http://plus.google.com/share?url=[URL]', shareObject);

	__.openLinkInWindow(shareUrlString);
};

/**
 * click handler xing
 * @param {Object} shareObject - information of the share link
 */
__.clickShare_xing = function(shareObject) {
	var shareUrlString = __.getShareString('https://www.xing.com/social_plugins/share?url=[URL]', shareObject);

	__.openLinkInWindow(shareUrlString);
};

/**
 * click handler pinterest
 */
__.clickShare_pinterest = function() {
	var e = document.createElement('script');

	e.setAttribute('type', 'text/javascript');
	e.setAttribute('charset', 'UTF-8');
	e.setAttribute('src', '//assets.pinterest.com/js/pinmarklet.js?r=' + Math.random() * 99999999);
	document.body.appendChild(e);
};

/**
 * click handler delicious
 * @param {Object} shareObject - information of the share link
 */
__.clickShare_delicious = function (shareObject) {
	var shareUrlString = __.getShareString('http://del.icio.us/save?url=[URL]', shareObject);

	__.openLinkInWindow(shareUrlString);
};

__.addEvents = function() {
	dom.getEventDelegate('body').on('click', '.socialShareItem', __.handler);
};

/**
 * Constructor
 */
__.initialize = function() {
	__.addEvents();
};

/**
 * public initialize method
 * @param {EventBus} globalEventBus - globalEventBus
 * @returns {Promise} returns Promise
 */
exports.initialize = function(globalEventBus) {
	return new Promise(resolve => {
		__.globalEventBus = globalEventBus;
		__.initialize();
		resolve('socialshare.js');
	});
};

export {exports as socialshare};
