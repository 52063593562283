const template = `
<div class="dsh-resultlist-item" data-dsh-kvpsid="{{=it.kvpsid}}" data-dsh-services="{{=it.servicesAsString}}">
    <h4 class="axs-el-hdl axs-el-hdl-04 axs-at-hdl-b">{{=it.name}}</h4>
    <div class="dsh-result-meta clearfix">
       {{var dealer = window.i18n["dsh.icon.dealer"] || "Dealer";}}
        {{var service = window.i18n["dsh.icon.service"] || "Service";}}
        {{?!!it.dealer}}<span class="dsh-result-type dsh-icon-dealer">{{=dealer}}</span>{{?}} {{?!!it.service}}<span class="dsh-result-type dsh-icon-service">{{=service}}</span>{{?}}

        {{?!!it.distanceAsString}}
        <span class="dsh-result-direction">{{=it.distanceAsString}}</span>
        {{?}}
    </div>

	{{?!!it.address.display}}
    <p class="axs-el-lbl axs-el-lbl-01">{{=it.address.display[0]}}<br/>
    {{=it.address.display[1]}}</p>
	{{?}}

	{{?!!it.contactDetails.phone.display}}
    {{var phone = window.i18n["dsh.label.phone"] || "Telefon";}}
    <p class="dsh-result-phonenumber axs-el-lbl axs-el-lbl-01">{{=phone}}: {{=it.contactDetails.phone.display}}<p>
	{{?}}

    {{?it.dshConfig.DealerSearchEnableAskMode === 'on'}}

    {{var kvpsid = window.i18n["dsh.details.label.dealer.ask.kvpsid"] || "Betriebsnummer";}}
    {{var regionNumber = window.i18n["dsh.details.label.dealer.ask.regionNumber"] || "Regionsnummer";}}
    {{var distributionCenter = window.i18n["dsh.details.label.dealer.ask.distributionCenter"] || "VZ-Nummer";}}
    {{var contractStatusGvo = window.i18n["dsh.details.label.dealer.ask.contractStatusGvo"] || "Vertragsstatus";}}

        <p class="axs-el-lbl axs-el-lbl-01 dsh-ask-mode-values">
        {{?!!it.kvpsid}}
            {{=kvpsid}}: {{=it.kvpsid}}<br/>
        {{?}}
        {{?!!it.address.regionNumber}}
            {{=regionNumber}}: {{=it.address.regionNumber}}<br/>
        {{?}}
        {{?!!it.address.distributionCenter}}
            {{=distributionCenter}}: {{=it.address.distributionCenter}}
        {{?}}
        {{?!!it.contractStatusGvo}}
            {{=contractStatusGvo}}: {{=it.contractStatusGvo}}
        {{?}}
        </p>
    {{?}}

    <!-- TODO: check for existing links -->
    <p class="dsh-dealer-links">

		{{?it.dshConfig.DealerSearchShowRouteplannerLink === 'on'}}
			{{var calcRoute = window.i18n["dsh.details.label.dealer.calculate.route"] || "Route berechnen";}}
			<span><a href="{{=it.directionsLink}}" class="axs-el-lk axs-el-lk-01 axs-at-lk-b axs-el-lk-icn axs-at-lk-highlight axs-icon-routing" target="_blank"><span class="dsh-tooltip">{{=calcRoute}}</span></a></span>
		{{?}}

        {{?!!it.url}}
		{{var website = window.i18n["dsh.details.label.dealer.website"] || "Website";}}
        <span><a href="{{=it.url}}" class="axs-el-lk axs-el-lk-01 axs-at-lk-b axs-el-lk-icn axs-at-lk-highlight axs-icon-url" target="_blank"><span class="dsh-tooltip">{{=website}}</span></a></span>
        {{?}}

        {{?!!it.contactDetails.email}}
        {{var enquiry = window.i18n["dsh.icon.dealer.enquiry"] || "H&auml;ndleranfrage";}}
		{{var subject = window.i18n["dsh.details.label.dealer.email.subject"] || "";}}
        <span><a href="mailto:{{=it.contactDetails.email}}?subject={{=subject.replace(/ /g, '%20')}}" class="axs-el-lk axs-el-lk-01 axs-at-lk-b axs-el-lk-icn axs-at-lk-highlight axs-icon-enquiry"><span class="dsh-tooltip">{{=enquiry}}</span></a></span>
        {{?}}

        <!-- TODO: check if link to test drive exists -->
        {{?it.dshConfig.DealerSearchShowTestDriveLink !== 'off'}}
            {{var testdrive = window.i18n["dsh.icon.dealer.testdrive"] || "Probefahrt anfragen";}}
            <span><a href="#" class="axs-el-lk axs-el-lk-01 axs-at-lk-b axs-el-lk-icn axs-at-lk-highlight axs-icon-testdrive" title="{{=testdrive}}"><span class="dsh-tooltip">{{=testdrive}}</span></a></span>
        {{?}}
    </p>
    <hr/>
</div>
`;

export {template as dshResultlistItemTemplate};
