import {dom, swipe} from 'core-utils';
import {slider} from 'global-bundle';

var __ = {},
	exports = {__};

__.oDefaults = {
	'selectorClassUsp': '.axs-usp-teaser',
	'selectorClassNavigation': '.axs-at-slider-input',
	'selectorClassNavigationLabels': '.axs-at-slider-input-label',
	'selectorClassNavigationArrowButtons': '.axs-usp-teaser__arrow-navigation-button'
};

/**
 * swipe finish handler
 * @param {Object} data_ - swipefinishhandler
 */
__.onSwipeFinishHandler = function(data_) {
	if (data_.direction && data_.move !== 0) {
		switch (data_.direction.horizontal) {
			case swipe.LEFT:
				__.arrowNextHandler();
				break;
			case swipe.RIGHT:
				__.arrowPreviousHandler();
				break;
			default:
				// do nothing
				break;
		}
	}
};

/**
 * set active slide
 * @param {number} activeSlide_ - active slide
 */
__.setActiveSlide = function(activeSlide_) {
	var navigation = dom.getElementsArray(__.oDefaults.selectorClassUsp + ' ' + __.oDefaults.selectorClassNavigation),
		i;

	for (i = 0; i < navigation.length; i++) {
		navigation[i].checked = false;

		if (i === activeSlide_) {
			navigation[i].checked = true;
			navigation[i].click();
			__.onNavigationChange();
		}
	}
};

/**
 * arrowNextHandler
 * @param {Event} event_ - Event
 */
__.arrowNextHandler = function(event_) {
	var navigation = dom.getElementsArray(__.oDefaults.selectorClassUsp + ' ' + __.oDefaults.selectorClassNavigation),
		i, nextSlide = 0;

	if (event_) {
		event_.preventDefault();
	}

	for (i = 0; i < navigation.length; i++) {
		if (navigation[i].checked) {
			nextSlide = i + 1;
		}
	}

	if (nextSlide < navigation.length) {
		__.setActiveSlide(nextSlide);
	}
};

/**
 * arrowPreviousHandler
 * @param {Event} event_ - Event
 */
__.arrowPreviousHandler = function(event_) {
	var navigation = dom.getElementsArray(__.oDefaults.selectorClassUsp + ' ' + __.oDefaults.selectorClassNavigation),
		i, prevSlide = 0;

	if (event_) {
		event_.preventDefault();
	}

	for (i = 0; i < navigation.length; i++) {
		if (navigation[i].checked) {
			prevSlide = i - 1;
		}
	}

	if (prevSlide >= 0) {
		__.setActiveSlide(prevSlide);
	}
};

__.onNavigationChange = function() {
	var navigation = dom.getElementsArray(__.oDefaults.selectorClassUsp + ' ' + __.oDefaults.selectorClassNavigation),
		labels = dom.getElementsArray(__.oDefaults.selectorClassUsp + ' ' + __.oDefaults.selectorClassNavigationLabels),
		i, activeSlide;

	for (i = 0; i < navigation.length; i++) {
		labels[i].classList.remove('selected');

		if (navigation[i].checked) {
			labels[i].classList.add('selected');
			activeSlide = i;
		}
	}

	dom.getElementsArray(__.oDefaults.selectorClassNavigationArrowButtons).forEach((item) => {
		item.classList.remove('axs-usp-teaser__arrow-navigation-button--active');
	});

	if (activeSlide > 0) {
		dom.getElement(__.oDefaults.selectorClassNavigationArrowButtons + '--prev').classList.add('axs-usp-teaser__arrow-navigation-button--active');
	}

	if (activeSlide < navigation.length - 1) {
		dom.getElement(__.oDefaults.selectorClassNavigationArrowButtons + '--next').classList.add('axs-usp-teaser__arrow-navigation-button--active');
	}
};

/**
 * onNavigationArrowClick
 * @param {Event} event_ - Event
 */
__.onNavigationArrowClick = function(event_) {
	var {target} = event_;

	if (target.classList.contains('axs-usp-teaser__arrow-navigation-button--prev')) {
		__.arrowPreviousHandler(event_);
	}
	else {
		__.arrowNextHandler(event_);
	}
};

__.init = function() {
	var sliderElements,
		autoAnimation_,
		delegate = dom.getEventDelegate('body');

	var elemId;

	sliderElements = dom.getElementsArray(__.oDefaults.selectorClassUsp);
	autoAnimation_ = true;
	sliderElements.forEach((element) => {

		if (element.getAttribute('data-auto-animation') && (element.getAttribute('data-auto-animation') === 'false' || element.getAttribute('data-auto-animation') === 'false')) {
			autoAnimation_ = false;
		}

		elemId = element.getAttribute('id');

		slider.register('#' + elemId, {
			'autoAnimation': autoAnimation_
		});
		swipe.register(__.onSwipeFinishHandler, '#' + elemId, {
			'swipedistance': 50
		});
		delegate.on('change', '#' + elemId + ' ' + __.oDefaults.selectorClassNavigation, __.onNavigationChange);
		delegate.on('click', '#' + elemId + ' ' + __.oDefaults.selectorClassNavigationArrowButtons, __.onNavigationArrowClick);
		delegate.on('input:changed', '#' + elemId + ' ' + __.oDefaults.selectorClassNavigation, __.onNavigationChange);
	});

	// set slider content-list  height and call update on window resize
	__.setContentHeight();
	window.addEventListener('resize', __.setContentHeight);

};

/**
 * private function to set the content-list based on the highest content-slide
 * to prevent jumping when active slide changes
 */ // eslint-disable jsdoc/require-returns
__.setContentHeight = function() {
	var sliderElements = dom.getElementsArray('.axs-usp-teaser__content-list'),
		sliderOffset = 26, // slider ul padding + border
		slides, maxSlideHeight, moduleParent, moduleOffset;

	sliderElements.forEach((slider_) => {
		slides = dom.getElementsArray('.axs-usp-teaser__slide', slider_);
		maxSlideHeight = Math.max(...slides.map(slide => __.getSlideHeight(slide)));
		moduleParent = dom.closest(slider_, '.axs-module');
		moduleOffset = 0;

		// if next axs-module has background element (with negative margin) - set an offset to reduce slider height
		// offset 42px = CTA button margin-bottom
		if (!!moduleParent.nextElementSibling && window.innerWidth < 1280) {
			moduleOffset = (parseInt(getComputedStyle(moduleParent.nextElementSibling).marginTop, 10) < 0) ? 0 : 42;

			if (!!moduleParent.nextElementSibling.firstElementChild && moduleParent.nextElementSibling.firstElementChild.classList.contains('axs-module-background')) {
				moduleOffset = 0;
			}
		}

		slider_.style.height = maxSlideHeight + sliderOffset - moduleOffset + 'px';
	});
};

/**
 * private function to get the height of a content slide
 * @param {HTMLElement} slide - the slide to measure
 * @returns {number} - the slide height
 */
__.getSlideHeight = function(slide) {
	var height, oldHeight = slide.style.height;

	slide.style.height = 'auto';
	height = slide.clientHeight;
	slide.style.height = oldHeight;
	return height;
};

/**
 * public initialize method
 * @param {EventBus} globalEventBus - globalEventBus
 * @returns {Promise} returns Promise
 */
exports.initialize = function(globalEventBus) {
	return new Promise(resolve => {
		__.globalEventBus = globalEventBus;
		__.init();
		resolve('usp.js');
	});
};

export {exports as usp};
