import {appEvents} from 'application-bundle';
import {dom, swipe} from 'core-utils';
import {slider} from 'global-bundle';

var __ = {},
	exports = {__};

__.oDefaults = {
	selectorAppModule: '.axs-md-app-module',
	selectorInputs: '.axs-at-slider-input'
};

/**
 * onSwipeFinishHandler
 * @param {tbd} data_ - tbd
 * @param {tbd} target_ - tbd
 */
__.onSwipeFinishHandler = function(data_, target_) {
	if (data_.direction && data_.move !== 0) {
		switch (data_.direction.horizontal) {
			case swipe.LEFT:
				__.showNextSlide(target_);
				break;
			default:
				__.showPrevSlide(target_);
				break;
		}
	}
};

/**
 * showNextSlide
 * @param {tbd} target_ - tbd
 */
__.showNextSlide = function(target_) {
	var sliderElements = dom.getElementsArray(__.oDefaults.selectorInputs, target_),
		nextSlide = __.getActiveSlideNr(sliderElements) + 1;

	if (sliderElements[nextSlide]) {
		sliderElements[nextSlide].checked = true;
		__.triggerOnChangeEvent(sliderElements[nextSlide]);
	}
};

/**
 * showPrevSlide
 * @param {tbd} target_ - tbd
 */
__.showPrevSlide = function(target_) {
	var sliderElements = dom.getElementsArray(__.oDefaults.selectorInputs, target_),
		prevSlide = __.getActiveSlideNr(sliderElements) - 1;

	if (sliderElements[prevSlide]) {
		sliderElements[prevSlide].checked = true;
		__.triggerOnChangeEvent(sliderElements[prevSlide]);
	}
};

/**
 * getActiveSlideNr
 * @param {tbd} slider_ - tbd
 * @returns {number} returns array index of selected slide in slider_
 */
__.getActiveSlideNr = function(slider_) {
	var i;

	for (i = 0; i < slider_.length; i++) {
		if (slider_[i].checked) {
			return i;
		}
	}
};

/**
 * triggerOnChangeEvent
 * @param {tbd} element_ - tbd
 */
__.triggerOnChangeEvent = function(element_) {
	var ev = document.createEvent('Event');

	ev.initEvent('change', true, false);
	element_.dispatchEvent(ev);
};

/**
 * init module - initalizes the slider on app-init and layer-open
 */
__.init = function() {
	__.globalEventBus.on(appEvents.LAYER_LOADED, __.initSlider);
	__.initSlider();
};

/**
 * initSlider - register the slider module
 * @param {HTMLElement} payload_ - the context the slider is probably initialized
 */
__.initSlider = function(payload_) {
	var context, sliderElements;

	context = (!!payload_ && payload_.element) ? payload_.element : document;
	sliderElements = dom.getElementsArray(__.oDefaults.selectorAppModule, context);
	sliderElements.forEach((element) => {
		if (element) {
			slider.register('#' + element.getAttribute('id'), {
				autoAnimation: true,
				animationTime: 6000,
				alwaysShowArrows: true
			});
			swipe.register(__.onSwipeFinishHandler, '#' + element.getAttribute('id'), {
				swipedistance: 50
			});
		}
	});
};

/**
 * public initialize method
 * @param {EventBus} globalEventBus - tbd
 * @returns {Promise} returns Promise
 */
exports.initialize = function(globalEventBus) {

	return new Promise(resolve => {
		__.globalEventBus = globalEventBus;
		__.init();

		resolve('app-module.js');
	});
};

export {exports as appmodule};
