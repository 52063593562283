import {scroll} from 'global-bundle';

export default class OndemandConfigurator extends HTMLElement {
	static get ATTRIBUTE_CARMODEL_ID() {
		return 'data-model-id';
	}

	static get ATTRIBUTE_CARMODEL_URL() {
		return 'data-model-url';
	}

	static get EVENT_MODEL_RESULTS_CHANGED() {
		return 'ondemand-configurator-modelresults-change';
	}

	static get EVENT_MODEL_SELECTED() {
		return 'ondemand-configurator-model-selected';
	}

	static get EVENT_DETAILVIEW_EMBEDDED() {
		return 'ondemand-configurator-detail-view-embedded';
	}

	static get EVENT_MODELSELECTION_CANCELED() {
		return 'ondemand-configurator-modelselection-canceled';
	}

	static get EVENT_MODELFILTER_CHANGED() {
		return 'ondemand-configurator-modelfilter-changed';
	}

	/**
	 * constructor
	 */
	constructor() {
		super();
		this.detailViewContainer = undefined;
		this.modelfinder = undefined;
		this.handleModelSelection = this._handleModelSelection.bind(this);
		this.handleCloseButtonClick = this._handleCloseButtonClick.bind(this);
		this.handleSelectionCanceled = this._handleSelectionCanceled.bind(this);
	}

	/**
	 * connectedCallback - callback for adding element to dom/shadow dom
	 */
	connectedCallback() {
		this._initialize();
		this._bind();
	}

	_initialize() {
		this.detailViewContainer = this.querySelector('.axs-ondemand-configurator__embedded-detailview');
		this.modelfinder = this.querySelector('.axs-ondemand-configurator__modelfinder');
		this.isDetailViewStandalone = this._isDetailViewStandalone();
	}

	_bind() {
		if (!this.isDetailViewStandalone) {
			let closeButton = this.querySelector('.axs-ondemand-configurator__embedded-detailview-close');

			this.addEventListener(OndemandConfigurator.EVENT_DETAILVIEW_EMBEDDED, this._scrollToDetailView);
			this.addEventListener(OndemandConfigurator.EVENT_MODEL_SELECTED, this.handleModelSelection);
			this.addEventListener(OndemandConfigurator.EVENT_MODELSELECTION_CANCELED, this.handleSelectionCanceled);

			if (closeButton) {
				closeButton.addEventListener('click', this.handleCloseButtonClick);
			}
		}
	}

	/**
	 * @returns {boolean} true => standalone, false => embedded
	 */
	_isDetailViewStandalone() {
		let isStandalone = true;

		if (this.modelfinder && this.detailViewContainer) {
			isStandalone = false;
		}

		return isStandalone;
	}

	/**
	 * _handleModelSelection - callback function if model was selected
	 * @param {Event} modelSelectEvent_ - custom event
	 */
	_handleModelSelection(modelSelectEvent_) {
		let modelUrl = this._getModelUrlFromCaughtEvent(modelSelectEvent_);

		this._requestDetailViewHtml(modelUrl);
	}

	_handleSelectionCanceled() {
		this._clearDetailView();
		this._hideCloseButton();
	}

	_clearDetailView() {
		let contentWrapper = this.detailViewContainer.querySelector('.axs-ondemand-configurator__embedded-detailview-content');

		contentWrapper.innerHTML = '';
	}

	_handleCloseButtonClick() {
		const modelSelectionCanceledEvent = new CustomEvent(OndemandConfigurator.EVENT_MODELSELECTION_CANCELED);

		scroll.scrollToElement(this, {
			offset: 24, // top padding
			duration: 660, // time-xxl
			easing: 'easeInOutSine'
		});

		setTimeout(() => {
			this._hideCloseButton();
			this._clearDetailView();
			this.dispatchEvent(modelSelectionCanceledEvent);
		}, 660);
	}

	_hideCloseButton() {
		let closeButtonWrapper = this.detailViewContainer.querySelector('.axs-ondemand-configurator__embedded-detailview-button-wrapper');

		if (closeButtonWrapper) {
			closeButtonWrapper.classList.add('axs-ondemand-configurator__embedded-detailview-button-wrapper--hidden');
		}
	}

	_showCloseButton() {
		let closeButtonWrapper = this.detailViewContainer.querySelector('.axs-ondemand-configurator__embedded-detailview-button-wrapper');

		if (closeButtonWrapper) {
			closeButtonWrapper.classList.remove('axs-ondemand-configurator__embedded-detailview-button-wrapper--hidden');
		}
	}

	/**
	 * @param {Event} modelSelectEvent_ - custom event
	 * @param {string} modelSelectEvent_.details.url - url of detail view of selected model
	 * @returns {string} - new model url
	 */
	_getModelUrlFromCaughtEvent(modelSelectEvent_) {
		return modelSelectEvent_.detail.url;
	}

	/**
	 * _requestDetailViewHtml
	 * @param {string} url_ - url of detail view of selected model
	 */
	_requestDetailViewHtml(url_) {
		fetch(url_, {
			method: 'get',
			credentials: 'include'
		}).then((response_) => {
			return response_.text();
		}).then((responseText_) => {
			let relevantContentFromResponse = this._getRelevantContentFromResponse(responseText_);

			this._embedDetailViewHtml(relevantContentFromResponse);
			this._showCloseButton();
			this._fireDetailViewEmbeddedEvent();
		});
	}

	/**
	 * _getRelevantContentFromResponse
	 * @param {string} rawHTML - raw HTML string to extract relevant content from
	 * @returns {string} - only relevant HTML
	 */
	_getRelevantContentFromResponse(rawHTML) {
		let shadowElement = document.createElement('div');
		let ondemandConfiguratorDetailsElement;

		shadowElement.innerHTML = rawHTML;
		ondemandConfiguratorDetailsElement = shadowElement.querySelector('audi-ondemand-configurator-details');

		return '<audi-ondemand-configurator-details class="axs-ondemand-configurator__details">' + ondemandConfiguratorDetailsElement.innerHTML + '</audi-ondemand-configurator-details>';
	}

	/**
	 * _embedDetailViewHtml
	 * @param {string} detailViewHTML_ - html of detail view
	 */
	_embedDetailViewHtml(detailViewHTML_) {
		let contentWrapper = this.detailViewContainer.querySelector('.axs-ondemand-configurator__embedded-detailview-content');

		contentWrapper.innerHTML = detailViewHTML_;
	}

	_scrollToDetailView() {
		let container = this.querySelector('.axs-ondemand-configurator_embedded-detailview-outer-wrapper');

		scroll.scrollToElement(container, {
			duration: 660, // time-xxl
			easing: 'easeInOutSine'
		});
	}

	/**
	 * _fireDetailViewEmbeddedEvent - informs other parts of configurator that detail view is embedded
	 */
	_fireDetailViewEmbeddedEvent() {
		let detailViewEmbeddedEvent = new CustomEvent(OndemandConfigurator.EVENT_DETAILVIEW_EMBEDDED);

		this.dispatchEvent(detailViewEmbeddedEvent);
	}

	get isDetailViewStandalone() {
		return this.getAttribute('is-standalone') === 'true';
	}
	set isDetailViewStandalone(val) {
		this.setAttribute('is-standalone', val);
	}
}

if (window.customElements.get('audi-axs-ondemand-configurator') === undefined) {
	window.customElements.define('audi-axs-ondemand-configurator', OndemandConfigurator);
}
