(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("application-bundle"), require("core-utils"), require("global-bundle"));
	else if(typeof define === 'function' && define.amd)
		define("editorial-bundle", ["application-bundle", "core-utils", "global-bundle"], factory);
	else if(typeof exports === 'object')
		exports["editorial-bundle"] = factory(require("application-bundle"), require("core-utils"), require("global-bundle"));
	else
		root["editorial-bundle"] = factory(root["application-bundle"], root["core-utils"], root["global-bundle"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__5001__, __WEBPACK_EXTERNAL_MODULE__1139__, __WEBPACK_EXTERNAL_MODULE__7366__) {
return 