import {JClickable} from 'global-bundle';
import OndemandConfigurator from '../configurator/_configurator';
import {dom} from 'core-utils';

export default class ModelFinder extends HTMLElement {

	static get CLASS_ITEM_SELECTED() {
		return 'axs-ondemand-configurator__modelfinder-modeltile-selected';
	}
	static get CLASS_NO_RESULTS() {
		return 'axs-ondemand-configurator--no-results';
	}

	/**
	 * constructor
	 */
	constructor() {
		super();
		this.modelfinderItems = undefined;
		this.ondemandConfigurator = undefined;
		this.carModels = {};
		this.visibleCarModels = [];
		this.handleModelSelectionCanceled = this._handleModelSelectionCanceled.bind(this);
		this.handleModelfilterChanged = this._handleModelfilterChanged.bind(this);
		this.handleModelSelection = this._handleModelSelection.bind(this);
		this.isSortedBy = 'carline';
	}

	/**
	 * connectedCallback - callback for adding element to dom/shadow dom
	 */
	connectedCallback() {
		this._initialize();
		this._bind();
	}

	_initialize() {
		this._initElements();
		this._normalizePriceAttributes();
		this._initJClickable();
	}

	_initElements() {
		let itemLength;

		this.ondemandConfigurator = dom.closest(this, '.axs-ondemand-configurator');
		this.modelfinderItems = this.querySelectorAll('.axs-ondemand-configurator__modelfinder-modellist-item');
		itemLength = this.modelfinderItems.length;

		for (let i = 0; i < itemLength; i++) {
			const carmodelId = this.modelfinderItems[i].getAttribute(OndemandConfigurator.ATTRIBUTE_CARMODEL_ID);

			this.carModels[carmodelId] = i;
		}
	}

	/**
	 * make all data-price attributes formatted the same way
	 */
	_normalizePriceAttributes() {
		const priceItems = Array.from(this.querySelectorAll('.axs-ondemand-configurator__modelfinder-modeltile-price-values'));

		priceItems.forEach(priceItem => {
			priceItem.dataset.price = ModelFinder._normalizePrice(priceItem.dataset.price);
		});
	}

	/**
	 * @param {string} price_ - price to be normalized
	 * @returns {string} price in format 1234567.89
	 */
	static _normalizePrice(price_) {
		// Regexp for digits before and after the decimal delimiter: (e.g. 1.234,56)
		// (^[\d.,']+?) looks for numbers and and ' and . and , => match $1 (in this case $3 will be empty)
		// [.,] must have a decimal separator
		// (\d?\d)?$ followed by 1 or 2 digits => match $2
		//
		// If the number does not have decimal places at all: (e.g. 1.234)
		// (^[\d.,']+?)$ catches the rest as match $3 ($1 and $2 are empty in this case)
		//
		// Either $1 or $3 is empty depending on the given number string
		const rex = new RegExp(/(^[\d.,']+?)[.,](\d?\d)?$|(^[\d.,']+?)$/, 'm');

		const price = price_.replace(rex, '$3$1SEP$2') // SEP is a placeholder for the new decimal separator
			.replace(new RegExp(/[.,']/, 'g'), '') // strip all unwanted characters
			.replace('SEP', '.'); // use . a a decimal separator

		return parseFloat(price).toFixed(2);
	}

	_initJClickable() {
		const jClickableElements = this.querySelectorAll('.audi-j-clickable');
		const jClickableElementsLength = jClickableElements.length;

		for (let i = 0; i < jClickableElementsLength; i++) {
			new JClickable(jClickableElements[i]); // eslint-disable-line no-new
		}
	}

	_bind() {
		this._bindItemEvents();
		this.ondemandConfigurator.addEventListener(OndemandConfigurator.EVENT_MODEL_SELECTED, this.handleModelSelection);
		this.ondemandConfigurator.addEventListener(OndemandConfigurator.EVENT_MODELFILTER_CHANGED, this.handleModelfilterChanged);
		this.ondemandConfigurator.addEventListener(OndemandConfigurator.EVENT_MODELSELECTION_CANCELED, this.handleModelSelectionCanceled);
	}

	_bindItemEvents() {
		const modeltileLinksList = this.querySelectorAll('.axs-ondemand-configurator__modelfinder-modeltile-link');
		const modeltileLinksListLength = modeltileLinksList.length;

		for (let index = 0; index < modeltileLinksListLength; index++) {
			const linkItem = modeltileLinksList[index];

			linkItem.addEventListener('click', (event_) => {
				this._handleClickOnModeltileLink(event_, index);
			});
		}
	}

	/**
	 * _handleClickOnModeltileLink - callback function for click on modeltile link
	 * @param {Event} event_ - click event
	 * @param {Event} index_ - modeltile index
	 */
	_handleClickOnModeltileLink(event_, index_) {
		const link = event_.target;
		const modelUrl = link.getAttribute('href');
		const carmodelItem = this.modelfinderItems[index_];
		const carmodelId = carmodelItem.getAttribute(OndemandConfigurator.ATTRIBUTE_CARMODEL_ID);
		const eventDetail = this._prepareEventDetails(modelUrl, carmodelId);

		event_.preventDefault();
		this._setSelectedClassForItem(carmodelItem);
		this._fireModelSelectEventWithDetail(eventDetail);
	}

	/**
	 * @param {Event} modelSelectEvent_ - model selected event (EVENT_MODEL_SELECTED)
	 */
	_handleModelSelection(modelSelectEvent_) {
		const {carmodelId} = modelSelectEvent_.detail;
		const modelfinderItemIndex = this.carModels[carmodelId];
		const item = this.modelfinderItems[modelfinderItemIndex];

		this._setSelectedClassForItem(item);
	}

	/**
	 * @param {Event} modelfilterChangedEvent_ - modelfilter changed event (EVENT_MODELFILTER_CHANGED)
	 */
	_handleModelfilterChanged(modelfilterChangedEvent_) {
		const {filterProperty} = modelfilterChangedEvent_.detail;
		let priceId, sortId;

		switch (filterProperty) {
			case 'bodytype':
				this._filterSelectedModels(modelfilterChangedEvent_.detail.filterValue);
				break;
			case 'price':
				priceId = modelfilterChangedEvent_.detail.filterValue.value;
				this._showSelectedPrices(priceId);
				break;
			case 'sort':
				sortId = modelfilterChangedEvent_.detail.filterValue.value;
				this._sortBy(sortId);
				break;
			default:
				break;
		}
	}

	_handleModelSelectionCanceled() {
		this._removeSelectedClassFromItems();
	}

	/**
	 * @returns {boolean} isHidden - is active item hidden (true) or not (false)
	*/
	_isActiveModelTileHidden() {
		let isHidden = false;
		const activeModel = this.querySelector('.' + ModelFinder.CLASS_ITEM_SELECTED);

		if (activeModel) {
			isHidden = activeModel.classList.contains('axs-ondemand-configurator__modelfinder-modeltile-hidden');
		}

		return isHidden;
	}

	_fireModelSelectionCanceledEvent() {
		const eventModelSelectionCanceled = new CustomEvent(OndemandConfigurator.EVENT_MODELSELECTION_CANCELED);

		this.ondemandConfigurator.dispatchEvent(eventModelSelectionCanceled);
	}

	/**
	 * @param {string} priceId_ - id of the selected price
	 */
	_showSelectedPrices(priceId_) {
		const modelLength = this.modelfinderItems.length;

		for (let key = 0; key < modelLength; key++) {
			this._showSelectedPrice(this.modelfinderItems[key], priceId_);
		}

		this._checkAndSortAfterPriceChange();
	}

	_checkAndSortAfterPriceChange() {
		if (this.isSortedBy === 'price_asc' || this.isSortedBy === 'price_desc') {
			this._sortBy(this.isSortedBy);
		}
	}

	/**
	 * @param {HTMLElement} model_ - model
	 * @param {string} priceId_ - id of the selected price
	 */
	_showSelectedPrice(model_, priceId_) {
		const selectedPriceItem = model_.querySelector(`[data-priceid="${priceId_}"]`);
		const lastSelectedPriceItem = model_.querySelector('.axs-ondemand-configurator__modelfinder-modeltile-price-selected');

		if (selectedPriceItem !== lastSelectedPriceItem) {
			if (lastSelectedPriceItem) {
				lastSelectedPriceItem.classList.remove('axs-ondemand-configurator__modelfinder-modeltile-price-selected');
			}

			selectedPriceItem.classList.add('axs-ondemand-configurator__modelfinder-modeltile-price-selected');
		}
	}

	/**
	 * @param {string} sortId_ - the isd to sort by
	 */
	_sortBy(sortId_) {
		switch (sortId_) {
			case 'price_asc':
				this._sortByPrice();
				break;
			case 'price_desc':
				this._sortByPrice('desc');
				break;
			default:
				this._resetOrder();
				break;
		}

		this.isSortedBy = sortId_;
	}

	/**
	 * @param {string} direction_ - asc or desc
	 */
	_sortByPrice(direction_ = 'asc') {
		let [...priceItems] = this.querySelectorAll('.axs-ondemand-configurator__modelfinder-modeltile-price-selected');

		priceItems = this._sortByDataPriceAttribute(priceItems, direction_);
		this._setOrder(priceItems);
	}

	/**
	 *
	 * @param {Array} priceItems_ - DOM elements
	 * @param {string} direction_ - asc or desc
	 * @returns {Array} priceItems_ - sorted priceItems_
	 */
	_sortByDataPriceAttribute(priceItems_, direction_) {
		priceItems_.sort((a, b) => {
			return (direction_ !== 'asc')
				? b.getAttribute('data-price') - a.getAttribute('data-price')
				: a.getAttribute('data-price') - b.getAttribute('data-price');
		});
		return priceItems_;
	}

	/**
	 * @param {Array} priceItems_ - array of price items
	 */
	_setOrder(priceItems_) {
		priceItems_.forEach((tile, index) => {
			dom.closest(tile, '.axs-ondemand-configurator__modelfinder-modellist-item').style.order = index.toString();
		});
	}

	_resetOrder() {
		this.modelfinderItems.forEach((tile) => {
			tile.style.order = '0';
		});
	}

	/**
	 * @param {Array<string>} selectedItemValues_ - all selected values
	 */
	_filterSelectedModels(selectedItemValues_) {
		this._showSelectedModels(selectedItemValues_);
		this._fireModelResultsChangeEvent(this.visibleCarModels);
		this._handleNoResults();

		if (this._isActiveModelTileHidden()) {
			this.querySelector('.' + ModelFinder.CLASS_ITEM_SELECTED).classList.remove(ModelFinder.CLASS_ITEM_SELECTED);
			this._fireModelSelectionCanceledEvent();
		}
	}

	/**
	 * @param {Array<string>} selectedItemValues_ - all selected values
	 */
	_showSelectedModels(selectedItemValues_) {
		const modelLength = this.modelfinderItems.length;

		this.visibleCarModels = [];

		for (let key = 0; key < modelLength; key++) {
			if (this._isOfSelectedBodyType(this.modelfinderItems[key], selectedItemValues_)) {
				this._showModel(this.modelfinderItems[key]);
			}
			else {
				this._hideModel(this.modelfinderItems[key]);
			}
		}
	}

	/**
	 * @param {HTMLElement} model_ - the model
	 * @param {Array<string>} selectedItemValues_ - all selected values
	 * @returns {boolean} true if is selected body type
	 */
	_isOfSelectedBodyType(model_, selectedItemValues_) {
		const bodyTypeId = model_.getAttribute('data-bodytype-id');

		if (selectedItemValues_.length === 0) {
			return true;
		}

		return (selectedItemValues_.indexOf(bodyTypeId) !== -1);
	}

	/**
	 * @param {HTMLElement} model_ - the model
	 */
	_showModel(model_) {
		const carmodelId = model_.getAttribute(OndemandConfigurator.ATTRIBUTE_CARMODEL_ID);

		model_.classList.remove('axs-ondemand-configurator__modelfinder-modeltile-hidden');
		this.visibleCarModels.push(carmodelId);
	}

	/**
	 * @param {HTMLElement} model_ - the model
	 */
	_hideModel(model_) {
		model_.classList.add('axs-ondemand-configurator__modelfinder-modeltile-hidden');
	}

	/**
	 * @param {Array} visibleCarModels_ - array with ids of visible car models
	 */
	_fireModelResultsChangeEvent(visibleCarModels_) {
		let modelResultsChangeEvent;
		const eventDetail = {
			visibleCarModels: visibleCarModels_
		};

		modelResultsChangeEvent = new CustomEvent(OndemandConfigurator.EVENT_MODEL_RESULTS_CHANGED, {detail: eventDetail});
		this.ondemandConfigurator.dispatchEvent(modelResultsChangeEvent);
	}

	_handleNoResults() {
		if (this.visibleCarModels.length === 0) {
			this.ondemandConfigurator.classList.add(ModelFinder.CLASS_NO_RESULTS);
		}
		else {
			this.ondemandConfigurator.classList.remove(ModelFinder.CLASS_NO_RESULTS);
		}
	}

	/**
	 * @param {HTMLElement} item_ - modeltile item
	 */
	_setSelectedClassForItem(item_) {
		this._removeSelectedClassFromItems();
		item_.classList.add(ModelFinder.CLASS_ITEM_SELECTED);
	}

	_removeSelectedClassFromItems() {
		const selectedModeltile = this.querySelector('.' + ModelFinder.CLASS_ITEM_SELECTED);

		if (selectedModeltile) {
			selectedModeltile.classList.remove(ModelFinder.CLASS_ITEM_SELECTED);
		}
	}

	/**
	 * @param {string} carmodelUrl_ - model url
	 * @param {string} carmodelId_ - model id
	 */
	_prepareEventDetails(carmodelUrl_, carmodelId_) {
		const details = {};

		details.url = carmodelUrl_;
		details.carmodelId = carmodelId_;

		return details;
	}

	/**
	 * @param {Object} eventDetail_ - event detail object
	 */
	_fireModelSelectEventWithDetail(eventDetail_) {
		let modelSelectEvent;

		modelSelectEvent = new CustomEvent(OndemandConfigurator.EVENT_MODEL_SELECTED, {
			detail: eventDetail_
		});

		this.ondemandConfigurator.dispatchEvent(modelSelectEvent);
	}
}

if (window.customElements.get('audi-axs-modelfinder') === undefined) {
	window.customElements.define('audi-axs-modelfinder', ModelFinder);
}
