import {dom} from 'core-utils';

import './cookieNotification';

export default class HeaderNavigation extends HTMLElement {
	constructor() {
		super();

		this._collectElements();

		this.menuToggleHandler = this._menuToggleHandler.bind(this);
		this.langMenuToggleHandler = this._langMenuToggleHandler.bind(this);
		this.setHeaderAppearance = this._setHeaderAppearance.bind(this);

		this.logo.onload = function () {
			this._calculateWidths();
			this.setHeaderAppearance();
		}.bind(this);

		this._calculateWidths();
		this.setHeaderAppearance(); // call initially

		this._addEvents();
	}

	_collectElements() {
		/* the logo link is as wide as the space we can populate with our items
		 * therefor we will use it for calculation of the available width
		*/
		this.logoLink = this.querySelector('.axs-header-navigation__logo-link');
		this.mainNavigation = this.querySelector('.axs-header-navigation__main-navigation');
		this.logo = this.querySelector('.axs-header-navigation__logo');
		this.menuToggle = this.querySelector('.axs-header-navigation__main-nav-toggle-input');
		this.specialLinks = Array.from(this.querySelectorAll('.axs-header-navigation__link--special > a'));
		this.specialLinksWrapper = this.querySelector('.axs-header-navigation__special-links-wrapper');
		this.normalLinks = Array.from(this.querySelectorAll('.axs-header-navigation__link:not(.axs-header-navigation__link--special) > a'));
		this.langMenu = this.querySelector('.axs-header-navigation__language-menu');
		this.langMenuToggle = this.querySelector('.axs-header-navigation__lang-nav-toggle-input');
	}

	_addEvents() {
		window.addEventListener('resize', this.setHeaderAppearance);
		this.menuToggle.addEventListener('change', this.menuToggleHandler);

		if (this.langMenuToggle) {
			this.langMenuToggle.addEventListener('change', this.langMenuToggleHandler);
		}
	}

	disconnectedCallback() {
		window.removeEventListener('resize', this.setHeaderAppearance);
	}

	/**
	 * calculate the widths of the header parts
	 * @returns {undefined}
	 */
	_calculateWidths() {
		const spaceXL = 30;
		const reducer = (accumulator, currentValue) => accumulator + currentValue.offsetWidth + spaceXL;

		this.logoWidth = this.logo.offsetWidth;
		this.specialLinksWidth = this.specialLinks.reduce(reducer, 0);
		this.normalLinksWidth = this.normalLinks.reduce(reducer, 0);
		this.langMenuWidth = (this.langMenu) ? this.langMenu.offsetWidth : 0;
	}

	/**
	 * decides header appearance based on the width of the elements and the available width.
	 * sets the classes on header and specialLinksWrapper.
	 */
	_setHeaderAppearance() {
		const availableWidth = this.logoLink.offsetWidth;

		// 37 = 2 * space-m + grey separator line (1px)
		const widestAppearanceWidth = this.logoWidth + this.specialLinksWidth + this.normalLinksWidth + 37 + this.langMenuWidth;

		if (widestAppearanceWidth < availableWidth) {
			this._isSpecialLinksInTopBar = false;
			this.classList.add('axs-header-navigation--no-flyout');
		}
		else {
			this.classList.remove('axs-header-navigation--no-flyout');

			// 24 = button width on the right
			const neededHeaderSpaceForSpecialLinks = this.logoWidth + this.specialLinksWidth + 24;

			const enoughSpaceForSpecialLinksInTopBar = neededHeaderSpaceForSpecialLinks < availableWidth;
			const hasNormalLinks = this.normalLinksWidth > 0;

			if (enoughSpaceForSpecialLinksInTopBar && hasNormalLinks) {
				this._isSpecialLinksInTopBar = true;
			}
			else {
				this._isSpecialLinksInTopBar = false;
			}
		}
	}

	/**
	 * @param {boolean} value_ - whether
	 */
	set _isSpecialLinksInTopBar(value_) {
		if (this.specialLinksWrapper) {
			if (value_ === true) {
				this.specialLinksWrapper.classList.add('axs-header-navigation__special-links-wrapper--in-topbar');
			}
			else {
				this.specialLinksWrapper.classList.remove('axs-header-navigation__special-links-wrapper--in-topbar');
			}
		}
	}

	/**
	 * If main navigation is opened prevent scrolling of the background by setting overflow: hidden to body element via a class
	 * @param {Object} event_ - Checkbox change event
	 */
	_menuToggleHandler(event_) {
		if (event_.target.checked) {
			document.body.classList.add('axs-body--header-open');

			if (this.langMenuToggle) {
				this.langMenuToggle.checked = false;
			}

			this.mainNavigation.scrollTop = 0;
		}
		else {
			document.body.classList.remove('axs-body--header-open');
		}
	}

	/**
	 * If language menu is opened, scroll down to view all languages
	 * @param {Object} event_ - Checkbox change event
	 */
	_langMenuToggleHandler(event_) {
		if (event_.target.checked) {
			const offset = this.langMenu.offsetTop - this.mainNavigation.offsetTop;

			dom.animateElementY(offset, this.mainNavigation, {
				property: 'scrollTop',
				duration: 420, // time-xl
				easing: 'easeOutSine'
			});
		}
	}
}

customElements.define('audi-axs-header-navigation', HeaderNavigation);
