import {appEvents} from 'application-bundle';
import {dom, template} from 'core-utils';
import {jsloader} from 'global-bundle';

import {map as MAIN_MAP} from './map';

import {mapContainerTemplate as CONTAINER_TPL} from './map-container-template';
import {mapPartnerDetailsTemplate as DETAIL_TPL} from './map-partner-details-template';
import {mapDataUris as DATA_URIS} from './map-data-uris';

var exports, __;

__ = {};
exports = {
	__
};
__.updateLock = false;
__.updateReleaseCallback = null;
__.defaultZoomLevel = 6;
__.defaultMapCenter = '51.318928,9.49601';
__.dealerData = null;
__.mapContainer = null;
__.markers = [];
__.centerMarker = null;
__.dataURIs = JSON.parse(DATA_URIS);
__.defaultStyledMap = null;
__.topLevelStyledMap = null;
__.defaultCountryCode = 'de';
__.partnerDetailsSelector = '.map-main .map-partner-details';
__.config = {};
__.listScrollLocker = false;
__.markersWithinCurrentBounds = null;
__.currentCenter = null;
__.currentBounds = null;
__.updateListener = null;
__.mapLoadedListener = null;
__.mapApiLoaded = false;
__.mapApiLoading = false;

__.readConfig = function(configJSON, distanceUnit) { // eslint-disable-line max-statements
	var config, key, zoomLevel;

	if (!__.config) {
		__.config = {};
	}

	try {
		config = JSON.parse(configJSON);
		// Set fallback for MapsDirectionsBaseUrl
		__.config.MapsDirectionsBaseUrl = 'https://maps.google.com/maps/dir';

		for (key in config) {
			if (Object.prototype.hasOwnProperty.call(config, key)) {
				__.config[key] = config[key];
			}
		}

		__.config['distanceUnit'] = distanceUnit;
		zoomLevel = __.config.MapDefaultZoomlevel || __.defaultZoomLevel;
		__.defaultZoomLevel = Number(zoomLevel);

		if (document.body.clientWidth < 750) {
			__.defaultZoomLevel -= 1;
		}

		__.defaultMapCenter = __.config.MapDefaultMapCenter || __.defaultMapCenter;
		__.config.localeLang = __.config.MapLocaleLanguage || 'en';
	}
	catch (err) {
		console.error('MAP: Could not read config', err);
	}
};

/*
	* Sets an internal lock parameter releases it after a given
	* timeout. Optionally runs a callback function providing the lock.
	* @param timeout {number} -- Time until lock is released (in ms).
	*/
__.lock_and_release = function(timeout) {
	__.updateLock = true;
	setTimeout(() => {
		__.updateLock = false;

		if (typeof __.updateReleaseCallback === 'function') {
			__.updateReleaseCallback();
			__.updateReleaseCallback = false;
		}
	}, timeout);
};

__.addMarkerHighlightEvents = function(markers, activeId) {
	MAIN_MAP.highlightMarkers(markers, activeId);
};

__.getMarkerIcon = function(data) {
	data.activeIconKey = 'pin_audi_active_' + ((data && data.address && data.address.style)? data.address.style : 'red');
	data.inactiveIconKey = 'pin_audi_inactive';
	return __.dataURIs[data.activeIconKey];
};

__.initMarkers = function() {
	var i, latLng, marker, MarkerWithLabel,
		partnerList = dom.getElementsArray('.map-partner li');

	__.dealerData = {
		partners: []
	};

	for (i = 0; i < partnerList.length; i++) {
		__.dealerData.partners.push({
			address: {
				latitude: partnerList[i].getAttribute('data-lat'),
				longitude: partnerList[i].getAttribute('data-long'),
				city: partnerList[i].getAttribute('data-city'),
				style: partnerList[i].getAttribute('data-style')
			},
			kvpsid: partnerList[i].getAttribute('data-id'),
			product: JSON.parse(partnerList[i].getAttribute('data-product')).product
		});
	}

	__.markers = [];
	// eslint-disable-next-line no-undef
	MarkerWithLabel = require('markerwithlabel')(google.maps);

	for (i = __.dealerData.partners.length - 1; i >= 0; i--) {
		latLng = new google.maps.LatLng(__.dealerData.partners[i].address.latitude, __.dealerData.partners[i].address.longitude);
		marker = new MarkerWithLabel({
			position: latLng,
			zIndex: 10,
			map: MAIN_MAP.getMap(),
			labelContent: __.dealerData.partners[i].address.city,
			labelAnchor: new google.maps.Point(20, 75),
			labelClass: 'axs-map-labels', // the CSS class for the label
			icon: {
				url: __.getMarkerIcon(__.dealerData.partners[i])
			}
		});
		// add index
		marker.dshIndex = i;
		marker.kvpsid = __.dealerData.partners[i].id;
		marker.data = __.dealerData.partners[i];
		// add click event
		__.addMarkerListener(marker);
		__.markers.unshift(marker);
	}

	__.markerCluster = MAIN_MAP.createCluster(MAIN_MAP.getMap(), __.markers);
};

__.addMarkerListener = function(marker) {
	google.maps.event.addListener(marker, 'click', () => {
		exports.handleMarkerClick(marker);
	});
	google.maps.event.addListener(marker, 'mouseover', () => {
		exports.handleMarkerMouseover(marker);
	});
	google.maps.event.addListener(marker, 'mouseout', () => {
		exports.handleMarkerMouseout(marker);
	});
};

__.addUIEvents = function() {
	var delegate = dom.getEventDelegate('body');

	delegate.on('click', '#map', exports.handleDetailsClose);
	delegate.on('click', '#map-zoom-in', exports.handleZoomIn);
	delegate.on('click', '#map-zoom-out', exports.handleZoomOut);
};

__.setClassName = function(classNameToAdd) {
	var {className} = __.mapContainer;

	if (className.length === 0 || className.indexOf(classNameToAdd) === -1) {
		__.mapContainer.className = className + ' ' + classNameToAdd;
	}

	if ((classNameToAdd === 'map-results-active') && (document.body.clientWidth < 769)) {
		MAIN_MAP.getMap().setOptions({
			draggable: false
		});
	}
	else {
		MAIN_MAP.getMap().setOptions({
			draggable: true
		});
	}
};

__.removeClassName = function(classNameToRemove) {
	var {className} = __.mapContainer;
	var re = new RegExp(classNameToRemove, 'g');

	__.mapContainer.className = className.replace(re, '');
	MAIN_MAP.getMap().setOptions({
		draggable: true
	});
};

__.getI18NKey = function(key) {
	if (!window || !window.i18n) {
		return undefined;
	}

	return window.i18n[key];
};

exports.handleZoomIn = function() {
	var map = MAIN_MAP.getMap();

	if (map) {
		map.setZoom(map.zoom + 1);
		exports.handleCenterChange();
	}
};

exports.handleZoomOut = function() {
	var map = MAIN_MAP.getMap(),
		zoom;

	if (map) {
		zoom = map.zoom - 1;
		map.setZoom(zoom);
		exports.handleCenterChange();
	}
};

exports.handleResize = function() {
	var map = MAIN_MAP.getMap();

	if (map) {
		google.maps.event.trigger(map, 'resize');
		map.setCenter(__.currentCenter);
		__.updateDetailsPosition();
	}
};

exports.saveCenter = function() {
	var map = MAIN_MAP.getMap();

	if (map) {
		__.currentCenter = map.getCenter();
		__.currentBounds = map.getBounds();
	}
};

exports.handleZoomChange = function() {
	var map = MAIN_MAP.getMap();

	exports.hideDetails();

	if (map.zoom > __.defaultZoomLevel) {
		map.setMapTypeId('default');
	}
	else {
		map.setMapTypeId('topLevel');
	}
};

exports.handleCenterChange = function() {
	if (__.updateLock !== true) {
		__.lock_and_release(1000);
	}
	else {
		__.updateReleaseCallback = exports.handleCenterChange;
	}
};

exports.handleMarkerClick = function(marker) {
	exports.showDetails(marker);
};

exports.handleMarkerMouseover = function(marker) {
	var resultListItemEl, resultListEl, scrollTime;

	scrollTime = 500;
	resultListEl = dom.getElement('.map-results');

	if (dom.isElement(resultListEl)) {
		if (!__.listScrollLocker) {
			__.listScrollLocker = true;
			setTimeout(() => {
				__.listScrollLocker = false;
			}, scrollTime);
			resultListItemEl = dom.getElement('div[data-map-kvpsid="' + marker.kvpsid + '"]');

			if (dom.isElement(resultListItemEl)) {
				resultListEl.scrollTop = 0;
				dom.getElement('.map-resultlist-item').classList.remove('is-active');
				resultListItemEl.classList.add('is-active');
			}
		}
	}
};

exports.handleMarkerMouseout = function() {
	if (document.querySelectorAll('.map-details-active').length === 0) {
		dom.getElement('.map-resultlist-item').classList.remove('is-active');
	}
};

exports.handleDetailsClose = function(event) {
	if (event.target.tagName === 'div' || event.target.tagName === 'DIV') {
		exports.hideDetails();
	}
};

exports.getMarkerPosition = function(marker_) {
	var scale = Math.pow(2, MAIN_MAP.getMap().getZoom()),
		nw = new google.maps.LatLng(MAIN_MAP.getMap().getBounds().getNorthEast().lat(), MAIN_MAP.getMap().getBounds().getSouthWest().lng()),
		worldCoordinateNW = MAIN_MAP.getMap().getProjection().fromLatLngToPoint(nw),
		worldCoordinate = MAIN_MAP.getMap().getProjection().fromLatLngToPoint(marker_.getPosition()),
		pixelOffset = new google.maps.Point(Math.floor((worldCoordinate.x - worldCoordinateNW.x) * scale), Math.floor((worldCoordinate.y - worldCoordinateNW.y) * scale));

	return pixelOffset;
};

/**
 * update details position in order that it does not leave map container
 * (only if positioned absolute above maps container)
 */
__.updateDetailsPosition = function() {
	var {className} = __.mapContainer;
	var currentDetailsStyle, currentDetailsPosition, mapWidth;

	if (className.length > 0 && className.indexOf('map-details-active') !== -1) {
		mapWidth = dom.getElement('.map-main').offsetWidth;
		currentDetailsStyle = window.getComputedStyle(__.partnerDetailsContainer);
		currentDetailsPosition = currentDetailsStyle.position;

		if (currentDetailsPosition === 'absolute' && parseInt(__.partnerDetailsContainer.style.left, 10) > (mapWidth - __.partnerDetailsContainer.offsetWidth)) {
			__.partnerDetailsContainer.style.left = (mapWidth - __.partnerDetailsContainer.offsetWidth) + 'px';
		}
	}
};

exports.showDetails = function(data) { // eslint-disable-line max-statements
	var content, resultListItem, data_,
		pixelOffset = exports.getMarkerPosition(data),
		mapWidth,
		offsetWidth;

	data_ = __.enhanceDealerData(data.data);
	resultListItem = dom.getElement('.map-resultlist-item[data-map-kvpsid="' + data_.kvpsid + '"]');
	dom.getElement('.map-resultlist-item').classList.remove('is-active');
	resultListItem.classList.add('is-active');

	if (!__.partnerDetailsContainer) {
		__.partnerDetailsContainer = dom.getElement(__.partnerDetailsSelector);
	}

	__.partnerDetailsContainer.style.left = Math.max(0, (pixelOffset.x + 40)) + 'px';
	// check if there is enough space to the left ...
	mapWidth = dom.getElement('.map-main').offsetWidth;
	offsetWidth = __.partnerDetailsContainer.offsetWidth + pixelOffset.x + 40;

	if (mapWidth < offsetWidth) {
		__.partnerDetailsContainer.style.left = (pixelOffset.x - 40 - __.partnerDetailsContainer.offsetWidth) + 'px';
	}

	__.partnerDetailsContainer.setAttribute('data-map-kvpsid', data_.kvpsid);
	const {className} = __.mapContainer;

	if (className.length === 0 || className.indexOf('map-details-active') === -1) {
		__.setClassName('map-details-active');
		content = template.render(DETAIL_TPL, data_);
		__.partnerDetailsContainer.innerHTML = content;
	}
	else {
		__.removeClassName('map-details-active');
		__.partnerDetailsContainer.innerHTML = '';
		// TODO: kill timeout if needed
		// Add short delay, to have an open / close box
		setTimeout(() => {
			__.setClassName('map-details-active');
			content = template.render(DETAIL_TPL, data_);
			__.partnerDetailsContainer.innerHTML = content;
		}, 400);
	}

	__.addMarkerHighlightEvents(__.markers, data_.kvpsid);
};

__.enhanceDealerData = function(item) {
	item.directionsLink = __.config.MapsDirectionsBaseUrl + '/\'\'/' + encodeURIComponent(item.name) + ',' + item.address.latitude + ',' + item.address.longitude + ',15z';
	item.service = true;
	item.dshConfig = __.config;
	return item;
};

exports.hideDetails = function() {
	var resultListItem, kvpsid;

	if (!__.partnerDetailsContainer) {
		__.partnerDetailsContainer = dom.getElement(__.partnerDetailsSelector);
	}

	__.removeClassName('map-details-active');
	__.partnerDetailsContainer.innerHTML = '';
	kvpsid = __.partnerDetailsContainer.getAttribute('data-map-kvpsid');
	resultListItem = dom.getElement('.map-resultlist-item[data-map-kvpsid="' + kvpsid + '"]');
	resultListItem.classList.remove('is-active');
	MAIN_MAP.resetMarkers(__.markers);
};

__.updateMarkerData = function(items) {
	var i, newKvpsids, distances, distancesOnAddress, item;

	newKvpsids = [];
	distances = {};
	distancesOnAddress = {};

	// memorize kvpsids
	for (i = items.length - 1; i >= 0; i--) {
		item = items[i][0];
		newKvpsids.push(item.kvpsid);
		distances[item.kvpsid] = item.distance;

		if (!!(item.address && item.address.distance)) {
			distancesOnAddress[item.kvpsid] = item.address.distance;
		}
	}

	newKvpsids = newKvpsids.toString();

	// update existing marker data
	for (i = __.markers.length - 1; i >= 0; i--) {
		if (newKvpsids.indexOf(__.markers[i].data.kvpsid) !== -1) {
			__.markers[i].data.distance = distances[__.markers[i].data.kvpsid];
			__.markers[i].data.address.distance = distancesOnAddress[__.markers[i].data.kvpsid];
		}
	}
};

__.setCenterMarker = function(latlng) {
	// Remove existing marker
	if (__.centerMarker) {
		__.centerMarker.setMap(null);
	}

	// Set new marker
	__.centerMarker = new google.maps.Marker({
		position: latlng,
		icon: __.dataURIs.location,
		zIndex: 1,
		map: MAIN_MAP.getMap()
	});
};

__.loadMapAPI = function() {
	var url;

	if (__.containerEl && !!__.containerEl.getAttribute('data-url')) {
		__.mapApiLoading = true;
		url = __.containerEl.getAttribute('data-url');
		jsloader.loadURL(url).then(() => {
			__.mapApiLoaded = true;
			__.mapApiLoading = false;
			__.setMap();
		});
	}
};

__.setMap = function() {
	__.readConfig(__.containerEl.getAttribute('data-map-config'), __.containerEl.getAttribute('data-map-distance-unit'));
	__.containerEl.innerHTML = (template.render(CONTAINER_TPL, {
		title: 'MAP',
		dshConfig: __.config
	}));
	// wait for google to be loaded
	MAIN_MAP.waitForMaptoBeAvailable().then(() => {
		// read config
		MAIN_MAP.renderMap({
			defaultMapCenter: __.defaultMapCenter,
			defaultZoomLevel: __.defaultZoomLevel
		}).then(() => {
			__.updateListener = MAIN_MAP.addListener('bounds_changed', exports.handleCenterChange);
			__.mapLoadedListener = MAIN_MAP.addListener('tilesloaded', exports.handleCenterChange);
			MAIN_MAP.addListener('zoom_changed', exports.handleZoomChange);
			MAIN_MAP.addListener('idle', exports.saveCenter);
			google.maps.event.addDomListener(window, 'resize', exports.handleResize);
			google.maps.event.addDomListener(window, 'scroll', () => {
				// fix for scrolling on iphone
				MAIN_MAP.removeListener(__.updateListener);
				MAIN_MAP.removeListener(__.mapLoadedListener);
				setTimeout(() => {
					__.updateListener = MAIN_MAP.addListener('bounds_changed', exports.handleCenterChange);
					__.mapLoadedListener = MAIN_MAP.addListener('tilesloaded', exports.handleCenterChange);
				}, 100);
			});
			__.initMarkers();
			__.addUIEvents();
		});
	});
};

__.initMap = function() {
	var element = dom.getElement('#map');

	if (!dom.isElement(element)) {
		return;
	}

	__.containerEl = element;
	__.mapContainer = element;
	__.loadMapAPI();
};

__.init = function() {
	__.globalEventBus.on(appEvents.LAYER_LOADED, () => {
		__.mapContainer = document.getElementById('map');

		if (!__.mapContainer) {
			setTimeout(__.initMap, 100);
		}
	});
	__.initMap();
};

/**
	* public initialize method
	* @param {EventBus} globalEventBus - tbd
	* @returns {Promise} returns Promise
	*/
exports.initialize = function(globalEventBus) {
	return new Promise(resolve => {
		__.globalEventBus = globalEventBus;
		__.init();
		resolve('map/api.js');
	});
};

export {exports as api};
