
import CountrySelectorMapAnimation from './country-selector-map-animation';

export default class CountrySelectorMap {

	/**
	* constructor
	* @param {Object} container_ - parent container(= country-selector)
	*/
	constructor(container_) {
		this.CountrySelector = container_;
		this.Map = null;
		this.initialMapViewport = null;
		this.activeContinentsAndCountries = this.CountrySelector.activeContinentsAndCountries;

		this._initializeMap();
	}

	/**
	* set the initial state of the World-Map and initialize interaction
	*/
	_initializeMap() {
		let svgContinents;

		this.Map = this.CountrySelector.querySelector('.country-selector-map');
		this.MapAnimation = new CountrySelectorMapAnimation(this.Map);
		this.initialMapViewport = this.Map.getAttribute('viewBox');
		svgContinents = this.Map.querySelectorAll('g');

		this._setActiveContinentsOnMap(svgContinents);
		this._addMapEventsForActiveContinents(this.activeContinentsAndCountries.continents);
	}

	/**
	* @param  {HTMLCollection} activeContinents_ - the elements of the map to be highlighted
	*/
	_setActiveContinentsOnMap(activeContinents_) {
		let svgCountries;
		let currentContinentID;

		Array.from(activeContinents_).forEach(currentContinent => {
			currentContinentID = currentContinent.getAttribute('data-id');

			if (this._isContinentActive(currentContinentID)) {
				svgCountries = currentContinent.querySelectorAll('path');

				currentContinent.setAttribute('data-state', 'active');
				this._setActiveCountriesOnMap(svgCountries);
			}
		});
	}

	/**
	 *
	 * @param {HTMLCollection} svgCountries_ - the country elements of the map to be highlighted
	 */
	_setActiveCountriesOnMap(svgCountries_) {
		let currentCountryID;

		Array.from(svgCountries_).forEach(currentCountry => {
			currentCountryID = currentCountry.getAttribute('data-id');

			if (this._isCountryActive(currentCountryID)) {
				currentCountry.setAttribute('data-state', 'active');
			}
		});
	}

	/**
	* @param {Array} activeContinents_ - only the continents to be initialized
	*/
	_addMapEventsForActiveContinents(activeContinents_) {
		activeContinents_.forEach(currentID => {
			const continent = this.Map.getElementById(currentID);

			continent.addEventListener('click', this._handleMapClick);
		});
	}

	/**
	* @param {Array} activeContinents_ - the continents to remove the events from
	*/
	removeMapEventsForContinents(activeContinents_) {
		activeContinents_.forEach(currentID => {
			const continent = this.Map.getElementById(currentID);

			continent.removeEventListener('click', this._handleMapClick);
		});
	}

	/**
	* _handleMapClick - handle click on map
	* @param {MouseEvent} event_ - the click event
	*/
	_handleMapClick = (event_) => {
		const continentID = this._getContinentForClickedCountry(event_.target.id);
		const countryID = event_.target.id;

		event_.preventDefault();

		if (this._getMapZoomLevel() === 'world') {
			this._handleWorldZoomLevelClick(continentID, countryID);
		}
		else {
			this._handleContinentZoomLevelClick(continentID, countryID);
		}
	};

	/**
	* _handleWorldZoomLevelClick - handle map click in world zoom level mode
	* @param {string} continentID_ - the continent ID
	*/
	_handleWorldZoomLevelClick(continentID_) {
		if (!this.CountrySelector.getContinentSelect()) {
			this.zoomToContinent(continentID_);
		}

		this.CountrySelector.updateContinentDropdown(continentID_);
	}

	/**
	* delegates click on map if clicked item belongs to active continents and / or countries
	* @param {string} continentID_ - the continent ID
	* @param {string} countryID_ - the country ID
	*/
	_handleContinentZoomLevelClick(continentID_, countryID_) {
		if (this.CountrySelector.getContinentSelect() || this._getMapZoomLevel() === 'world') {
			if (this.activeContinentsAndCountries.countries.indexOf(countryID_) !== -1 && this.CountrySelector.getSelectedContinent() === continentID_) {
				this.CountrySelector.updateCountryDropdown(countryID_);
			}
		}
		else {
			if (this.activeContinentsAndCountries.countries.indexOf(countryID_) !== -1) {
				this.CountrySelector.updateCountryDropdown(countryID_);
			}
		}
	}

	/**
	* _getContinentForClickedCountry - get Continent For Clicked Country
	* @param {string} clickTargetCountry_ - the ID of the clicked Country on Map
	* @returns {string} - the ID of the corresponding Continent on Map
	*/
	_getContinentForClickedCountry(clickTargetCountry_) {
		return this.Map.getElementById(clickTargetCountry_).parentNode.id;
	}

	/** _getMapZoomLevel - get map zoom level
	* @returns {string} - the actual Zoom Level of the Map being shown (world/ continent)
	*/
	_getMapZoomLevel() {
		return this.Map.getAttribute('data-zoom-level');
	}

	/**
	* zoomOut - zoom back to 100%
	*/
	zoomOut() {
		this._setMapZoomLevel('world');
		this._resetSelectionStateForElement('.country-selector-map-continent');
		this._resetInteractiveMapViewBox();
	}

	/**
	* zoomToContinent - zoom in to continent
	* @param {string} continent_ - continent to set zoom to
	*/
	zoomToContinent(continent_) {
		this._zoomInAndOutMapView(continent_);
		this._setMapZoomLevel('continent');
		this._resetSelectionStateForElement('.country-selector-map-country');
		this._resetSelectionStateForElement('.country-selector-map-continent');
		this._setSelectionStateForElementByID(continent_, 'selected');
	}

	/**
	* zoomToCountry - zoom in to country
	* @param {string} country_ - country to set zoom to
	*/
	zoomToCountry(country_) {
		this._resetSelectionStateForElement('.country-selector-map-country');
		this._setSelectionStateForElementByID(country_, 'selected');
	}

	/**
	* _setMapZoomLevel- set map zoom level
	* @param {string} zoomLevel_ - the actual Zoom Level of the Map to be shown (world/ continent)
	*/
	_setMapZoomLevel(zoomLevel_) {
		this.Map.setAttribute('data-zoom-level', zoomLevel_);
	}

	/**
	* _isContinentActive - is continent active
	* @param  {string} continentID_ - the ID to be checked
	* @returns {boolean} - wether the given ID is selected or not
	*/
	_isContinentActive(continentID_) {
		return this.activeContinentsAndCountries.continents.indexOf(continentID_) !== -1;
	}

	/**
	* @param  {string} countryID_ - the ID to be checked
	* @returns {boolean} - wether the given ID is selected or not
	*/
	_isCountryActive(countryID_) {
		return this.activeContinentsAndCountries.countries.indexOf(countryID_) !== -1;
	}

	/**
	* Set selection State for given ID
	* @param {string} id_ - the Element's ID
	* @param {string} state_ - the state to be set
	*/
	_setSelectionStateForElementByID(id_, state_) {
		if (id_ !== 'none') {
			this.Map.getElementById(id_).setAttribute('data-selection', state_);
		}
	}

	/**
	* reset selection State for given ID
	* @param {string} selector_ - the class Selector for the Elements to be reset
	*/
	_resetSelectionStateForElement(selector_) {
		const targets = this.Map.querySelectorAll(selector_);

		Array.from(targets).forEach(target => {
			target.removeAttribute('data-selection');
		});
	}

	/**
	* trigger the switch from global view to continental view and trigger update for controls
	* @param {string} targetElement_ - the selected continent
	*/
	_zoomInAndOutMapView(targetElement_) {
		const targetViewBox = this._getTargetViewBoxAttribute(targetElement_);

		this.MapAnimation.updateViewBoxChange(targetViewBox);
	}

	/**
	* every continent has the corresponding targetViewBox defined as 'data-target-viewbox' attribute
	* @param {string} targetElement_ - the selected continent
	* @returns {string} the target viewbox attribute
	*/
	_getTargetViewBoxAttribute(targetElement_) {
		const targetSVGElement = this.Map.getElementById(targetElement_);

		return targetSVGElement.getAttribute('data-target-viewbox');
	}

	/**
	* sets the interactive map to the initial view
	*/
	_resetInteractiveMapViewBox() {
		this.MapAnimation.updateViewBoxChange(this.initialMapViewport);
	}
}
