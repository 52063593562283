const template = `
 <div class="dsh-container">

	<header class="dsh-header">

		<h1 class="axs-el-hdl axs-el-hdl-02 axs-at-hdl-b">{{=window.i18n['dsh.header.dealersearch']}}</h1>

		<div class="clearfix dsh-search-wrapper">
			<div class="axs-input dsh-input">
				<label class="dsh-search-label" for="dsh-search">{{=window.i18n['dsh.label.search']}}</label>
				<input type="text" class="dsh-search" id="dsh-search" placeholder="{{=window.i18n['dsh.label.search.placeholder']}}"/>
				<a href="#" class="dsh-btn-gps" title="Geolocate me">Geolocation</a>
			</div>

			<div class="dsh-filter-container dsh-form">
				{{?it.dshConfig.DealerSearchEnableETronSearch !== 'off'}}
				<div class="axs-checkbox dsh-filter">
					<input type="checkbox" class="dsh-filter-tron" name="dsh-filter-tron" id="dsh-filter-tron" />
					<label for="dsh-filter-tron" class="axs-el-lbl axs-el-lbl-02">{{=window.i18n['dsh.label.audi.etron.partner']}}</label>
				</div>
				{{?}}
				{{?it.dshConfig.DealerSearchEnableR8Search !== 'off'}}
				<div class="axs-checkbox dsh-filter">
					<input type="checkbox" class="dsh-filter-r8" name="dsh-filter-r8" id="dsh-filter-r8" />
					<label for="dsh-filter-r8" class="axs-el-lbl axs-el-lbl-02">{{=window.i18n['dsh.label.audi.r8.partner']}}</label>
				</div>
				{{?}}
				{{?it.dshConfig.DealerSearchEnableUsedCarsSearch !== 'off'}}
				<div class="axs-checkbox dsh-filter">
					<input type="checkbox" class="dsh-filter-usedcars" name="dsh-filter-usedcars" id="dsh-filter-usedcars" />
					<label for="dsh-filter-usedcars" class="axs-el-lbl axs-el-lbl-02">{{=window.i18n['dsh.label.audi.usedcars.partner']}}</label>
				</div>
				{{?}}
			</div>

			<div class="clearfix dsh-search-container">
				<div class="dsh-search-suggestions clearfix">
					<div class="dsh-city-suggestions"></div>
					<div class="dsh-partner-suggestions"></div>
				</div>
				<p class="dsh-no-suggestions axs-el-hdl axs-el-hdl-05 axs-hidden">{{=window.i18n['dsh.search.noresult']}}</p>
				<a x-cq-linkchecker="valid" href="#" class="axs-close axs-image-replace">schließen</a>

			</div>
		</div>

	</header>

	<main class="dsh-main">
		<div class="dsh-result-details">

			<div class="dsh-results-wrap">
				<div class="dsh-form-select dsh-form-select-red">
					<select class="dsh-results-toggle">
					</select>
				</div>
				<div class="dsh-results"></div>
			</div>

			<div class="dsh-partner-details"></div>

		</div>

		<div class="dsh-map"></div>

		<nav class="dsh-map-controls">
			<button id="dsh-zoom-in" title="Zoom in"><span class="zoom-in">+</span></button>
			<button id="dsh-zoom-out" title="Zoom out"><span class="zoom-out">–</span></button>
		</nav>

	</main>
</div>
`;

export {template as dshContainerTemplate};
