import {dom, swipe} from 'core-utils';
import OndemandConfigurator from '../configurator/_configurator';

export default class Pagination extends HTMLElement {
	// constants
	static get CLASS_ITEM_ACTIVE() { return 'axs-ondemand-configurator__pagination-entry--active'; }
	static get CLASS_ITEM_HIDDEN() { return 'axs-ondemand-configurator__pagination-entry--hidden'; }

	/**
	 * constructor
	 */
	constructor() {
		super();
		this.carModels = {};
		this.activeItemIndex = undefined;
		this.items = undefined; /* all items of pagination (for each car model one item) also hidden items for non selectable cars */
		this.availableItems = undefined; /* items of pagiantion (for car models) that are visible/available */
		this.bodyWrapper = undefined;
		this.configuratorOndemand = undefined;
		this.centerSelectedItem = dom.throttle(this._centerSelectedItem.bind(this), 100);
		this.handleModelSelection = this._handleModelSelection.bind(this);
		this.handleOnResultListChanged = this._handleOnResultListChanged.bind(this);
		this.hidePagination = this._hidePagination.bind(this);
		this.handleButtonClick = this._handleButtonClick.bind(this);
		this.handleSwipeEnd = this._handleSwipeEnd.bind(this);
	}

	/**
	 * connectedCallback - callback for adding element to dom/shadow dom
	 */
	connectedCallback() {
		this._initialize();
		this._bind();
	}

	disconnectedCallback() {
		this._unbindEvents();
	}

	_initialize() {
		this._initializeElements();
		this._initCarmodelArray();
	}

	_initializeElements() {
		this.configuratorOndemand = dom.closest(this, '.axs-ondemand-configurator');
		this.bodyWrapper = this.querySelector('.axs-ondemand-configurator__pagination-body');
		this.items = this.bodyWrapper.querySelectorAll('.axs-ondemand-configurator__pagination-entry');
		this.availableItems = this.items;
		this.buttonForward = this.bodyWrapper.querySelector('.axs-ondemand-configurator__pagination-button--forwards');
		this.buttonBackward = this.bodyWrapper.querySelector('.axs-ondemand-configurator__pagination-button--backwards');
	}

	_initCarmodelArray() {
		const itemLength = this.items.length;

		for (let i = 0; i < itemLength; i++) {
			const carModelId = this.items[i].getAttribute(OndemandConfigurator.ATTRIBUTE_CARMODEL_ID);
			const carModelUrl = this.items[i].getAttribute('data-model-url');

			this.carModels[carModelId] = {
				originalIndex: i,
				availableIndex: i,
				url: carModelUrl
			};
		}
	}

	_bind() {
		this._bindButtonEvents();
		this._bindSwipeEvent();
		this._bindResizeEvent();
		this._bindConfiguratorEvents();
	}

	_unbindEvents() {
		window.removeEventListener('resize', this.centerSelectedItem);
	}

	_bindButtonEvents() {
		this.buttonForward.addEventListener('click', this.handleButtonClick);
		this.buttonBackward.addEventListener('click', this.handleButtonClick);
	}

	_bindSwipeEvent() {
		const swipeContainer = this.querySelector('.axs-ondemand-configurator__pagination-list');
		const randomID = 'axs-ondemand-configurator__swipe-container-' + Math.floor(Math.random() * 100);

		swipeContainer.id = randomID;

		swipe.register(this.handleSwipeEnd, '#' + randomID, {
			swipedistance: 50
		});
	}

	/**
	 * @param {HTMLElement} item_ - item of pagination (one single pagelist entry)
	 */
	_getEventDetailsForItem(item_) {
		const eventDetails = {};

		eventDetails.url = item_.getAttribute(OndemandConfigurator.ATTRIBUTE_CARMODEL_URL);
		eventDetails.carmodelId = item_.getAttribute(OndemandConfigurator.ATTRIBUTE_CARMODEL_ID);

		return eventDetails;
	}

	_bindResizeEvent() {
		window.addEventListener('resize', this.centerSelectedItem);
	}

	_bindConfiguratorEvents() {
		this.configuratorOndemand.addEventListener(OndemandConfigurator.EVENT_MODEL_SELECTED, this.handleModelSelection);
		this.configuratorOndemand.addEventListener(OndemandConfigurator.EVENT_MODEL_RESULTS_CHANGED, this.handleOnResultListChanged);
		this.configuratorOndemand.addEventListener(OndemandConfigurator.EVENT_MODELSELECTION_CANCELED, this.hidePagination);
	}

	/**
	 * @param {Event} modelSelectEvent_ - event, fired when a new model was selected
	 */
	_handleModelSelection(modelSelectEvent_) {
		const carModelId = modelSelectEvent_.detail.carmodelId;

		this._showPagination();
		this._selectItemByCarModelId(carModelId);
	}

	/**
	 * @param {Event} modelResultListChangedEvent_ - event that inform about new result list
	 * @param {Array} modelResultListChangedEvent_.detail.visibleCarModels - model ids for models that fullfill filter criteria
	 */
	_handleOnResultListChanged(modelResultListChangedEvent_) {
		const {visibleCarModels} = modelResultListChangedEvent_.detail;

		this._updateAvailableItems(visibleCarModels);
		this._updateItemsVisibility(visibleCarModels);
		this._updateTotalItems(visibleCarModels);
		this._updatePaginationIndices();
		this._setButtonStates();
		this._centerSelectedItem();
	}

	/**
	 * @param {Array} visibleCarModels_ - list of carmodel ids, that are visible in result list
	 */
	_updateAvailableItems(visibleCarModels_) {
		const itemLength = this.items.length;
		let newAvailableIndex = 0;

		this.availableItems = [];

		for (let i = 0; i < itemLength; i++) {
			const carModelId = this.items[i].getAttribute(OndemandConfigurator.ATTRIBUTE_CARMODEL_ID);

			if (visibleCarModels_.indexOf(carModelId) >= 0) {
				this.availableItems.push(this.items[i]);
				this.carModels[carModelId].availableIndex = newAvailableIndex;
				newAvailableIndex++;
			}
			else {
				this.carModels[carModelId].availableIndex = -1;
			}
		}

		this._updateActiveItemIndex();
	}

	/**
	 * @param {Array} visibleCarModels_ - list of carmodel ids, that are visible in result list
	 */
	_updateItemsVisibility(visibleCarModels_) {
		const allItemsLength = this.items.length;

		for (let i = 0; i < allItemsLength; i++) {
			const carModelId = this.items[i].getAttribute(OndemandConfigurator.ATTRIBUTE_CARMODEL_ID);

			this._showItem(this.items[i]);

			if (visibleCarModels_.indexOf(carModelId) < 0) {
				this._hideItem(this.items[i]);
			}
		}
	}

	/**
	 * @param {Array} visibleCarModels_ - list of carmodel ids, that are visible in result list
	 */
	_updateTotalItems(visibleCarModels_) {
		const totalItemsCounter = this.bodyWrapper.querySelector('.axs-ondemand-configurator__pagination-total');

		totalItemsCounter.innerHTML = visibleCarModels_.length;
	}

	_updatePaginationIndices() {
		const availableItemsLength = this.availableItems.length;

		for (let i = 0; i < availableItemsLength; i++) {
			const itemIndexContainer = this.availableItems[i].querySelector('.axs-ondemand-configurator__pagination-index');

			itemIndexContainer.innerHTML = i + 1;
		}
	}

	/**
	 * @param {HTMLElement} item_ - item to hide
	 */
	_hideItem(item_) {
		item_.classList.add(Pagination.CLASS_ITEM_HIDDEN);
	}

	/**
	 * @param {HTMLElement} item_ - item to show
	 */
	_showItem(item_) {
		item_.classList.remove(Pagination.CLASS_ITEM_HIDDEN);
	}

	/**
	 * @param {HTMLElement} item_ - item to check for visibility
	 * @returns {boolean} - is item hidden (true) or not (false)
	 */
	_isItemHidden(item_) {
		return item_.classList.contains(Pagination.CLASS_ITEM_HIDDEN);
	}

	_showPagination() {
		this.classList.remove('axs-ondemand-configurator__pagination--hidden');
	}

	_hidePagination() {
		this.classList.add('axs-ondemand-configurator__pagination--hidden');
	}

	/**
	 * @param {Event} event_ - click event from button
	 */
	_handleButtonClick(event_) {
		const button = event_.target;
		let newItemIndex;

		event_.preventDefault();

		if (button === this.buttonForward) {
			newItemIndex = this._getNextPaginationItemIndex();
		}
		else {
			newItemIndex = this._getPreviousPaginationItemIndex();
		}

		this._selectItemAndFireEvent(newItemIndex);
	}

	/**
	 * @param {Event} data_ - data with swipe information
	 */
	_handleSwipeEnd(data_) {
		let newItemIndex;
		const wasAVerticalSwipe = data_.direction.vertical !== '';
		const wasASimpleKlick = data_.direction.vertical === '' && data_.direction.horizontal === '';

		if (wasAVerticalSwipe || wasASimpleKlick) {
			return;
		}

		if (data_.direction.horizontal === 'left') {
			newItemIndex = this._getNextPaginationItemIndex();
		}

		if (data_.direction.horizontal === 'right') {
			newItemIndex = this._getPreviousPaginationItemIndex();
		}

		this._selectItemAndFireEvent(newItemIndex);
	}

	_getNextPaginationItemIndex() {
		let nextIndex = this.activeItemIndex;
		const maxIndex = this.availableItems.length - 1;

		if (nextIndex >= 0 && nextIndex < maxIndex) {
			nextIndex++;
		}

		return nextIndex;
	}

	_getPreviousPaginationItemIndex() {
		let previousItemIndex = this.activeItemIndex;

		if (previousItemIndex > 0) {
			previousItemIndex--;
		}

		return previousItemIndex;
	}

	/**
	 * _selectItemAndFireEvent - if an item is selected active by pagination, an event must be fired
	 * @param {number} newItemIndex_ - index of new item in availableItems !!!! (NOT in this.items!)
	 */
	_selectItemAndFireEvent(newItemIndex_) {
		const item = this.availableItems[newItemIndex_];
		const carModelId = item.getAttribute(OndemandConfigurator.ATTRIBUTE_CARMODEL_ID);
		const itemIsNotChosen = !item.classList.contains(Pagination.CLASS_ITEM_ACTIVE);

		if (itemIsNotChosen) {
			this._selectItemByCarModelId(carModelId);
			this._fireModelSelectEvent();
		}
	}

	/**
	 * @param {HTMLElement} button_ - button to set inactive
	 */
	_setButtonAsInactive(button_) {
		button_.disabled = true;
	}

	/**
	 * @param {HTMLElement} button_ - button to set active
	 */
	_setButtonAsActive(button_) {
		button_.disabled = false;
	}

	/**
	 * _selectItemByCarModelId - select a single page item (entry in list)
	 * @param {string} carModelId_ - model id
	 */
	_selectItemByCarModelId(carModelId_) {
		this._setItemActiveClassByCarModelId(carModelId_);
		this._updateActiveItemIndex();
		this._setButtonStates();
		this._centerSelectedItem();
	}

	/**
	 * @param {string} carModelId_ - model id
	 */
	_getIndexOfCarModelInItems(carModelId_) {
		let index = 0;

		if (this.carModels[carModelId_] && this.carModels[carModelId_].originalIndex) {
			index = this.carModels[carModelId_].originalIndex;
		}

		return index;
	}

	/**
	 * @param {number} index_ - page item index
	 */
	_updateActiveItemIndex(index_) {
		let index = index_;

		if (!index) {
			const activeItem = this.bodyWrapper.querySelector('.' + Pagination.CLASS_ITEM_ACTIVE);
			let carModelId;

			if (activeItem) {
				carModelId = activeItem.getAttribute(OndemandConfigurator.ATTRIBUTE_CARMODEL_ID);
				index = this.carModels[carModelId].availableIndex;
			}
		}

		if (index >= 0) {
			this.activeItemIndex = index;
		}
		else {
			this.activeItemIndex = 0;
		}
	}

	/**
	 * @param {string} carModelId_ - car model id
	 */
	_setItemActiveClassByCarModelId(carModelId_) {
		const activeItem = this.bodyWrapper.querySelector('.' + Pagination.CLASS_ITEM_ACTIVE);
		const indexInItems = this._getIndexOfCarModelInItems(carModelId_);

		if (activeItem) {
			activeItem.classList.remove(Pagination.CLASS_ITEM_ACTIVE);
		}

		this.items[indexInItems].classList.add(Pagination.CLASS_ITEM_ACTIVE);
	}

	_setButtonStates() {
		if (this._isFirstItemSelected()) {
			this._setButtonAsInactive(this.buttonBackward);
		}
		else {
			this._setButtonAsActive(this.buttonBackward);
		}

		if (this._isLastItemSelected()) {
			this._setButtonAsInactive(this.buttonForward);
		}
		else {
			this._setButtonAsActive(this.buttonForward);
		}
	}

	_centerSelectedItem() {
		const itemList = this.bodyWrapper.querySelector('.axs-ondemand-configurator__pagination-list');
		const paginationCenter = this.bodyWrapper.querySelector('.axs-ondemand-configurator__pagination-inner-wrapper').clientWidth/2;
		let diffBetweenCenters = 0;

		if (this.activeItemIndex >= 0 && this.activeItemIndex < this.availableItems.length) {
			const activeItem = this.availableItems[this.activeItemIndex];
			const itemCenter = activeItem.offsetLeft + activeItem.clientWidth/2;

			diffBetweenCenters = paginationCenter - itemCenter;
			itemList.style.left = diffBetweenCenters + 'px';
		}
	}

	_fireModelSelectEvent() {
		const selectedItem = this.querySelector('.' + Pagination.CLASS_ITEM_ACTIVE);
		const modelSelectEvent = new CustomEvent(OndemandConfigurator.EVENT_MODEL_SELECTED, {
			detail: this._getEventDetailsForItem(selectedItem)
		});

		this.configuratorOndemand.dispatchEvent(modelSelectEvent);
	}

	/**
	 * @returns {boolean} - is first item (page entry) selected (true) or not (false)
	 */
	_isFirstItemSelected() {
		return (this.activeItemIndex === 0);
	}

	/**
	 * @returns {boolean} - is last item (page entry) selected (true) or not (false)
	 */
	_isLastItemSelected() {
		return (this.activeItemIndex === this.availableItems.length - 1);
	}
}

if (window.customElements.get('audi-ondemand-configurator-pagination') === undefined) {
	window.customElements.define('audi-ondemand-configurator-pagination', Pagination);
}
