var exports, __;

__ = {};
exports = {
	__
};

/*
	* Gets the base url from configuration, adds a path to it and returns
	* the result.
	*/
__.getUrl = function (path) {
	var baseUrl;

	baseUrl = __.config.DealerSearchBaseURL +
		__.config.DealerSearchMarketAppParameter + '/';
	return baseUrl + path;
};

/*
	* this is the url for the first data fetch
	*/
__.getCachedUrl = function () {
	// if(__.config.DealerSearchURLforFetchingAllDealers.indexOf('//') === -1){

	// 	__.config.DealerSearchURLforFetchingAllDealers = __.config.DealerSearchBaseURL + __.config.DealerSearchURLforFetchingAllDealers;
	// }
	return __.config.DealerSearchURLforFetchingAllDealers;
};

/**
 * Returns a list of all partners.
 * @returns {Promise} - The Partner data.
 */
exports.getAllPartners = function () {
	const url = __.getCachedUrl();

	return fetch(url, {
		method: 'get',
		credentials: 'omit'
	}).then((response_) => {
		if (response_.ok) {
			try {
				return response_.json();
			}
			catch (err) {
				return Promise.reject(err);
			}
		}

		return Promise.reject({status: response_.status, statusText: response_.statusText});
	}).catch((error_) => {
		console.error('dsh api call failed:', error_);
	});
};

__.getClientId = function () {
	return '31371de4e8';
};

/**
 * @param {string} query_ - search query
 * @returns {Promise} - The Partner data.
 */
exports.searchPartnersByCity = function (query_) {
	const url = __.getUrl('city?q=' + encodeURIComponent(query_) + '&clientId=' + __.getClientId());

	return fetch(url, {
		method: 'get',
		credentials: 'omit'
	}).then((response_) => {
		if (response_.ok) {
			try {
				return response_.json();
			}
			catch (err) {
				return Promise.reject(err);
			}
		}

		return Promise.reject({status: response_.status, statusText: response_.statusText});
	}).catch((error_) => {
		console.error('dsh api call failed:', error_);
	});
};

__.sortPartnersByDistance = function (partners) {
	return partners.sort((a, b) => {
		return b.distance - a.distance;
	});
};

/*
 * Generates city name suggestions for a given input.
 * @param term
 * @returns {Object} A Promise.
 */
exports.getCityNameSuggestions = function (term, countryCode) {
	var termIsZipCode, placeTypes,
		suggestions, i,
		displayText;

	placeTypes = ['(cities)'];
	// Check whether the search term is a zip code
	// (i.e. if all chars are numbers).
	termIsZipCode = !isNaN(Number(term));

	// If the term is a zipcode, don't define a place type.
	if (termIsZipCode) {
		placeTypes = [];
	}

	// Initialize service if neccessary.
	if (!__.autoCompleteService) {
		__.autoCompleteService = new google.maps.places.AutocompleteService();
	}

	return new Promise((resolve, reject) => {
		// call
		__.autoCompleteService.getPlacePredictions({
			input: term,
			componentRestrictions: {
				country: countryCode
			},
			types: placeTypes
		}, (results, status) => {
			if (status === 'OK') {
				suggestions = [];

				for (i = results.length - 1; i >= 0; i--) {
					displayText = results[i].terms[0].value;

					// If search term was a zip code, display both
					// zip code and city.
					if (termIsZipCode) {
						displayText = results[i].terms[0].value + ' ' +
							results[i].terms[1].value;
					}

					suggestions.unshift(displayText);
				}

				resolve(suggestions);
			}
			else {
				reject(new Error('Could not retrieve address suggestions: ' + status));
			}
		});
	});
};

exports.getDealerSuggestions = function (term) {
	var dealerData, results, i, p, index, sortFn;

	dealerData = __.data;
	results = [];

	sortFn = function (a, b) {
		var indexA, indexB;

		indexA = a.name.toLowerCase().indexOf(term.toLowerCase());
		indexB = b.name.toLowerCase().indexOf(term.toLowerCase());

		if (indexA !== indexB) {
			return indexA - indexB;
		}

		return a.name <= b.name.toLowerCase;
	};

	return new Promise((resolve, reject) => {
		if (!dealerData) {
			reject(new Error('no data available'));
		}
		else {
			for (i = dealerData.partners.length - 1; i >= 0; i--) {
				p = dealerData.partners[i];
				index = p.name.toLowerCase().indexOf(term.toLowerCase());

				if (index !== -1) {
					results.push(p);
				}
			}

			resolve(results.sort(sortFn).slice(0, 5));
		}
	});
};

exports.getItemByKvpsId = function (kvpsid) {
	if (!__.data) {
		return undefined;
	}

	const {partners} = __.data;

	for (let i = partners.length - 1; i >= 0; i--) {
		if (partners[i].kvpsid === kvpsid) {
			return partners[i];
		}
	}
};

exports.setConfig = function (config) {
	__.config = config;
};

export {exports as dshdata};
