import {dom} from 'core-utils';

var __ = {}, exports = {__};

__.oDefaults = {
	'selectorMain': '.axs-md-faq',
	'selectorActiveTab': '.axs-faq-input.show + .axs-faq-tab',
	'selectorTabIndicator': '.axs-faq-tab-indicator'
};

__.onFaqChange = function(event_) {
	var faq = event_.target, checkedState = faq.checked,
		parentElement = dom.closest(faq, __.oDefaults.selectorMain),
		faqArray = dom.getElementsArray('.axs-faq-input', parentElement),
		i, activeTab, indicator;

	for (i = 0; i < faqArray.length; i++) {
		faqArray[i].checked = false;
		faqArray[i].classList.remove('show');
	}

	faq.checked = checkedState;
	faq.classList.add('show');

	if (window.innerWidth >= 768) {
		activeTab = dom.getElement(__.oDefaults.selectorActiveTab, parentElement);
		indicator = dom.getElement(__.oDefaults.selectorTabIndicator, parentElement);
		__.setIndicatorWidthAndOffset(indicator, activeTab);
	}
};

/**
 * onResize - handler called on window resize
 * needed for the current tab indicator, which resizes to the width of active tab
 * on mobile tabs are 100% width -> resize to desktop would break the size
 */
__.onResize = function() {
	var faqs, activeTab, lastTab, indicator;

	// do nothing on mobile, indicator is hidden
	if (window.innerWidth < 768) {
		return;
	}

	faqs = dom.getElementsArray(__.oDefaults.selectorMain);
	faqs.forEach((faq_) => {
		activeTab = dom.getElement(__.oDefaults.selectorActiveTab, faq_);
		indicator = dom.getElement(__.oDefaults.selectorTabIndicator, faq_);
		indicator.classList.remove('no-animation');
		// no animation for multi-row navigation - check on last tab-element should be enough
		lastTab = dom.getElementsArray('.axs-faq-tab', faq_).pop();

		if (lastTab && lastTab.offsetTop > 0) {
			indicator.classList.add('no-animation');
		}

		__.setIndicatorWidthAndOffset(indicator, activeTab);
	});
};

/**
 * setIndicatorWidthAndOffset - resizes and moves the indicator
 * @param {HTMLElement} indicator_ - the indicator
 * @param {HTMLElement} activeTab_ - the active tab
 */
__.setIndicatorWidthAndOffset = function(indicator_, activeTab_) {
	indicator_.style.left = activeTab_.offsetLeft + 'px';
	indicator_.style.marginTop = activeTab_.offsetTop + 'px';
	indicator_.style.width = activeTab_.clientWidth + 'px';
};

/**
 * init
 */
__.init = function() {
	dom.getEventDelegate('body').on('change', __.oDefaults.selectorMain + ' .axs-faq-input', __.onFaqChange);
	__.onResize();

	window.addEventListener('resize', __.onResize);
	window.addEventListener('load', __.onResize); // called on load again because of slow font loading
};

/**
 * public initialize method
 * @param {EventBus} globalEventBus - globalEventBus
 * @returns {Promise} returns Promise
 */
exports.initialize = function(globalEventBus) {
	return new Promise(resolve => {
		__.globalEventBus = globalEventBus;
		__.init();
		resolve('faq.js');
	});
};

export {exports as faq};
