const template = `
<div class="map-container">

	<main class="map-main">

		<div class="map-map"></div>

		<nav class="map-controls">
			<button id="map-zoom-in" title="Zoom in"><span class="zoom-in">+</span></button>
			<button id="map-zoom-out" title="Zoom out"><span class="zoom-out">–</span></button>
		</nav>
		<div class="map-partner-details"></div>

	</main>
</div>
`;

export {template as mapContainerTemplate};
