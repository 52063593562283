import {OffCanvas} from 'global-bundle';

export default class HubSearchOffCanvas {

	static get SELECTOR_MOBILE_CANVAS() { return '.axs-module-hub-search-off-canvas-mobile'; }
	static get SELECTOR_DESKTOP_CANVAS() { return '.axs-module-hub-search-off-canvas-desktop'; }

	constructor(container_) {
		this.parentContainer = container_;
		this.offCanvasInstance = undefined;
	}

	/**
	* initCanvasForMobile (public)
	*/
	initCanvasForMobile() {
		const mobileOffCanvas = this.parentContainer.querySelector(HubSearchOffCanvas.SELECTOR_MOBILE_CANVAS);

		this._initCanvas(mobileOffCanvas);
	}

	/**
	* initCanvasForDesktop (public)
	*/
	initCanvasForDesktop() {
		const desktopOffCanvas = this.parentContainer.querySelector(HubSearchOffCanvas.SELECTOR_DESKTOP_CANVAS);

		this._initCanvas(desktopOffCanvas);
	}

	_initCanvas(canvasContainer_) {
		if (this.offCanvasInstance !== undefined) {
			this.offCanvasInstance._destroyInstance();
		}

		this.offCanvasInstance = new OffCanvas(canvasContainer_);
	}

	/**
	* toggleForward
	*/
	toggleForward() {
		this.offCanvasInstance.toggleForward();
	}

	/**
	* toggleBackward
	*/
	toggleBackward() {
		this.offCanvasInstance.toggleBackward();
	}

	/**
	* toggleToLast
	*/
	toggleToLast() {
		this.offCanvasInstance.toggleToLastPanel();
	}
}
