import IconTextCondition from '../icon-text-condition/icon-text-condition';

export default class IconTextHighlight extends IconTextCondition {
	static get CLASS_MODULE_ANIMATED() { return 'axs-icon-text-highlight--with-animation'; }
	static get CLASS_ITEM() { return 'axs-icon-text-highlight__item'; }
}

if (window.customElements.get('audi-axs-icon-text-highlight') === undefined) {
	window.customElements.define('audi-axs-icon-text-highlight', IconTextHighlight);
}
