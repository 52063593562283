export default class Select {

	/**
	 * constructor
	 * @param {string} dropdown_ - parent dropdown
	 * @param {HTMLElement} selectElement_ - select
	 */
	constructor(dropdown_, selectElement_) {
		this.dropdown = dropdown_;
		this.selectElement = selectElement_;
		this.onSelectionChange = this._onSelectionChange.bind(this);

		this._addEvents();
	}

	_addEvents() {
		this.selectElement.addEventListener('change', this.onSelectionChange);
	}

	_onSelectionChange() {
		let selectedItemValues = {};
		let selectedOption = this.selectElement.options[this.selectElement.options.selectedIndex];

		selectedItemValues.label = selectedOption.text;
		selectedItemValues.value = selectedOption.value;
		this.dropdown._informModelfilterAboutSelectedValue(selectedItemValues);
	}
}
