export default class CountrySelectorMobileCTA {

	constructor(countrySelector_) {
		this.callToActionMobile = countrySelector_.querySelectorAll('.axs-module-country-selector-cta-standalone');
		this.callToActionMobileWrapper = countrySelector_.querySelector('.axs-module-country-selector-cta-standalone-wrapper');
	}

	/**
	* activates the Call To Action in the mobile view
	* @param {string} country_ - the selected country - to be added to button label
	*/
	activateForCountry(country_) {
		this._setElementState(this.callToActionMobileWrapper, 'active');
		this._updateVisibility(country_);
	}

	/**
	* deactivates the Call To Action in the mobile view
	*/
	deactivate() {
		this._resetVisibility();
		this._setElementState(this.callToActionMobileWrapper, 'inactive');
	}

	/**
	* hide only mobile CTA Buttons not belonging to the selected country
	* for IE11 Compatibility the disabled attribute is also used
	* @param {string} selectedCountry_ - the selected country
	*/
	_updateVisibility(selectedCountry_) {
		let belongingCountry;

		this._resetVisibility();
		Array.from(this.callToActionMobile).forEach(button => {
			belongingCountry = button.getAttribute('data-country');

			if (belongingCountry === selectedCountry_) {
				this._setElementVisibility(button, 'visible');
				button.removeAttribute('disabled');
			}
		});
	}

	/**
	* hide all mobile CTA Buttons
	* for IE11 Compatibility the disabled attribute is also used
	*/
	_resetVisibility() {
		Array.from(this.callToActionMobile).forEach(button => {
			this._setElementVisibility(button, 'hidden');
			button.setAttribute('disabled', true);
		});
	}

	/**
	* sets/updates the data-visibility attribute value of a given element
	* @param {HTMLElement} element_ - the DOM element to change the visibility of
	* @param {string} visibility_ - the value to set for the visibility-attribute
	*/
	_setElementVisibility(element_, visibility_) {
		element_.setAttribute('data-visibility', visibility_);
	}

	/**
	* sets/updates the data-state attribute value of a given element
	* @param {HTMLElement} element_ - the DOM element to change the state of
	* @param {string} state_ - the value to set for the state-attribute
	*/
	_setElementState(element_, state_) {
		element_.setAttribute('data-state', state_);
	}

}
