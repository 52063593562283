const iCON_BASE_URL = () => `${SETUPS.get('DocrootAssets')}/icons/svg/map/`;


const commonMainMarkerEvents = {
	mouseover: {
		cssClass: 'axs-module-hub-search-marker-mouseover'
	},
	mouseout: {
		cssClass: 'axs-module-hub-search-marker-mouseout'
	}
};

// Used on the Main map, displayed on both desktop and mobile
let getMainMarkerStyle = () => {
	return {
		'private': {
			icon: iCON_BASE_URL() + 'poi-small-color.svg',
			events: commonMainMarkerEvents
		},
		'public': {
			icon: iCON_BASE_URL() + 'poi-small-black-filled.svg',
			events: commonMainMarkerEvents
		}
	};
};

// This style is used on the detailed map in mobile AND also it is used in desktop for the marker on the main map when only one marker is displayed - desktop detailed view
let getDetailedMarkerStyle = () => {
	return {
		'private': {
			icon: iCON_BASE_URL() + 'poi-large-color.svg',
			events: false
		},
		'public': {
			icon: iCON_BASE_URL() + 'poi-large-black-filled.svg',
			events: false
		}
	};
};

export {
	getMainMarkerStyle,
	getDetailedMarkerStyle
};
