import {dom} from 'core-utils';

var __ = {},
	exports = {__};

__.oDefaults = {
	'selectorMain': '.axs-md-packageinformation',
	'selectorPiDetails1': '.axs-md-pi-details-1',
	'selectorPiDetails2': '.axs-md-pi-details-2'
};

/**
 * showPackage
 * @param {tbd} index - tbd
 * @param {tbd} packageInfoTableId - tbd
 */
__.showPackage = function(index, packageInfoTableId) {
	dom.getElement('.axs-md-packageinformation-' + index + packageInfoTableId).style.visibility = 'visible';
};

/**
 * hidePackage
 * @param {tbd} index - tbd
 * @param {tbd} packageInfoTableId - tbd
 */
__.hidePackage = function(index, packageInfoTableId) {
	dom.getElement('.axs-md-packageinformation-' + index + packageInfoTableId).style.visibility='hidden';
};

/**
 * showPackageDetails
 * @param {tbd} index - tbd
 * @param {tbd} basicsTableId - tbd
 */
__.showPackageDetails = function(index, basicsTableId) {
	dom.getElement('.axs-md-pi-basics-radio-' + index + basicsTableId).checked = true;
};

/**
 * hidePackageDetails
 * @param {tbd} index - tbd
 * @param {tbd} basicsTableId - tbd
 */
__.hidePackageDetails = function(index, basicsTableId) {
	dom.getElement('.axs-md-pi-basics-radio-' + index + basicsTableId).checked = false;
};

/**
 * Calculates the index of the elem with given offset.
 *
 */ // eslint-disable jsdoc/require-returns
__.addEvents = function() { // eslint-disable-line max-statements
	var getPackageIndex = function(index, offset) {
		var nextIndex = (index + offset) % 3;

		return nextIndex === 0 ? 3 : nextIndex;
	};

	var domDelegate = dom.getEventDelegate('body');
	var showBorderFunction;
	var showBorderFunction1;
	var showBorderFunction2;
	var showBorderFunction3;

	showBorderFunction1 = function() {
		return showBorderFunction(1, this.getAttribute('data-tableid'));//eslint-disable-line
	};

	showBorderFunction2 = () => {
		return showBorderFunction(2, this.getAttribute('data-tableid'));//eslint-disable-line
	};

	showBorderFunction3 = function() {
		return showBorderFunction(3, this.getAttribute('data-tableid'));//eslint-disable-line
	};

	showBorderFunction = function(index, tableId) {
		var packageInfoTableId = '.axs-md-packageinformation-' + tableId,
			basicsTableId = '.axs-md-pi-basics-radio-' + tableId;

		if (window.outerWidth > 1024) {

			// add border to selected element
			__.showPackageDetails(index, basicsTableId);

			// remove border from other details elements
			__.hidePackageDetails(getPackageIndex(index, 1), basicsTableId);
			__.showPackage(getPackageIndex(index, 1), packageInfoTableId);

			__.hidePackageDetails(getPackageIndex(index, 2), basicsTableId);
			__.showPackage(getPackageIndex(index, 2), packageInfoTableId);

			dom.getElement('.axs-md-packageinformation-' + index + packageInfoTableId).style['border-right']='none';


			if (index === 1) {
				__.hidePackage(2, packageInfoTableId);
			}

			if (index === 2) {
				__.hidePackage(3, packageInfoTableId);
			}

			if (index === 3) {
				__.hidePackage(2, packageInfoTableId);
			}

		}
	};

	domDelegate.on('mouseover', '.axs-md-pi-basics-1', showBorderFunction1);
	domDelegate.on('mouseover', '.axs-md-pi-basics-2', showBorderFunction2);
	domDelegate.on('mouseover', '.axs-md-pi-basics-3', showBorderFunction3);

	// Make other package informations visible when details is closed
	domDelegate.on('change', '.axs-md-pi-details-radio-1', function() {
		if (window.outerWidth > 1024) {
			var packageInfoTableId = '.axs-md-packageinformation-' + this.getAttribute('data-tableid');//eslint-disable-line

			__.showPackage(2, packageInfoTableId);
			__.showPackage(3, packageInfoTableId);
		}
	});

	domDelegate.on('change', '.axs-md-pi-details-radio-2', function() {
		if (window.outerWidth > 1024) {
			var packageInfoTableId = '.axs-md-packageinformation-' + this.getAttribute('data-tableid');//eslint-disable-line

			__.showPackage(1, packageInfoTableId);
			__.showPackage(3, packageInfoTableId);
		}
	});

	domDelegate.on('change', '.axs-md-pi-details-radio-3', function() {
		if (window.outerWidth > 1024) {
			var packageInfoTableId = '.axs-md-packageinformation-' + this.getAttribute('data-tableid');//eslint-disable-line

			__.showPackage(1, packageInfoTableId);
			__.showPackage(2, packageInfoTableId);
		}
	});

};

__.init = function() {
	if (dom.isElement(__.oDefaults.selectorMain)) {
		__.addEvents();
	}
};

/**
 * public initialize method
 * @param {EventBus} globalEventBus - globalEventBus
 * @returns {Promise} returns Promise
 */
exports.initialize = function(globalEventBus) {
	return new Promise(resolve => {
		__.globalEventBus = globalEventBus;
		__.init();

		resolve('packageinformation.js');
	});
};

export {exports as packageinformation};
