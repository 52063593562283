import {dom} from 'core-utils';

var __ = {},
	exports = {__};

__.oDefaults = {
	'allFiltersSelector': 'input[class="axs-md-modelfinder-flat-filter"]',
	'allCheckedFilters': 'input[class="axs-md-modelfinder-flat-filter"]:checked',
	'allCarModels': '.axs-md-modelfinder-flat-model',
	'sortByPriceUp': '#sortbypriceup',
	'sortByPriceDown': '#sortbypricedown',
	'sortByPriceLabel': '.axs-md-modelfinder-flat-sortbyprice-label',
	'selectorConfiguratorFlat': '.axs-md-configurator-flat',
	'selectorClassPreviousArrow': '.axs-md-ondemand-paginator-previous',
	'selectorClassNextArrow': '.axs-md-ondemand-paginator-next',
	'selectorClassNumModel': '.axs-md-ondemand-numModel',
	'selectorClassSumModel': '.axs-md-ondemand-sumModel',
	'selectorInfoWrapper': '.axs-md-ondemand-innerInfoWrapper',
	'selectorFootbarWrapper': '.axs-md-ondemand-footbarInnerWrapper',
	'selectorPictureWrapper': '.axs-md-ondemand-carimagesInnerWrapper',
	'selectorThumbnailsWrapper': '.axs-md-ondemand-carThumbnailsInnerWrapper',
	'selectorPictureThumbnail': '.axs-md-ondemand-carthumbnail-img',
	'selectorPicture': '.axs-md-ondemand-carpic-img',
	'selectorCarInformation': '.axs-md-ondemand-carinformation',
	'selectorCarImage': '.axs-md-ondemand-carimages',
	'classHideElement': 'axs-md-ondemand-configurator-display-none'
};

__.currentIndex = 0;
__.visibleCarsArray = [];

/**
 * slideNextElement
 * @param {string} selector - css selector
 */
__.slideNextElement = function(selector) {
	var element = dom.getElement(selector),
		{marginLeft} = element.style;

	marginLeft = marginLeft ? marginLeft : '0%';
	marginLeft = parseInt(marginLeft.replace('%', ''), 10);
	element.style.marginLeft = (marginLeft - 100) + '%';
};

/**
 * slidePreviousElement
 * @param {string} selector - css selector
 */
__.slidePreviousElement = function(selector) {
	var element = dom.getElement(selector),
		{marginLeft} = element.style;

	marginLeft = marginLeft ? marginLeft : '0%';
	marginLeft = parseInt(marginLeft.replace('%', ''), 10);
	element.style.marginLeft = (marginLeft + 100) + '%';
};

/**
 * slideToLastElement
 * @param {string} selector - tbd
 * @param {number} totalNumberOfElem - number of all elements
 */
__.slideToLastElement = function(selector, totalNumberOfElem) {
	dom.getElement(selector).style.marginLeft = -(totalNumberOfElem - 1) * 100 + '%';
};

/**
 * slideToFirstElement
 * @param {string} selector - tbd
 */
__.slideToFirstElement = function(selector) {
	dom.getElement(selector).style.marginLeft = '0%';
};

/**
 * slideToElement
 * @param {string} selector - css selector
 * @param {number} index - overall index of the item
 */
__.slideToElement = function(selector, index) {
	dom.getElement(selector).style.marginLeft = (-(index - 1) * 100) + '%';
};

/**
 * goToPriorityCar
 */
__.goToPriorityCar = function() {
	var idx, index=0;
	var priorityCar = dom.getElement('#prio-car');

	if (dom.isElement(priorityCar)) {
		idx = parseInt(priorityCar.getAttribute('data-index'), 10);
		// idx = parseInt(priorityCar.dataset.index,10); old version, doesnt work for IE Browsers. SOLUTION: getAttribute('data-xxx')
		__.goToCar(idx);
		index = __.getVisibleIndex(idx)-1;
	}

	__.updateIndex(index, __.visibleCarsArray.length);
};

/**
 * goToCar
 * @param {numver} index -  overall index of the element
 */
__.goToCar = function(index) {
	__.slideToElement(__.oDefaults.selectorInfoWrapper, index);
	__.slideToElement(__.oDefaults.selectorFootbarWrapper, index);
	__.slideToElement(__.oDefaults.selectorPictureWrapper, index);
	__.slideToElement(__.oDefaults.selectorThumbnailsWrapper, index);
	__.hideAllCarInformations();
	__.displayCarInformation(index, true);
	__.hideAllCarImages();
	__.displayCarImage(index, true);
};

/**
 * animate Car and detail information
 * @param {number} index - overall index of the car
 * @param {number} previousIndex - index (overall) of the previously selected car
 * @param {number} maxIndex - max overall index
 */
__.animateCar = function(index, previousIndex, maxIndex) { // eslint-disable-line max-statements
	__.hideAllCarInformations();
	__.hideAllCarImages();

	// skip from last to first
	if (index === 1 && previousIndex === maxIndex) {
		__.slideToFirstElement(__.oDefaults.selectorInfoWrapper);
		__.slideToFirstElement(__.oDefaults.selectorFootbarWrapper);
		__.slideToFirstElement(__.oDefaults.selectorPictureWrapper);
		__.slideToFirstElement(__.oDefaults.selectorThumbnailsWrapper);
		dom.getElement(__.oDefaults.selectorPictureWrapper).style.transition = '1s';
	}

	// skip from first to last
	if (index === maxIndex && previousIndex === 1) {
		__.slideToLastElement(__.oDefaults.selectorInfoWrapper, maxIndex);
		__.slideToLastElement(__.oDefaults.selectorFootbarWrapper, maxIndex);
		__.slideToLastElement(__.oDefaults.selectorPictureWrapper, maxIndex);
		__.slideToLastElement(__.oDefaults.selectorThumbnailsWrapper, maxIndex);
		dom.getElement(__.oDefaults.selectorPictureWrapper).style.transition = '1s'; // item.css('height', 'XXXpx');
	}

	// goto next
	if (index > previousIndex) {
		__.slideNextElement(__.oDefaults.selectorInfoWrapper);
		__.slideNextElement(__.oDefaults.selectorFootbarWrapper);
		__.slideNextElement(__.oDefaults.selectorPictureWrapper);
		__.slideNextElement(__.oDefaults.selectorThumbnailsWrapper);
		dom.getElement(__.oDefaults.selectorPictureWrapper).style.transition = '1s';
	}

	// go to previous
	if (index < previousIndex) {
		__.slidePreviousElement(__.oDefaults.selectorInfoWrapper);
		__.slidePreviousElement(__.oDefaults.selectorFootbarWrapper);
		__.slidePreviousElement(__.oDefaults.selectorPictureWrapper);
		__.slidePreviousElement(__.oDefaults.selectorThumbnailsWrapper);
		dom.getElement(__.oDefaults.selectorPictureWrapper).style.transition = '1s';
	}

	// show selected car
	__.goToCar(index);
	__.displayCarInformation(index, true);
	__.displayCarImage(index, true);
};

/**
 * get an array with all car elements (HTMLElement) fro the DOM
 * @returns {Array} array of all cars
 */
__.getAllCars = function() {
	return dom.getElementsArray('.axs-md-ondemand-carimagesInnerWrapper .axs-md-ondemand-carimages');
};

/**
 * get an array with all car elements (HTMLElement) that are currently active via filter
 * @returns {Array} array of all cars
 */
__.getVisibleCarsOnDemand = function() {
	var visibleCars = __.getAllCars().filter((car_) => {
		return !car_.classList.contains('filtered');
	});

	return visibleCars;
};

/**
 * displayCarInformation
 * @param {tbd} index - tbd
 * @param {tbd} display - tbd
 */
__.displayCarInformation = function(index, display) {
	var element = dom.getElement(__.oDefaults.selectorCarInformation + '-' + index);

	if (display === false) {
		element.classList.add(__.oDefaults.classHideElement);
	}
	else {
		element.classList.remove(__.oDefaults.classHideElement);
	}
};

/**
 * displayCarImage
 * @param {tbd} index - tbd
 * @param {tbd} display - tbd
 */
__.displayCarImage = function(index, display) {
	var element = dom.getElement(__.oDefaults.selectorCarImage + '-' + index);

	if (display === false) {
		element.classList.add(__.oDefaults.classHideElement);
	}
	else {
		element.classList.remove(__.oDefaults.classHideElement);
	}
};

__.getTotalNumberOfElements = function() {
	return __.getAllCars().length;
};

__.getTotalNumberOfVisibleElements = function() {
	return __.getVisibleCarsOnDemand().length;
};

/**
 * hideAllCarInformations
 */
__.hideAllCarInformations = function() {
	var i;
	var totalNumberOfElem = __.getTotalNumberOfElements();

	for (i = 1; i <= totalNumberOfElem; i++) {
		__.displayCarInformation(i, false);
	}
};

/**
 * hideAllCarImages
 */
__.hideAllCarImages = function() {
	var i, totalNumberOfElem = __.getTotalNumberOfElements();

	for (i = 1; i <= totalNumberOfElem; i++) {
		__.displayCarImage(i, false);
	}
};

__.renderPage = function() {
	__.numberOfCars = __.getTotalNumberOfElements();
	// store number of cars and update index counter
	__.visibleCarsArray=__.getVisibleCarsOnDemand();
	// show priority car
	__.goToPriorityCar();
};

// handle prev next arrow clicks
__.handlePrevNextClick = function(event) {
	var index, newSelection, nextSlotIndex, previousIndex, visibleIndex;

	__.currentIndex = __.getPreviousSelectedIndex();
	visibleIndex=__.getVisibleIndex(__.currentIndex);

	// handle next click
	if (event.target.classList.contains('axs-md-ondemand-paginator-next')) {
		index = __.getNewIndex('next', visibleIndex-1, __.visibleCarsArray.length);
	}
	// handle prev click
	else {
		index = __.getNewIndex('prev', visibleIndex-1, __.visibleCarsArray.length);
	}

	// store previously selected car index
	previousIndex = __.currentIndex;
	newSelection = __.visibleCarsArray[index];
	nextSlotIndex = parseInt(newSelection.getAttribute('data-index'), 10);
	__.animateCar(nextSlotIndex, previousIndex, __.numberOfCars);
	__.updateIndex(index, __.visibleCarsArray.length);
	__.carSelectionFromOnDemandConfigurator(nextSlotIndex);
};

// get the Index of the previously selected car before animation to the new index
__.getPreviousSelectedIndex = function() {
	var prevIndex = dom.getElement('[class^="axs-md-ondemand-carimages-"]:not(.axs-md-ondemand-configurator-display-none)').parentNode.getAttribute('data-index');

	prevIndex = parseInt(prevIndex, 10) || 1;
	return prevIndex;
};

// update counter (index and max)
__.updateIndex = function(index_, max) {
	var currentIndex = parseInt(index_, 10)+1;

	dom.getElement(__.oDefaults.selectorClassSumModel).innerHTML = max < 10 ? '0' + max : max;
	dom.getElement(__.oDefaults.selectorClassNumModel).innerHTML = currentIndex < 10 ? '0' + currentIndex : (currentIndex);
};

__.getNewIndex = function(prevOrNext, prevIndex_, max_) {
	var index = prevIndex_;

	if (prevOrNext === 'next') {
		index++;

		if (index >= max_) {
			index = 0;
		}
	}
	else {
		index--;

		if (index < 0) {
			index = max_ - 1;
		}
	}

	return index;
};

__.getVisibleIndex = function(index_) {
	var element = dom.getElement(__.oDefaults.selectorCarImage + '-' + index_).parentElement,
		visIndex = 0,
		i, len, carsArray = __.visibleCarsArray,
		car;

	for (i = 0, len = carsArray.length; i < len; i++) {
		car = carsArray[i];

		if (!car.classList.contains('filtered')) {
			visIndex++;
		}

		if (car === element) {
			return visIndex;
		}
	}

	return visIndex;
};

// handle event coming from configurator flat (filtering or selection)
__.updateIndexAndSelectedCarFromConfiguratorFlat = function(payload_) {
	var visibleIndex, scrollTarget, carImageTopPosition = dom.getElement('.axs-md-ondemand-carimagesWrapper').getBoundingClientRect();

	scrollTarget = (carImageTopPosition.top - 100) + window.pageYOffset;

	// scroll to detail only if car was clicked (not on filter change)
	if (payload_.selectionChange) {
		dom.scrollTo(scrollTarget, 500);
	}
	else {
		// filter cars (hide cars that do not match current filters)
		__.filterCars(payload_.filters);
	}

	__.goToCar(payload_.overallIndex);
	visibleIndex=__.getVisibleIndex(payload_.overallIndex);
	__.updateIndex(visibleIndex-1, __.visibleCarsArray.length);
};

/**
 * hide cars that do not match the current filter
 * @param {Array}filtersArray_ - array of filters
 */
__.filterCars = function(filtersArray_) {
	var carsArray = dom.getElementsArray(__.oDefaults.selectorCarImage),
		filterGroup;

	// hide cars that do not match the current filter
	carsArray.forEach((car_) => {
		filterGroup = car_.getAttribute('data-filterid');

		if (!!filtersArray_ && filtersArray_.length && filtersArray_.indexOf(filterGroup) === -1) {
			car_.classList.add('filtered');
		}
		else {
			car_.classList.remove('filtered');
		}
	});
	__.visibleCarsArray = __.getVisibleCarsOnDemand();
};

/**
 * set 'display' style of an element
 * @param {string} selector - css selector for one or more elements
 * @param {string} displayValue - valid values for 'display' ('none','block',…)
 */
__.displayHelper = function(selector, displayValue) {
	var models = document.querySelectorAll(selector);
	var i;

	for (i = 0; i < models.length; ++i) {
		models[i].style.display = displayValue;
	}
};

/**
 * hide element by its classname
 * @param {string} selectorId - classname
 */
__.hideModels = function(selectorId) {
	__.displayHelper('.' + selectorId, 'none');
};

/**
 * show element by its classname
 * @param {string} selectorId - classname
 */
__.showModels = function(selectorId) {
	__.displayHelper('.' + selectorId, 'block');
};

/* *
	* Search for all checked filters.
	* If there is no checked filter, all the models are shown
	*/
__.showAllModelsIfEmpty = function() {
	var checkedFilters = document.querySelectorAll(__.oDefaults.allCheckedFilters);

	if (checkedFilters.length === 0) {
		__.displayHelper(__.oDefaults.allCarModels, 'block');
	}
};

/* *
	* When a filter is clicked, go through all filters and show/hide models according
	* to the filter status.
	*
	* If no filter is checked, all models are shown.
	*/
__.filterHandler = function() {
	var allFilters = dom.getElementsArray(__.oDefaults.allFiltersSelector),
		selected;
	var i;

	for (i = 0; i < allFilters.length; ++i) {
		if (!allFilters[i].checked) {
			__.hideModels(allFilters[i].id);
		}
		else {
			__.showModels(allFilters[i].id);
		}
	}

	__.showAllModelsIfEmpty();
	selected = dom.getElement('.selected');
	__.setSelectedCar(selected);
	__.dispatchFilterstatus(false);
};

/*
* get the values of all currently selected filters
* @returns {array} array with active filternames (['suv','sedan'])
*/
__.getSelectedFilters = function() {
	var checkedFiltersArray = dom.getElementsArray(__.oDefaults.allCheckedFilters).map((el) => {
		return el.getAttribute('id');
	});

	return checkedFiltersArray;
};

/*
* get all curently visible cars (without the ones that are hidden by filters)
* @returns {array} array with visible cars (HTMLELements)
*/
__.getVisibleCars = function() {
	var visibleCars = dom.getElementsArray(__.oDefaults.allCarModels).filter((car) => {
		return __.isVisible(car);
	});

	return visibleCars;
};

/*
* check if car is visible or disabled by a filter
* @param {HTMLElement} el_ - car to check
* @returns {boolean} visible or not
*/
__.isVisible = function(el_) {
	return !!(el_.offsetWidth || el_.offsetHeight || el_.getClientRects().length);
};

/**
 * get currently selected carIndex (within the visible cars)
 * @param {HTMLElement} selectedCar_ - currently selected car element
 * @returns {number} index of this car within the currently visible cars
 */
__.getSelectedCarIndex = function(selectedCar_) {
	var visibleCarsArray = __.getVisibleCars();
	var index = 0;

	visibleCarsArray.forEach((car, index_) => {
		if (car === selectedCar_) {
			index = index_;
		}
	});
	return index;
};

/* trigger event with state of the current sleected car and filters */
__.dispatchFilterstatus = function(selectionChange_) {
	var visibleCars, state, selectedCar;

	visibleCars = __.getVisibleCars();
	selectedCar = dom.getElement(__.oDefaults.allCarModels + '.selected');
	state = {
		overallIndex: parseInt(selectedCar.getAttribute('data-index'), 10),
		index: __.getSelectedCarIndex(selectedCar),
		maxIndex: visibleCars.length,
		filters: __.getSelectedFilters(),
		selectionChange: selectionChange_
	};
	__.updateIndexAndSelectedCarFromConfiguratorFlat(state);
};

/*
* add change handlers to all filter items
* @returns {void} nothing
*/
__.initFiltersHandlers = function() {
	var inputFilters = document.querySelectorAll(__.oDefaults.allFiltersSelector);
	var i;

	for (i = 0; i < inputFilters.length; i++) {
		inputFilters[i].onchange = __.filterHandler;
	}
};

/**
 * sort items by price
 * @param {string} direction_ - 'asc'ending or 'desc'ending
 */ // eslint-disable jsdoc/require-returns
__.sortList = function(direction_) {
	var unsortedList = dom.getElement('.unsort'),
		tempList = document.createElement('ul'),
		carItems, dummyItems;

	// get a reference list of all list items
	carItems = dom.getElementsArray('li:not(.dummy)', unsortedList);
	dummyItems = dom.getElementsArray('li.dummy', unsortedList);

	if (direction_ === 'asc') {
		carItems.sort((a, b) => {
			return parseInt(a.getAttribute('data-price'), 10) - parseInt(b.getAttribute('data-price'), 10);
		});
	}
	else {
		carItems.sort((a, b) => {
			return parseInt(b.getAttribute('data-price'), 10) - parseInt(a.getAttribute('data-price'), 10);
		});
	}

	// add dummy items to the sorted car items and append both to the list again
	carItems.concat(dummyItems).forEach((item_) => {
		// IE11 Bugfix for appendChild (li childnodes are not appended, when ul innerHTML is cleared afterwards)
		// use temp list for appending sorted items and and then change the original list innerHTML
		tempList.appendChild(item_);
	});
	unsortedList.innerHTML = tempList.innerHTML;
};

/*
* handle Sort checkbox changes
* @returns {void} nothing
*/
__.initPriceSortHandlers = function() {
	var priceUpElement = dom.getElement(__.oDefaults.sortByPriceUp),
		priceDownElement = dom.getElement(__.oDefaults.sortByPriceDown);

	if (dom.isElement(priceDownElement) && dom.isElement(priceUpElement)) {
		// handle checkbox changes
		priceDownElement.onchange = function() {
			priceUpElement.checked = false;
			__.sortList('desc');
		};

		priceUpElement.onchange = function() {
			priceDownElement.checked = false;
			__.sortList('asc');
		};
	}
};

/**
	* handle Sort-Label clicks
	*/
__.handleSortLabelClicks = function() {
	// handle label clicks
	var priceUpElement = dom.getElement(__.oDefaults.sortByPriceUp),
		priceDownElement = dom.getElement(__.oDefaults.sortByPriceDown);

	if (priceUpElement.checked) {
		priceDownElement.checked = true;
		priceUpElement.checked = false;
		__.sortList('desc');
	}
	else {
		priceUpElement.checked = true;
		priceDownElement.checked = false;
		__.sortList('asc');
	}
};

/*
	* set selected Car
	* @param {HTMLElement} car_ - car to be selected (optional,takes the first car if no param is given)
	* @returns {void} nothing
	*/
__.setSelectedCar = function(car_) {
	var visibleCarsArray, selectedCar = car_;
	// take param or first found car

	// if no car is selected => select the first visible car
	if (!dom.isElement(selectedCar) || !__.isVisible(selectedCar)) {
		visibleCarsArray = __.getVisibleCars();
		selectedCar = visibleCarsArray[0];
	}

	// remove selected state from all cars
	dom.getElementsArray(__.oDefaults.allCarModels).forEach((car) => {
		car.classList.remove('selected');
	});
	// add selected class to the current sleected car
	selectedCar.classList.add('selected');
};

/**
	* car selection handler
	*/
__.handleCarClick = function() {
	__.setSelectedCar(this);
	__.dispatchFilterstatus(true);
};

/**
* select car by skipping through the on demand configurator
* @param {number} index_ - current index
*/
__.carSelectionFromOnDemandConfigurator = function(index_) {
	var newCar = dom.getElement('[data-index=\'' + index_ + '\']');

	__.setSelectedCar(newCar);
};

__.handleThumbnailClick = function() {
	var thumbnail = this,
		mainImage;

	dom.getElementsArray('.' + thumbnail.getAttribute('data-thumbnailgroup')).forEach((item) => {
		item.classList.remove('axs-md-ondemand-carthumbnails-border');
	}); // eslint-disable-line
	dom.getElement('#' + thumbnail.id).classList.add('axs-md-ondemand-carthumbnails-border'); // eslint-disable-line
	mainImage = dom.getElement('#' + thumbnail.getAttribute('data-id'));
	mainImage.src = thumbnail.src; // eslint-disable-line

	if (thumbnail.srcset) {
		mainImage.srcset = thumbnail.srcset; // eslint-disable-line
	}

};

__.addEvents = function() {
	var domDelegate = dom.getEventDelegate('body');

	domDelegate.on('click', __.oDefaults.sortByPriceLabel, __.handleSortLabelClicks);
	domDelegate.on('click', __.oDefaults.allCarModels, __.handleCarClick);
	domDelegate.on('click', __.oDefaults.selectorClassPreviousArrow + ',' + __.oDefaults.selectorClassNextArrow, __.handlePrevNextClick);
	domDelegate.on('click', __.oDefaults.selectorPictureThumbnail, __.handleThumbnailClick);
};

// initialization method
__.init = function() {
	if (document.querySelector(__.oDefaults.selectorConfiguratorFlat)) {
		__.addEvents();
		__.renderPage();
		__.initFiltersHandlers();
		__.initPriceSortHandlers();
		__.setSelectedCar();
	}
};

/**
* public initialize method
* @param {EventBus} globalEventBus - globalEventBus
* @returns {Promise} returns Promise
*/
exports.initialize = function(globalEventBus) {
	return new Promise(resolve => {
		__.globalEventBus = globalEventBus;
		__.init();
		resolve('configurator-flat.js');
	});
};

export {exports as configuratorflat};
