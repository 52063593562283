
export default class HubSearchOpeningHours extends HTMLElement {
	/**
	* constructor (private)
	*/
	constructor() {
		super();

		this.toggleItem = null;
		this.handleToggle = this._handleToggle.bind(this);
	}

	/**
	* connectedCallback - callback for adding element to dom/shadow dom
	*/
	connectedCallback() {
		this._init();
		this._addEvents();
	}

	_init() {
		this.isOpen = false;
		this.toggleItem = this.querySelector('.axs-module-hub-search__opening-times-toggle');
	}

	_addEvents() {
		this.toggleItem.addEventListener('click', this.handleToggle);
	}

	_handleToggle() {
		this.isOpen = !this.isOpen;
	}

	get isOpen() {
		return this.getAttribute('is-open') === 'true';
	}
	set isOpen(val) {
		this.setAttribute('is-open', val);
	}
}

if (window.customElements.get('axs-module-hub-search-opening-times') === undefined) {
	customElements.define('axs-module-hub-search-opening-times', HubSearchOpeningHours);
}

