import {MapUtils} from './map-utils';
import {getMainMarkerStyle, getDetailedMarkerStyle} from './markers';

export default class HubSearchMap {

	static get ZOOM_LEVEL_STREET() { return 13; }
	static get ZOOM_LEVEL_COUNTRY() { return 5; }
	static get SELECTOR_MAP_MAIN() { return '.axs-module-hub-search-map-main'; }
	static get SELECTOR_MAP_DETAIL() { return '.axs-module-hub-search-map-detail'; }
	static get SELECTOR_ZOOM_IN_CONTROL() { return '.axs-module-hub-search-zoom-in-control'; }
	static get SELECTOR_ZOOM_OUT_CONTROL() { return '.axs-module-hub-search-zoom-out-control'; }

	/**
	* constructor (private)
	* @param {Object} container_ - parent container(= hub-search)
	*/
	constructor(container_) {
		this.parentContainer = container_;
		this.map = null;
		this.markerIcons = getMainMarkerStyle();
		this.selectedMarker = null;
		this.markersOnMap = [];
		this.initalZoom = 0;
		this.initialCenter = null;
	}

	/**
	* initMainMap (public)
	*/
	initMainMap() {
		let mapContainer = this.parentContainer.querySelector(HubSearchMap.SELECTOR_MAP_MAIN);
		let mapOptions = this._readOutDataAttrForMapsOptions();

		if (!mapOptions.zoom) {
			mapOptions.zoom = HubSearchMap.ZOOM_LEVEL_COUNTRY;
		}

		this.map = this._createMapInstance(mapContainer, mapOptions);
		this.initMarkersStyle(getMainMarkerStyle());
		this._initZoomControls();
		return this.map;
	}

	/**
	 * @param {HTMLElement} mapContainer_ - container div
	 * @param {Object} mapOptions_ - map options
	 * @returns {MapUtils} map object
	*/
	_createMapInstance(mapContainer_, mapOptions_) {
		return new MapUtils(mapContainer_, mapOptions_);
	}

	/**
	* _initZoomControls - bind eventhandlers for zoom controls, handle hover effekt
	*/
	_initZoomControls() {
		let zoomInControl = this.parentContainer.querySelector(HubSearchMap.SELECTOR_ZOOM_IN_CONTROL);
		let zoomOutControl = this.parentContainer.querySelector(HubSearchMap.SELECTOR_ZOOM_OUT_CONTROL);
		let zoomInControlID;
		let zoomOutControlID;

		if (zoomInControl && zoomOutControl) {
			zoomInControlID = zoomInControl.getAttribute('id');
			zoomOutControlID = zoomOutControl.getAttribute('id');
		}

		if (zoomInControlID && zoomOutControlID) {
			this.map.initZoomInAndZoomOutByIDs(zoomInControlID, zoomOutControlID);
		}
	}

	/**
	* initDetailMap (public)
	*/
	initDetailMap() {
		let mapContainer = this.parentContainer.querySelector(HubSearchMap.SELECTOR_MAP_DETAIL);
		let mapOptions = {
			center: {lat: 0, lng: 0},
			zoom: HubSearchMap.ZOOM_LEVEL_STREET
		};

		this.map = this._createMapInstance(mapContainer, mapOptions);

		this.initMarkersStyle(getDetailedMarkerStyle());

		return this.map;
	}

	/**
	* initMarkersStyle (public) - set events and icon set for markers on map
	* @param {Object} markerIcons - An object
	* @param {string} markerIcons.icon - URL
	* @param {Object} markerIcons.events - Event definition object
	*/
	initMarkersStyle(markerIcons) {
		this.map.initMarkerIcons(markerIcons);
		this._updateMarkerIcons();
	}

	/**
	* _updateMarkerIcons
	*/
	_updateMarkerIcons() {
		this.map.updateIconsForMarkersOnMap();
	}

	/**
	 *
	 * @param {number} markerIndex_ - index of marker to be changed
	 * @param {Object} markerIcons_ - possible icons
	 */
	setMarkerStyleByIndex(markerIndex_, markerIcons_) {
		let marker = this.map.getMarkerFromMapByIndex(markerIndex_);

		this.selectedMarker = marker;
		this.map.setSelectedMarker(marker);
		this.map.updateIconForMarkerOnMap(marker, markerIcons_);
	}

	resetMapHovers() {
		this.map.resetMapHovers();
	}

	/**
	* _readOutDataAttrForMapsOptions (private)
	* @returns {Object} options
	*/
	_readOutDataAttrForMapsOptions() {
		let mapContainer = this.parentContainer.querySelector(HubSearchMap.SELECTOR_MAP_MAIN);
		let options = {};
		let center;
		let lng = mapContainer.getAttribute('data-long');
		let lat = mapContainer.getAttribute('data-lat');
		let zoom = mapContainer.getAttribute('data-zoom');

		if (lng && lat && lng !== '' && lat !== '') {
			center = {lat: parseFloat(lat), lng: parseFloat(lng)};
		}

		if (zoom) {
			zoom = parseInt(zoom, 10);
		}

		options = {
			center,
			zoom
		};

		return options;
	}

	/**
	* toggleHighlightOnMarkerByMarkerIndex (public)- toggles marker highlighting, given by index
	* @param {number} markerIndex_ - index of marker that should be highlighted
	*/
	toggleHighlightOnMarkerByMarkerIndex(markerIndex_) {
		let marker = this.map.getMarkerFromMapByIndex(markerIndex_);

		this._toggleHighlightFromMarker(marker);
	}

	/**
	* _toggleHighlightFromMarker (private)
	* @param {Object} marker_ - marker
	*/
	_toggleHighlightFromMarker(marker_) {

		if (marker_ && this.markerIcons[marker_.type].events && marker_ !== this.selectedMarker) {

			const mouseOverEvent = this.markerIcons[marker_.type].events.mouseover;
			const mouseOutEvent = this.markerIcons[marker_.type].events.mouseout;
			const mouseOutCssClass = mouseOutEvent && mouseOutEvent.cssClass;
			const mouseOverCssClass = mouseOverEvent && mouseOverEvent.cssClass;
			const marker = this.parentContainer.querySelector(`[title="${marker_.title}"]`);

			if (!marker) {
				return;
			}

			const isHighlighted = marker.classList.contains(mouseOverCssClass);

			if (isHighlighted) {
				marker.classList.remove(mouseOverCssClass);
				marker.classList.add(mouseOutCssClass);
			}
			else {
				marker.classList.remove(mouseOutCssClass);
				marker.classList.add(mouseOverCssClass);
			}
		}
	}

	/**
	 * set map center to given marker index
	 * @param {number} markerIndex_ - index of the marker to center
	 */
	centerMarker(markerIndex_) {
		let marker = this.map.getMarkerFromMapByIndex(markerIndex_);

		this.map.setMapCenterToMarker(marker);
	}

	/**
	* zoomMap
	* @param {number} zoom_ - zoom level (optional)
	*/
	zoomMap(zoom_) {
		if (!zoom_) {
			if (this.initalZoom) {
				this.map.setMapZoom(this.initalZoom);
			}
		}
		else {
			this.map.setMapZoom(zoom_);
		}
	}

	/**
	* moveMap
	* @param {number} position_ - new center (optional)
	*/
	moveMap(position_) {
		if (!position_) {
			if (this.initialCenter) {
				this.map.moveMapCenter(this.initialCenter);
			}
		}
		else {
			this.map.moveMapCenter(position_);
		}
	}

	/**
	* setInitialValues - store current zoom and center position and store
	*/
	setInitialValues() {
		this.initalZoom = this.map.getMapZoom();
		this.initialCenter = this.map.getMapCenter();
	}

	/**

	* setMarkersOffHubsOnMap (public) - set Markers (get from API) on map
	* @param {Array} markers_ - list of markers that should be shown in map
	*/
	setMarkersOnMap(markers_) {
		this.markersOnMap = markers_;
		this.map.initAndRenderMarkers(markers_);
	}

	/**
	* showMarkerOnMapByIndex (public)
	* @param {number} index_ - marker index
	*/
	showMarkerOnMapByIndex(index_) {
		let markers = [];
		let marker = this.markersOnMap[index_];

		markers.push(marker);
		this.map.initAndRenderMarkers(markers);
		this.markersOnMap = markers;
	}
}
