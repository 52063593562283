import {dom, template} from 'core-utils';

import {dshdata as DSH_DATA} from './data';
import {dshmap as DSH_MAP} from './map';

import {dshContainerTemplate as CONTAINER_TPL} from './dsh-container-template';
import {dshResultlistItemTemplate as ITEM_TPL} from './dsh-resultlist-item-template';
import {dshPartnerDetailsTemplate as DETAIL_TPL} from './dsh-partner-details-template';
import {dshDataUris as DATA_URIS} from './dsh-data-uris';

var exports, __;

__ = {};
exports = {
	__
};
__.MARKET_COMMA_DECIMAL = {
	'audi-deu': true
};
__.updateLock = false;
__.updateReleaseCallback = null;
__.defaultZoomLevel = 6;
__.defaultMapCenter = '51.318928,9.49601';
__.r8FilterActive = false;
__.tronFilterActive = false;
__.usedCarsFilterActive = false;
__.dealerData = null;
__.dshContainer = null;
__.markers = [];
__.centerMarker = null;
__.resultlistEl = null;
__.dataURIs = JSON.parse(DATA_URIS);
__.defaultStyledMap = null;
__.topLevelStyledMap = null;
__.defaultCountryCode = 'de';
__.partnerDetailsSelector = '.dsh-main .dsh-partner-details';
__.config = {};
__.listScrollLocker = false;
__.markersWithinCurrentBounds = null;
__.currentCenter = null;
__.currentBounds = null;
__.updateListener = null;
__.mapLoadedListener = null;

__.readConfig = function(configJSON, distanceUnit) { // eslint-disable-line max-statements
	var config, key, zoomLevel;

	if (!__.config) {
		__.config = {};
	}

	try {
		config = JSON.parse(configJSON);
		// Set fallback for MapsDirectionsBaseUrl
		__.config.MapsDirectionsBaseUrl = 'https://maps.google.com/maps/dir';

		for (key in config) {
			if (Object.prototype.hasOwnProperty.call(config, key)) {
				__.config[key] = config[key];
			}
		}

		__.config['distanceUnit'] = distanceUnit;
		zoomLevel = __.config.DealerSearchDefaultZoomlevel || __.defaultZoomLevel;
		__.defaultZoomLevel = Number(zoomLevel);

		if (document.body.clientWidth < 750) {
			__.defaultZoomLevel -= 1;
		}

		__.defaultMapCenter = __.config.DealerSearchDefaultMapCenter || __.defaultMapCenter;
		__.config.localeLang = __.config.DealerSearchLocaleLanguage || 'en';
	}
	catch (err) {
		console.error('DSH: Could not read config', err);
	}
};

/*
	* Sets an internal lock parameter releases it after a given
	* timeout. Optionally runs a callback function providing the lock.
	* @param timeout {Number} -- Time until lock is released (in ms).
	*/
__.lock_and_release = function(timeout) {
	__.updateLock = true;
	setTimeout(() => {
		__.updateLock = false;

		if (typeof __.updateReleaseCallback === 'function') {
			__.updateReleaseCallback();
			__.updateReleaseCallback = false;
		}
	}, timeout);
};

__.sortMarkers = function(markers) {
	var i, withDistance, nameOnly, combined;

	withDistance = [];
	nameOnly = [];
	combined = [];

	for (i = markers.length - 1; i >= 0; i--) {
		if (!!markers[i].data.distance) {
			withDistance.push(markers[i]);
		}
		else {
			nameOnly.push(markers[i]);
		}
	}

	withDistance.sort((a, b) => {
		return b.data.distance - a.data.distance;
	});
	nameOnly.sort((a, b) => {
		return b.data.name > a.data.name;
	});

	for (i = withDistance.length - 1; i >= 0; i--) {
		combined.push(withDistance[i]);
	}

	for (i = nameOnly.length - 1; i >= 0; i--) {
		combined.push(nameOnly[i]);
	}

	return combined;
};

__.filterService = function(items, str) {
	var i, result = [];

	for (i = items.length - 1; i >= 0; i--) {
		if (!!items[i].data.services && items[i].data.services.toString().match(str)) {
			result.unshift(items[i]);
		}
	}

	return result;
};

__.createDistanceString = function(item) { // eslint-disable-line max-statements
	var distance, units, unitStr, itemDistance;

	units = {
		'kilometers': 'km',
		'miles': 'miles'
	};

	if (!__.config.distanceUnit) {
		unitStr = units.kilometers;
		itemDistance = item.distance;
	}
	else {
		unitStr = units[__.config.distanceUnit] || units.kilometers;

		if (!!item.address.distance) {
			itemDistance = item.address.distance[__.config.distanceUnit];
		}
		else {
			itemDistance = item.distance;
			unitStr = units.kilometers;
		}
	}

	if (!!itemDistance && itemDistance !== 'NaN') {
		distance = Number(itemDistance);

		if (distance > 10) {
			distance = String(distance.toFixed(0));
		}
		else {
			distance = String(distance.toFixed(1));
		}

		if (__.MARKET_COMMA_DECIMAL[__.config.DealerSearchMarketAppParameter] === true) {
			distance = String(distance).replace('.', ',');
		}

		item.distanceAsString = distance + ' ' + unitStr;
	}

	return item;
};

__.getExtendedBounds = function(bounds) {
	var southPoint, westPoint, northPoint, eastPoint, extendedWestPoint, extendedSouthPoint, southWest;

	southPoint = bounds.getSouthWest().lat();
	westPoint = bounds.getSouthWest().lng();
	northPoint = bounds.getNorthEast().lat();
	eastPoint = bounds.getNorthEast().lng();
	// extend bounds 10% of bounds size to the left and bottom
	extendedWestPoint = westPoint - (Math.abs(eastPoint - westPoint) / 10.0);
	extendedSouthPoint = southPoint - (Math.abs(northPoint - southPoint) / 10.0);
	southWest = new google.maps.LatLng({
		lat: extendedSouthPoint,
		lng: extendedWestPoint
	});
	return new google.maps.LatLngBounds(southWest, bounds.getNorthEast());
};

__.updateResultList = function(partners) {
	var i, itemHtml, visible, bounds, extendedBounds, item, map, position, container;

	visible = [];
	map = DSH_MAP.getMap();
	// Add short delay, because map.zoom not in sync with center_change event.
	setTimeout(() => { // eslint-disable-line max-statements
		if (map.zoom < 8) {
			// Zoom level too low. Just remove results.
			__.resultlistEl.innerHTML = '';
			__.removeClassName('dsh-results-active');
		}
		// partners given as param
		else if (!!partners) {
			__.resultlistEl.innerHTML = '';

			for (i = partners.length - 1; i >= 0; i--) {
				item = __.enhanceDealerData(partners[i]);
				item = __.createDistanceString(item);
				itemHtml = template.render(ITEM_TPL, item);
				container = document.createElement('div');
				container.innerHTML = itemHtml;
				__.resultlistEl.appendChild(container);
			}

			// className = __.dshContainer.className;
			if (partners.length > 0) {
				__.setClassName('dsh-results-active');
			}
			else {
				__.removeClassName('dsh-results-active');
			}
		}
		// use partners from map
		else {
			// Find all markers within current bounds.
			bounds = __.currentBounds;
			// we extend the bounds even a little bit in order to also hide the markers which
			// are placed outside the bounds but still shown within the bounds
			extendedBounds = __.getExtendedBounds(bounds);

			for (i = __.markers.length - 1; i >= 0; i--) {
				position = __.markers[i].getPosition();

				if (bounds.contains(position)) {
					visible.unshift(__.markers[i]);
				}
				else if (extendedBounds.contains(position)) {
					visible.unshift(__.markers[i]);
				}
			}

			visible = __.sortMarkers(visible);
			__.markersWithinCurrentBounds = visible;

			if (__.r8FilterActive) {
				visible = __.filterService(visible, 'r8');
			}

			if (__.tronFilterActive) {
				visible = __.filterService(visible, 'tron');
			}

			if (__.usedCarsFilterActive) {
				visible = __.filterService(visible, 'usedcars');
			}

			// Empty result list and add new items.
			__.resultlistEl.innerHTML = '';

			for (i = visible.length - 1; i >= 0; i--) {
				item = __.enhanceDealerData(visible[i].data);
				item = __.createDistanceString(item);
				itemHtml = template.render(ITEM_TPL, item);
				container = document.createElement('div');
				container.innerHTML = itemHtml;
				__.resultlistEl.appendChild(container);
			}

			__.addMarkerHighlightEvents(visible);

			if (visible.length > 0) {
				__.setClassName('dsh-results-active');
			}
			else {
				__.removeClassName('dsh-results-active');
				google.maps.event.trigger(map, 'resize');
			}
		}
	}, 200);
};

__.addMarkerHighlightEvents = function(markers) {
	var featureIsActive = true,
		delegate;

	if (!!window.SETUPS) {
		featureIsActive = SETUPS.get('feature.dsh.marker-hover') !== 'off';
	}

	if (!!featureIsActive) {
		delegate = dom.getEventDelegate('body');
		delegate.off('mouseover', '.dsh-resultlist-item');
		delegate.on('mouseover', '.dsh-resultlist-item', function() {
			DSH_MAP.highlightMarkers(markers, dom.closest(this, '.dsh-resultlist-item').getAttribute('data-dsh-kvpsid'));
		});
		delegate.off('mouseout', '.dsh-results');
		delegate.on('mouseout', '.dsh-results', () => {
			DSH_MAP.highlightMarkers(markers);
		});
	}
};

__.itemIsSales = function(item) {
	var i;

	if (!item.services) {
		return false;
	}

	for (i = item.services.length - 1; i >= 0; i--) {
		if (item.services[i] === 'sales') {
			return true;
		}
	}

	return false;
};

__.itemIsService = function(item) {
	var i;

	if (!item.services) {
		return false;
	}

	for (i = item.services.length - 1; i >= 0; i--) {
		if (item.services[i] === 'service') {
			return true;
		}
	}

	return false;
};

__.getMarkerIcon = function(data) {
	if (__.itemIsSales(data)) {
		data.activeIconKey = 'pin_audi_active';
		data.inactiveIconKey = 'pin_audi_inactive';
	}
	else {
		data.activeIconKey = 'pin_service_active';
		data.inactiveIconKey = 'pin_service_inactive';
	}

	return __.itemIsSales(data) ? __.dataURIs.pin_audi_active : __.dataURIs.pin_service_active;
};

__.initMarkers = function() {
	var i, latLng, marker;

	DSH_DATA.getAllPartners().then((data) => {
		// console.log("initMarkers --> data: ", data);
		__.dealerData = data;
		__.markers = [];

		for (i = __.dealerData.partners.length - 1; i >= 0; i--) {
			latLng = new google.maps.LatLng(__.dealerData.partners[i].address.latitude, __.dealerData.partners[i].address.longitude);
			marker = new google.maps.Marker({
				position: latLng,
				zIndex: 10,
				icon: {
					url: __.getMarkerIcon(__.dealerData.partners[i]),
					origin: new google.maps.Point(0, 0),
					anchor: new google.maps.Point(0, 42)
				}
			});
			// add index
			marker.dshIndex = i;
			marker.kvpsid = __.dealerData.partners[i].kvpsid;
			marker.data = __.dealerData.partners[i];
			// add click event
			__.addMarkerListener(marker);
			__.markers.unshift(marker);
		}

		__.markerCluster = DSH_MAP.createCluster(DSH_MAP.getMap(), __.markers);
	}, (err) => {
		console.error(err);
	});
};

__.updateMarkers = function(partners) { // eslint-disable-line max-statements
	var i, p, latLng, marker, index, items, filterR8, filterTron, filterUsedCars;

	__.markers = [];
	items = [];

	for (i = partners.length - 1; i >= 0; i--) {
		// If only a subset of partners if given wrap partner objects
		// in an array, containing the partner obj and the index of this
		// partner in the whole partner set.
		if (!!partners.length && partners[i].length === 2) {
			p = partners[i][0];
			index = partners[i][1];
		}
		else {
			p = partners[i];
			index = i;
		}

		items.push(p);
		latLng = new google.maps.LatLng(p.address.latitude, p.address.longitude);
		marker = new google.maps.Marker({
			position: latLng,
			icon: {
				url: __.getMarkerIcon(p),
				origin: new google.maps.Point(0, 0),
				anchor: new google.maps.Point(0, 42)
			}
		});
		// add index
		marker.dshIndex = index;
		marker.kvpsid = p.kvpsid;
		marker.distance = p.distance;
		marker.data = p;
		// add click event listener
		__.addMarkerListener(marker);
		__.markers.unshift(marker);
	}

	DSH_MAP.updateCluster(__.markers);
	__.updateResultList();
	filterR8 = document.querySelector('.dsh-filter-r8');
	filterTron = document.querySelector('.dsh-filter-tron');
	filterUsedCars = document.querySelector('.dsh-filter-usedcars');

	if (!!filterR8.checked) {
		exports.handleR8Filter({
			target: filterR8
		});
	}
	else if (!!filterTron.checked) {
		exports.handleTronFilter({
			target: filterTron
		});
	}
	else if (!!filterUsedCars.checked) {
		exports.handleUsedCarsFilter({
			target: filterUsedCars
		});
	}
};

__.addMarkerListener = function(marker) {
	google.maps.event.addListener(marker, 'click', () => {
		exports.handleMarkerClick(marker);
	});
	google.maps.event.addListener(marker, 'mouseover', () => {
		exports.handleMarkerMouseover(marker);
	});
	google.maps.event.addListener(marker, 'mouseout', () => {
		exports.handleMarkerMouseout(marker);
	});
};

__.addUIEvents = function() {
	var delegate = dom.getEventDelegate('body');

	delegate.on('click', '.dsh-filter-r8', exports.handleR8Filter);
	delegate.on('click', '.dsh-filter-tron', exports.handleTronFilter);
	delegate.on('click', '.dsh-filter-usedcars', exports.handleUsedCarsFilter);
	delegate.on('keyup', '.dsh-search', exports.handleSearchKeyUp);
	delegate.on('click', '.dsh-partner-details .axs-button-close', exports.handleDetailsClose);
	delegate.on('click', '#dsh-zoom-in', exports.handleZoomIn);
	delegate.on('click', '#dsh-zoom-out', exports.handleZoomOut);
	delegate.on('click', '.dsh-city-suggestions a', exports.handleCitySuggestionClick);
	delegate.on('click', '.dsh-partner-suggestions a', exports.handlePartnerSuggestionClick);
	delegate.on('change', '.dsh-results-toggle', exports.handleResultListFilterChange);
	delegate.on('click', '.dsh-tabs li', exports.handleDetailsTabClick);
	delegate.on('click', '.dsh-search-container .axs-close', exports.handleSearchCloseClick);
	delegate.on('click', '.dsh-btn-gps', exports.handleGPSButtonClick);
	dom.getEventDelegate('.dsh-result-details').on('click', '.dsh-resultlist-item', exports.handleResultListItemClick);
};

__.setClassName = function(classNameToAdd) {
	var {className} = __.dshContainer;

	if (className.length === 0 || className.indexOf(classNameToAdd) === -1) {
		__.dshContainer.className = className + ' ' + classNameToAdd;
	}

	if ((classNameToAdd === 'dsh-results-active') && (document.body.clientWidth < 769)) {
		DSH_MAP.getMap().setOptions({
			draggable: false
		});
	}
	else {
		DSH_MAP.getMap().setOptions({
			draggable: true
		});
	}
};

__.removeClassName = function(classNameToRemove) {
	var {className} = __.dshContainer;
	var re = new RegExp(classNameToRemove, 'g');

	__.dshContainer.className = className.replace(re, '');
	DSH_MAP.getMap().setOptions({
		draggable: true
	});
};

exports.handleResultListMouseOver = function() {
	// TODO: remove function if not not used
	// console.log(e.target);
};

exports.handleResultListMouseOut = function() {
	// TODO: remove function if not not used
	// console.log(e.target);
};

__.getI18NKey = function(key) {
	if (!window || !window.i18n) {
		return undefined;
	}

	return window.i18n[key];
};

__.addResultListFilter = function(element) { // eslint-disable-line max-statements
	var filters, selectEl, option, value, i, l, dropdownLabelAll;

	dropdownLabelAll = 'Alle Audi Partner';

	if (!!window.i18n && window.i18n['dsh.results.dropdown.all']) {
		dropdownLabelAll = window.i18n['dsh.results.dropdown.all'];
	}

	selectEl = element.querySelector('.dsh-results-toggle');
	selectEl.innerHTML = '';
	option = document.createElement('option');
	option.value = 'all';
	option.innerHTML = __.getI18NKey('dsh.results.dropdown.all') || dropdownLabelAll;
	selectEl.appendChild(option);

	if (__.config.DealerSearchDropdownFilter) {
		filters = __.config.DealerSearchDropdownFilter.split(',');

		for (i = 0, l = filters.length; i < l; i++) {
			value = filters[i].trim();
			option = document.createElement('option');
			option.value = value;
			option.innerHTML = __.getI18NKey('dsh.results.dropdown.' + value) || value + ' Partner';
			selectEl.appendChild(option);
		}
	}
};

exports.handleZoomIn = function() {
	var map = DSH_MAP.getMap();

	if (map) {
		map.setZoom(map.zoom + 1);
		exports.handleCenterChange();
	}
};

exports.handleZoomOut = function() {
	var map = DSH_MAP.getMap(),
		zoom;

	if (map) {
		zoom = map.zoom - 1;
		map.setZoom(zoom);
		exports.handleCenterChange();
	}
};

exports.handleResize = function() {
	var map = DSH_MAP.getMap();

	if (map) {
		google.maps.event.trigger(map, 'resize');
		map.setCenter(__.currentCenter);
	}
};

exports.saveCenter = function() {
	var map = DSH_MAP.getMap();

	if (map) {
		__.currentCenter = map.getCenter();
		__.currentBounds = map.getBounds();
	}
};

exports.handleZoomChange = function() {
	var map = DSH_MAP.getMap();

	if (map.zoom > __.defaultZoomLevel) {
		map.setMapTypeId('default');
	}
	else {
		map.setMapTypeId('topLevel');
	}
};

exports.handleCenterChange = function() {
	if (__.updateLock !== true) {
		__.updateResultList();
		__.lock_and_release(1000);
	}
	else {
		__.updateReleaseCallback = exports.handleCenterChange;
	}
};

exports.handleMarkerClick = function(marker) {
	exports.showDetails(marker.data);
};

exports.handleMarkerMouseover = function(marker) {
	var resultListItemEl, resultListEl, scrollTime;

	scrollTime = 500;
	resultListEl = document.querySelector('.dsh-results');

	if (!!resultListEl) {
		if (!__.listScrollLocker) {
			__.listScrollLocker = true;
			setTimeout(() => {
				__.listScrollLocker = false;
			}, scrollTime);
			resultListItemEl = dom.getElement('div[data-dsh-kvpsid="' + marker.kvpsid + '"]');

			if (dom.isElement(resultListItemEl)) {
				resultListEl.scrollTop = resultListItemEl.offsetTop;
				dom.getElement('.dsh-resultlist-item').classList.remove('is-active');
				resultListItemEl.classList.add('is-active');
			}
		}
	}
};

exports.handleMarkerMouseout = function() {
	if (document.querySelectorAll('.dsh-details-active').length === 0) {
		dom.getElement('.dsh-resultlist-item.is-active').classList.remove('is-active');
	}
};

exports.handleDetailsClose = function() {
	exports.hideDetails();
};

exports.handleSearchCloseClick = function(e) {
	e.preventDefault();
	e.stopImmediatePropagation();
	dom.getElement('.dsh-partner-suggestions').innerHTML = '';
	dom.getElement('.dsh-city-suggestions').innerHTML = '';
	dom.getElement('.dsh-search').value = '';
	__.removeClassName('dsh-search-active');
};

exports.handleResultListFilterChange = function(e) { // eslint-disable-line max-statements
	var i, l, j, k, services, match, idList = {},
		item, id, filteredMarkers = [],
		marker;

	const {value} = e.target;
	const resultListItems = document.querySelectorAll('.dsh-resultlist-item');

	if (value === 'all') {
		for (i = 0, l = resultListItems.length - 1; i < l; i++) {
			item = resultListItems[i];
			item.classList.remove('is-hidden');
		}

		DSH_MAP.updateCluster(__.markersWithinCurrentBounds);
	}
	else {
		for (i = 0, l = resultListItems.length; i < l; i++) {
			item = resultListItems[i];
			services = item.getAttribute('data-dsh-services').split(',');
			match = false;

			// Check if any of the partner's services matches the one we are
			// looking for.
			for (j = 0, k = services.length; j < k; j++) {
				if (services[j].trim() === value) {
					match = true;
				}
			}

			if (match) {
				dom.getElement(item).classList.remove('is-hidden');
				id = item.getAttribute('data-dsh-kvpsid');

				if (!!id) {
					idList[id] = id;
				}
			}
			else {
				dom.getElement(item).classList.add('is-hidden');
			}

			match = false;
		}

		l = __.markersWithinCurrentBounds.length;

		while (l--) {
			marker = __.markersWithinCurrentBounds[l];

			if (!!idList[marker.data.kvpsid]) {
				filteredMarkers.push(marker);
			}
		}

		DSH_MAP.updateCluster(filteredMarkers);
	}
};

exports.handleResultListItemClick = function(e) {
	var kvpsid, partnerData;

	kvpsid = dom.closest(e.target, '.dsh-resultlist-item').getAttribute('data-dsh-kvpsid');
	partnerData = DSH_DATA.getItemByKvpsId(kvpsid);
	exports.showDetails(partnerData);
};

exports.handleDetailsTabClick = function(e) { // eslint-disable-line max-statements
	var containers, tabs, tabsContainer, id, i, k, panels, siblings, targettext;

	if (dom.getElementsArray('.dsh-tabs .active-item').length === 0) {
		return;
	}

	tabsContainer = dom.getElement('.dsh-tabs');

	if (e.target.classList.contains('active')) {
		if (tabsContainer.classList.contains('is-open')) {
			tabsContainer.classList.remove('is-open');
		}
		else {
			tabsContainer.classList.add('is-open');
		}

		return;
	}

	tabsContainer.classList.remove('is-open');
	containers = dom.getElement('.dsh-panels > div');
	tabs = dom.getElementsArray('.dsh-tabs li');

	// index = tabs.index(e.target);
	if (!e.target.classList.contains('active-item')) {
		for (i = 0; i < tabs.length; i++) {
			tabs[i].classList.remove('active');
		}

		// tabs.classList.remove('active');
		e.target.classList.add('active');
		siblings = dom.getElementsArray('active-item', e.target);
		targettext = e.target.innerHTML.replace('/<[^>]*>/g', '');

		for (i = 0; i < siblings.length; i++) {
			siblings[i].innerHTML = targettext;
		}

		containers.classList.remove('active');
		id = e.target.getAttribute('data-tab');
		panels = document.querySelectorAll('.dsh-panel');

		for (k = 0; k < panels.length; k++) {
			if (panels[k].classList.contains('active')) {
				panels[k].classList.remove('active');
			}

			if (panels[k].getAttribute('data-panel') === id) {
				panels[k].classList.add('active');
			}
		}
	}
};

exports.showDetails = function(data) { // eslint-disable-line max-statements
	var content, resultListItem, data_;

	data_ = __.enhanceDealerData(data);
	resultListItem = dom.getElement('.dsh-resultlist-item[data-dsh-kvpsid="' + data_.kvpsid + '"]');
	dom.getElement('.dsh-resultlist-item.is-active').classList.remove('is-active');
	resultListItem.classList.add('is-active');

	if (!__.partnerDetailsContainer) {
		__.partnerDetailsContainer = document.querySelector(__.partnerDetailsSelector);
	}

	__.partnerDetailsContainer.setAttribute('data-dsh-kvpsid', data_.kvpsid);
	const {className} = __.dshContainer;

	if (className.length === 0 || className.indexOf('dsh-details-active') === -1) {
		__.setClassName('dsh-details-active');
		content = template.render(DETAIL_TPL, data_);
		__.partnerDetailsContainer.innerHTML = content;

		if (document.body.clientWidth < 750) {
			window.scrollTo(0, dom.getOffset('.dsh-partner-details').top);
		}
	}
	else {
		__.removeClassName('dsh-details-active');
		__.partnerDetailsContainer.innerHTML = '';
		// TODO: kill timeout if needed
		// Add short delay, to have an open / close box
		setTimeout(() => {
			__.setClassName('dsh-details-active');
			content = template.render(DETAIL_TPL, data_);
			__.partnerDetailsContainer.innerHTML = content;
		}, 400);
	}

	setTimeout(() => {
		__.addPartnerMap(__.partnerDetailsContainer.querySelector('.dsh-partner-map'), data_);
	}, 800);
};

__.enhanceDealerData = function(item) {
	item.directionsLink = __.config.MapsDirectionsBaseUrl + '/\'\'/' + encodeURIComponent(item.name) + ',' + encodeURIComponent(item.address.display[0]) + ',' + encodeURIComponent(item.address.display[1]) + '/@' + item.address.latitude + ',' + item.address.longitude + ',15z';

	if (__.itemIsSales(item)) {
		item.dealer = true;
	}

	if (__.itemIsService(item)) {
		item.service = true;
	}

	item.servicesAsString = '';

	if (!!item.services) {
		item.servicesAsString = item.services.join(',');
	}

	item.dshConfig = __.config;
	return item;
};

__.addPartnerMap = function(element, data) {
	/* eslint-disable */
	var mapOpts, map, marker, latlng;

	/* eslint-enable */
	mapOpts = {
		center: new google.maps.LatLng(data.address.latitude, data.address.longitude),
		zoom: 16,
		mapTypeId: google.maps.MapTypeId.ROADMAP,
		mapTypeControl: false,
		panControl: false,
		streetViewControl: false,
		zoomControl: false,
		fullscreenControl: false,
		styles: DSH_MAP.defaultMapStyle,
		zoomControlOptions: {
			style: google.maps.ZoomControlStyle.SMALL,
			position: google.maps.ControlPosition.TOP_RIGHT
		}
	};
	element.style.height = __.getPartnerMapHeight();
	map = new google.maps.Map(element, mapOpts);
	latlng = new google.maps.LatLng(data.address.latitude, data.address.longitude);
	// eslint-disable-next-line no-unused-vars
	marker = new google.maps.Marker({
		position: latlng,
		icon: {
			url: __.getMarkerIcon(data),
			origin: new google.maps.Point(0, 0),
			anchor: new google.maps.Point(0, 42)
		},
		map
	});
};

__.getPartnerMapHeight = function() {
	var rest, totalHeight, headerHeight, infoHeight, linksHeight;

	totalHeight = dom.getElement('.dsh-partner-details').offsetHeight;
	headerHeight = dom.getElement('.dsh-detail-header').offsetHeight;
	infoHeight = dom.getElement('.dsh-detail-info').offsetHeight;
	linksHeight = dom.getElement('.dsh-dealer-links').offsetHeight;
	rest = totalHeight - headerHeight - infoHeight - linksHeight - 210;
	return rest + 'px';
};

exports.hideDetails = function() {
	var resultListItem, kvpsid;

	if (!__.partnerDetailsContainer) {
		__.partnerDetailsContainer = document.querySelector(__.partnerDetailsSelector);
	}

	__.removeClassName('dsh-details-active');
	__.partnerDetailsContainer.innerHTML = '';
	kvpsid = __.partnerDetailsContainer.getAttribute('data-dsh-kvpsid');
	resultListItem = dom.getElement('.dsh-resultlist-item[data-dsh-kvpsid="' + kvpsid + '"]');
	resultListItem.classList.remove('is-active');
};

exports.handleSearchKeyUp = function(e) { // eslint-disable-line max-statements
	/* eslint-disable */
	var i, p, citySuggestionsEl, partnerSuggestionsEl, headerEl,
		displayString, partnerMatches, citiesLabel, partnersLabel, container;

	/* eslint-enable */
	citiesLabel = __.getI18NKey('dsh.label.search.cities') || 'Städte';
	partnersLabel = __.getI18NKey('dsh.label.search.partners') || 'Partner';
	partnerMatches = false;
	const {value} = e.target;

	if (e.keyCode === 13) {
		__.searchAddress(value);
		dom.getElement('.dsh-search-container .dsh-city-suggestions').innerHTML = '';
		dom.getElement('.dsh-search-container .dsh-partner-suggestions').innerHTML = '';
		dom.getElement('.dsh-no-suggestions').classList.add('axs-hidden');
		__.removeClassName('dsh-results-active');
		return;
	}

	// eslint-disable-next-line no-unused-vars
	headerEl = dom.getElement('.dsh-header');
	partnerSuggestionsEl = dom.getElement('.dsh-partner-suggestions');
	citySuggestionsEl = dom.getElement('.dsh-city-suggestions');
	partnerSuggestionsEl.innerHTML = '';

	if (value.length >= 3) {
		DSH_DATA.getCityNameSuggestions(value, __.config.DealerSearchLocaleCountry).then((results) => {
			citySuggestionsEl.innerHTML = '';
			dom.appendHtmlString(citySuggestionsEl, '<h4 class="axs-el-hdl axs-el-hdl-05 axs-at-hdl-b dsh-hl-suggestion">' + citiesLabel + '</h4>');

			for (i = results.length - 1; i >= 0; i--) {
				dom.appendHtmlString(citySuggestionsEl, '<a href="#" data-search="' + results[i] + '" class="axs-link-basic">' +
						'<span>' + results[i] + '</span><svg class="axs-icon-arrow">' +
						'<use xlink:href="' + SETUPS.get('DocrootJs') + '/../includes/core-ci/assets/icons/svg/arrow.svg#nm-icon-arrow"></use>' +
						'</svg></a><br />');
			}

			if (results.length === 0 && !partnerMatches) {
				dom.getElement('.dsh-hl-suggestion').classList.add('axs-hidden');
			}
			else {
				dom.getElement('.dsh-hl-suggestion').classList.remove('axs-hidden');
			}
		}, () => {
			if (!partnerMatches) {
				dom.getElement('.dsh-hl-suggestion').classList.add('axs-hidden');
			}
			else {
				dom.appendHtmlString(citySuggestionsEl, '<h4 class="axs-el-hdl axs-el-hdl-05 axs-at-hdl-b dsh-hl-suggestion">' + citiesLabel + '</h4>');
			}
		});
		DSH_DATA.getDealerSuggestions(value).then((results) => {
			if (results.length > 0) {
				partnerMatches = true;
				dom.getElement('.dsh-hl-suggestion').classList.remove('axs-hidden');
			}
			else {
				partnerMatches = false;
			}

			if (results.length > 0) {
				dom.appendHtmlString(partnerSuggestionsEl, '<h4 class="axs-el-hdl axs-el-hdl-05 axs-at-hdl-b dsh-hl-suggestion">' + partnersLabel + '</h4>');
			}

			for (i = results.length - 1; i >= 0; i--) {
				p = results[i];
				displayString = p.name.replace(new RegExp('(' + value + ')', 'gi'), '<span class="dsh-search-highlight">' + '$1' + '</span>') + // eslint-disable-line no-useless-concat
					', ' + p.address.zipCode + ' ' + p.address.city;
				dom.appendHtmlString(partnerSuggestionsEl, '<a href="#" data-dsh-kvpsid="' + p.kvpsid + '" class="axs-link-basic"><span>' + displayString + '</span>' +
						'<svg class="axs-icon-arrow">' +
							'<use xlink:href="' + SETUPS.get('DocrootJs') + '/../includes/core-ci/assets/icons/svg/arrow.svg#nm-icon-arrow"></use>' +
						'</svg>' +
						'</a><br />');
			}
		}, () => {
			partnerSuggestionsEl.innerHTML = '';
			dom.appendHtmlString(partnerSuggestionsEl, '<h4 class="axs-el-hdl axs-el-hdl-05 axs-at-hdl-b dsh-hl-suggestion">' + partnersLabel + '</h4>');
			partnerMatches = false;
		});
		__.setClassName('dsh-search-active');
	}
	else {
		citySuggestionsEl.innerHTML = '';
		__.removeClassName('dsh-search-active');
		__.removeClassName('dsh-results-active');
	}
};

exports.handleR8Filter = function(e) { // eslint-disable-line max-statements
	var i, p, checked, items, visibleMarkers,
		dshFilterInputs, dshFilterInput;

	items = [];
	visibleMarkers = [];
	checked = !!e.target.checked;
	dshFilterInputs = dom.getElementsArray('.dsh-filter input');

	for (i = 0; i < dshFilterInputs.length; i++) {
		dshFilterInput = dshFilterInputs[i];

		if (!dshFilterInput.classList.contains('dsh-filter-r8')) {
			dshFilterInput.setAttribute('checked', false);
			dshFilterInput.checked = false;
		}
	}

	if (checked) {
		__.r8FilterActive = true;

		for (i = __.markers.length - 1; i >= 0; i--) {
			p = __.markers[i].data;

			if (!!p.services && p.services.toString().match(/r8/)) {
				__.markers[i].setVisible(true);
				items.push(p);
				visibleMarkers.push(__.markers[i]);
			}
			else {
				__.markers[i].setVisible(false);
			}
		}
	}
	else {
		__.r8FilterActive = false;

		for (i = __.markers.length - 1; i >= 0; i--) {
			__.markers[i].setVisible(true);
			items.push(__.markers[i].data);
			visibleMarkers.push(__.markers[i]);
		}
	}

	DSH_MAP.updateCluster(visibleMarkers);
	__.updateResultList();
};

exports.handleTronFilter = function(e) { // eslint-disable-line max-statements
	var i, p, checked, items, visibleMarkers,
		dshFilterInputs, dshFilterInput;

	items = [];
	visibleMarkers = [];
	checked = !!e.target.checked;
	dshFilterInputs = dom.getElementsArray('.dsh-filter input');

	for (i = 0; i < dshFilterInputs.length; i++) {
		dshFilterInput = dshFilterInputs[i];

		if (!dshFilterInput.classList.contains('dsh-filter-tron')) {
			dshFilterInput.setAttribute('checked', false);
			dshFilterInput.checked = false;
		}
	}

	if (checked) {
		__.tronFilterActive = true;

		for (i = __.markers.length - 1; i >= 0; i--) {
			p = __.markers[i].data;

			if (!!p.services && p.services.toString().match(/tron/)) {
				__.markers[i].setVisible(true);
				items.push(p);
				visibleMarkers.push(__.markers[i]);
			}
			else {
				__.markers[i].setVisible(false);
			}
		}
	}
	else {
		__.tronFilterActive = false;

		for (i = __.markers.length - 1; i >= 0; i--) {
			__.markers[i].setVisible(true);
			items.push(__.markers[i].data);
			visibleMarkers.push(__.markers[i]);
		}
	}

	DSH_MAP.updateCluster(visibleMarkers);
	__.updateResultList();
};

exports.handleUsedCarsFilter = function(e) { // eslint-disable-line max-statements
	var i, p, checked, items, visibleMarkers,
		dshFilterInputs,
		dshFilterInput;

	items = [];
	visibleMarkers = [];
	checked = !!e.target.checked;
	dshFilterInputs = dom.getElementsArray('.dsh-filter input');

	for (i = 0; i < dshFilterInputs.length; i++) {
		dshFilterInput = dshFilterInputs[i];

		if (!dshFilterInput.classList.contains('dsh-filter-usedcars')) {
			dshFilterInput.setAttribute('checked', false);
			dshFilterInput.checked = false;
		}
	}

	if (checked) {
		__.usedCarsFilterActive = true;

		for (i = __.markers.length - 1; i >= 0; i--) {
			p = __.markers[i].data;

			if (!!p.services && p.services.toString().match(/usedcars/)) {
				__.markers[i].setVisible(true);
				items.push(p);
				visibleMarkers.push(__.markers[i]);
			}
			else {
				__.markers[i].setVisible(false);
			}
		}
	}
	else {
		__.usedCarsFilterActive = false;

		for (i = __.markers.length - 1; i >= 0; i--) {
			__.markers[i].setVisible(true);
			items.push(__.markers[i].data);
			visibleMarkers.push(__.markers[i]);
		}
	}

	DSH_MAP.updateCluster(visibleMarkers);
	__.updateResultList();
};

exports.handleCitySuggestionClick = function(e) {
	var queryString;

	if (!!e && !!e.preventDefault) {
		e.preventDefault();
	}

	dom.getElement('.dsh-search-container .dsh-city-suggestions').innerHTML = '';
	dom.getElement('.dsh-search-container .dsh-partner-suggestions').innerHTML = '';
	__.removeClassName('dsh-search-active');
	queryString = this.getAttribute('data-search');
	dom.getElement('.dsh-search').value = queryString;
	__.searchAddress(queryString);
};

__.searchAddress = function(queryString) {
	var latlng;

	DSH_DATA.searchPartnersByCity(queryString).then((data) => {
		var partners, i;

		partners = [];

		if (!!data.partners) {
			for (i = data.partners.length - 1; i >= 0; i--) {
				partners.unshift([data.partners[i], i]);
			}
		}

		latlng = new google.maps.LatLng(data.meta.search.latitude, data.meta.search.longitude);
		__.updateMarkerData(partners);
		setTimeout(() => {
			DSH_MAP.getMap().setCenter(latlng);
			DSH_MAP.getMap().setZoom(__.getSearchResultZoom());
			__.setCenterMarker(latlng);
		}, 200);
	}, (err) => {
		console.error(err);
	});
};

__.getSearchResultZoom = function() {
	return document.body.clientWidth < 750 ? 9 : 10;
};

__.updateMarkerData = function(items) {
	var i, newKvpsids, distances, distancesOnAddress, item;

	newKvpsids = [];
	distances = {};
	distancesOnAddress = {};

	// memorize kvpsids
	for (i = items.length - 1; i >= 0; i--) {
		item = items[i][0];
		newKvpsids.push(item.kvpsid);
		distances[item.kvpsid] = item.distance;

		if (!!(item.address && item.address.distance)) {
			distancesOnAddress[item.kvpsid] = item.address.distance;
		}
	}

	newKvpsids = newKvpsids.toString();

	// update existing marker data
	for (i = __.markers.length - 1; i >= 0; i--) {
		if (newKvpsids.indexOf(__.markers[i].data.kvpsid) !== -1) {
			__.markers[i].data.distance = distances[__.markers[i].data.kvpsid];
			__.markers[i].data.address.distance = distancesOnAddress[__.markers[i].data.kvpsid];
		}
	}
};

__.setCenterMarker = function(latlng) {
	// Remove existing marker
	if (__.centerMarker) {
		__.centerMarker.setMap(null);
	}

	// Set new marker
	__.centerMarker = new google.maps.Marker({
		position: latlng,
		icon: __.dataURIs.location,
		zIndex: 1,
		map: DSH_MAP.getMap()
	});
};

exports.handlePartnerSuggestionClick = function(e) { // eslint-disable-line max-statements
	var kvpsid, data, map, zoom;

	if (!!e && !!e.preventDefault) {
		e.preventDefault();
	}

	kvpsid = dom.closest(e.target, '.axs-link-basic').getAttribute('data-dsh-kvpsid');
	data = DSH_DATA.getItemByKvpsId(kvpsid);

	if (kvpsid !== undefined) {
		dom.getElement('.dsh-search').value = '';
		dom.getElement('.dsh-search-container .dsh-city-suggestions').innerHTML = '';
		dom.getElement('.dsh-search-container .dsh-partner-suggestions').innerHTML = '';
		__.removeClassName('dsh-search-active');
		exports.showDetails(data);
		DSH_MAP.getMap().setCenter(new google.maps.LatLng(data.address.latitude, data.address.longitude));
		map = DSH_MAP.getMap();
		zoom = __.getSearchResultZoom();
		map.setZoom(zoom);
		DSH_DATA.getAllPartners().then((data_) => {
			__.updateMarkers(data_.partners);
			__.updateResultList();
		});
	}
};

__.getCityName = function(address_components) {
	var i, j;

	// ...check every address component...
	for (i = address_components.length - 1; i >= 0; i--) {
		// ...and see if 'locality' is among its types. And if it is...
		for (j = address_components[i].types.length; j >= 0; j--) {
			// ...return the long_name of the address component as search term.
			if (address_components[i].types[j] === 'locality') {
				return address_components[i].long_name;
			}
		}
	}
};

__.findAddressEntry = function(results) {
	var i, l, j, city_name;

	// For each address result...
	for (i = 0, l = results.length; i < l; i++) {
		for (j = results[i].types.length - 1; j >= 0; j--) {
			// if type is street address, return immediately
			if (results[i].types[j] === 'street_address') {
				city_name = __.getCityName(results[i].address_components);
				return city_name;
			}
			// if type is locality, save it, so it can be uses if no street_address
			// is found.
			else if (results[i].types[j] === 'locality') {
				city_name = __.getCityName(results[i].address_components);
			}
		}
	}

	return city_name;
};

__.init = function() {
	var element = dom.getElement('#dsh');

	if (!dom.isElement(element)) {
		return;
	}

	__.containerEl = element;
	__.dshContainer = document.getElementById('dsh');
	__.readConfig(__.containerEl.getAttribute('data-dsh-config'), __.containerEl.getAttribute('data-dsh-distance-unit'));
	DSH_DATA.setConfig(__.config);
	__.containerEl.innerHTML = (template.render(CONTAINER_TPL, {
		title: 'DSH',
		dshConfig: __.config
	}));
	__.resultlistEl = dom.getElement('.dsh-results', __.containerEl);
	__.resultlistToggleEl = dom.getElement('.dsh-results-toggle', __.containerEl);
	// read config
	DSH_MAP.renderMap({
		defaultMapCenter: __.defaultMapCenter,
		defaultZoomLevel: __.defaultZoomLevel
	}).then(() => {
		__.updateListener = DSH_MAP.addListener('bounds_changed', exports.handleCenterChange);
		__.mapLoadedListener = DSH_MAP.addListener('tilesloaded', exports.handleCenterChange);
		DSH_MAP.addListener('zoom_changed', exports.handleZoomChange);
		DSH_MAP.addListener('idle', exports.saveCenter);
		google.maps.event.addDomListener(window, 'resize', exports.handleResize);
		google.maps.event.addDomListener(window, 'scroll', () => {
			// fix for scrolling on iphone
			DSH_MAP.removeListener(__.updateListener);
			DSH_MAP.removeListener(__.mapLoadedListener);
			setTimeout(() => {
				__.updateListener = DSH_MAP.addListener('bounds_changed', exports.handleCenterChange);
				__.mapLoadedListener = DSH_MAP.addListener('tilesloaded', exports.handleCenterChange);
			}, 100);
		});
		__.initMarkers();
		__.addResultListFilter(element);
		__.addUIEvents();
	});
};

exports.handleGPSButtonClick = function(e) {
	var geocoder, address;

	e.preventDefault();

	if (!!navigator.geolocation) {
		geocoder = new google.maps.Geocoder();
		navigator.geolocation.getCurrentPosition((position) => {
			var latLng;

			if (!!position.coords) {
				latLng = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
				geocoder.geocode({
					latLng
				}, (results, status) => {
					if (status === google.maps.GeocoderStatus.OK) {
						address = __.findAddressEntry(results);
						dom.getElement('.dsh-search').value = address;
						__.searchAddress(address);
					}
				});
			}
		}, (err) => {
			console.error(err);
		});
	}
};

/**
 * public initialize method
 * @param {EventBus} globalEventBus - tbd
 * @returns {Promise} returns Promise
 */
exports.initialize = function(globalEventBus) {
	return new Promise(resolve => {
		__.globalEventBus = globalEventBus;
		__.init();
		resolve('dsh/api.js');
	});
};

export {exports as dshapi};
