import {dom} from 'core-utils';
import {appEvents} from 'application-bundle';

var __ = {},
	exports = {__};

__.oDefaults = {
	'selectorMain': '.axs-md-stories',
	'allRadio0Inputs': 'input[id$=radio-0]',
	'storiesInputs': 'input[name$="storiesinput"]',
	'storiesVideo': '.axs-md-stories-video'
};

__.selectFirstStoryForDesktop = function() {
	var radio0List, i, firstStoryId;

	if (window.outerWidth > 1024) {
		// search for all modules which radio-0 input is selected
		// and select the first story if it's a desktop view
		radio0List = document.querySelectorAll(__.oDefaults.allRadio0Inputs);

		for (i = 0; i < radio0List.length; i++) {
			if (radio0List[i].checked) {
				firstStoryId = radio0List[i].id.replace('radio-0', 'radio-1');
				dom.getElement('#' + firstStoryId).checked = true;
			}
		}
	}
};

__.handleCheckboxes = function() {
	var inputs = dom.getElementsArray(__.oDefaults.storiesInputs);

	inputs.forEach((input) => {
		input.onchange = function() {
			var overviewInput = dom.getElement('#' + this.id + '-overview');

			overviewInput.checked = true;
		};
	});
};

// __.checkOverviewInput = function() {
// 	var overviewInput = dom.item('#' + this.id + '-overview').element;
// 	if (overviewInput) {
// 		overviewInput.checked = true;
// 	}
// };
__.addEvents = function() {
	var delegate = dom.getEventDelegate('body');

	__.globalEventBus.on(appEvents.LAYER_LOADED, __.handleCheckboxes);

	delegate.on('mouseover', __.oDefaults.storiesVideo, (event_) => {
		var video = event_.target;

		video.muted = true;
		video.play();
	});
	delegate.on('mouseout', __.oDefaults.storiesVideo, (event_) => {
		var video = event_.target;

		video.pause();
		video.muted = false;
	});
	delegate.on('click', __.oDefaults.storiesVideo, (event_) => {
		var video = event_.target;

		if (video.paused) {
			// video is not playing; so start it
			video.muted = false;
			video.play();
		}
		else {
			// video is already playing
			if (video.muted) {
				// video is playing muted; have to unmute
				video.muted = false;
			}
			else {
				// video is playing unmuted; have to pause
				video.pause();
				/* var elem = video;
				if (elem.requestFullscreen) {
					elem.requestFullscreen();
				} else if (elem.msRequestFullscreen) {
					elem.msRequestFullscreen();
				} else if (elem.mozRequestFullScreen) {
					elem.mozRequestFullScreen();
				} else if (elem.webkitRequestFullscreen) {
					elem.webkitRequestFullscreen();
				} */
			}
		}
	});

	window.onresize = function() {
		__.selectFirstStoryForDesktop();
	};

	// select the first story for desktop view on loading the module
	__.selectFirstStoryForDesktop();
};

__.init = function() {
	__.addEvents();
	__.handleCheckboxes();
};

/**
 * public initialize method
 * @param {EventBus} globalEventBus - globalEventBus
 * @returns {Promise} Promise - return Promise
 */
exports.initialize = function(globalEventBus) {
	return new Promise(resolve => {
		__.globalEventBus = globalEventBus;
		__.init();
		resolve('stories.js');
	});
};

export {exports as stories};
