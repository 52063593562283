import {signal} from 'application-bundle';
import {scroll} from 'global-bundle';
import {dom} from 'core-utils';
import AnchorList from './anchor-list';

export default class AnchorNavigation extends HTMLElement {

	constructor() {
		super();
		this.navigationInitialWidth = 0;
		this.stickyPosition = 0;
		this.windowScrollPosition = 0;
		this.onScrollHandler = dom.throttle(this._onScrollHandler, 100);
		this.onResizeHandler = dom.throttle(this._onResizeHandler, 100);

		this.prepareNavigationStickyPosition = this._prepareNavigationStickyPosition.bind(this);

		this.placeHolder = this.nextElementSibling;
	}

	connectedCallback() {
		this._addEvents();
		this._initialize();
	}

	disconnectedCallback() {
		this._removeEvents();
	}

	/**
	 * initialize the anchor navigation
	 */
	_initialize() {
		this._initializeNavigation();
		this._initializeFlyOutButton();
		this._initializeAnchorLinks();

		this.anchorList = new AnchorList();

		this._prepareNavigationDisplay();
	}

	_addEvents() {
		let eventBus = signal.getEmitter();

		window.addEventListener('resize', this.onResizeHandler);
		window.addEventListener('scroll', this.onScrollHandler);
		document.addEventListener('axs-header-cookie-notification-size-change', this.prepareNavigationStickyPosition);
		eventBus.addListener(AnchorList.EVENT_ANCHOR_ACTIVATED, this._onActivateNavigationAnchor);
	}

	_removeEvents() {
		const anchorLinksLength = this.anchorLinks.length;

		window.removeEventListener('resize', this.onResizeHandler);
		window.removeEventListener('scroll', this.onScrollHandler);
		document.removeEventListener('axs-header-cookie-notification-size-change', this.prepareNavigationStickyPosition);
		this.flyOutButton.removeEventListener('click', this._onToggleFlyoutStatus);

		for (let key = 0; key < anchorLinksLength; key++) {
			this.anchorLinks[key].removeEventListener('click', this._onClickAnchorLink);
		}
	}

	_prepareNavigationDisplay() {
		this._calculateNavigationType();
		this._prepareNavigationStickyPosition();
	}

	_prepareNavigationStickyPosition() {
		if (!this.isBurgerMenu) {
			this.isSticky = false;
			this._calculateStickyPosition();
			this._handleStickyPositioning();
		}
	}

	/**
	 * initialize the navigation elements
	 */
	_initializeNavigation() {
		this.navigation = this.querySelector('.axs-module-anchor-navigation-list');
		this.navigationWrapper = this.querySelector('.axs-module-anchor-navigation-wrapper');
		this.navigationInitialWidth = this.navigation.offsetWidth;
	}

	/**
	 * initialize the flyout button
	 */
	_initializeFlyOutButton() {
		this.flyOutButton = this.querySelector('.axs-module-anchor-navigation-flyout-button');
		this.flyOutButton.addEventListener('click', this._onToggleFlyoutStatus);
	}

	/**
	 * initialize the navigation elements
	 */
	_initializeAnchorLinks() {
		this.anchorLinks = this.getElementsByClassName('axs-module-anchor-navigation-anchor-link');
		const anchorLinksLength = this.anchorLinks.length;

		for (let key = 0; key < anchorLinksLength; key++) {
			this.anchorLinks[key].addEventListener('click', this._onClickAnchorLink);
		}
	}

	/**
	 *
	 * @param {CustomEvent} event_ - AnchorList.EVENT_ANCHOR_ACTIVATED
	 */
	_onActivateNavigationAnchor = (event_) => {
		let link = this.querySelector('[href=\'#' + event_.reference + '\']');

		if (link) {
			this._deactivateAllAnchorItems();
			this._activateAnchorItem(link);
		}
	};

	/**
	 * toggle the open of the flyout
	 */
	_onToggleFlyoutStatus = () => {
		this.isFlyoutOpen = !this.isFlyoutOpen;
	};

	/**
	 * clickhandler for the anchornavigation
	 * @param {Event} event_ - the clickevent
	 */
	_onClickAnchorLink = (event_) => {
		if (this.isBurgerMenu) {
			this._onToggleFlyoutStatus();
			this._scrollToTargetOfLink(event_.target);
		}
		else {
			event_.preventDefault();
			this._deactivateAllAnchorItems();
			this._scrollToTargetOfLink(event_.target);
			this._activateAnchorItem(event_.target);
		}
	};

	/**
	 * @param {HTMLElement} link_ - link of item to be activated (click target)
	 */
	_activateAnchorItem(link_) {
		link_.parentNode.classList.add('axs-module-anchor-navigation-list-item-is-active');
	}

	_deactivateAllAnchorItems() {
		const anchorLinksLength = this.anchorLinks.length;

		for (let key = 0; key < anchorLinksLength; key++) {
			this.anchorLinks[key].parentNode.classList.remove('axs-module-anchor-navigation-list-item-is-active');
		}
	}

	/**
	 * @param {HTMLElement} link_ - anchor link
	 */
	_scrollToTargetOfLink(link_) {
		const linkHref = link_.getAttribute('href');
		const targetName = this._cutOfHashFromString(linkHref);
		const target = document.getElementsByName(targetName)[0];
		let stickyOffset = 0;

		if (!this.isBurgerMenu) {
			stickyOffset -= this.clientHeight;

			if (!this.isSticky) {
				stickyOffset -= this.clientHeight;
			}
		}

		scroll.scrollToElement(target, {
			offset: stickyOffset,
			duration: 660, // time-xxl
			easing: 'easeInOutSine'
		});
	}

	/**
	 * @param {string} string_ - original string
	 * @returns {string} modifiedString - string without starting '#'
	 */
	_cutOfHashFromString(string_) {
		let modifiedString = string_;

		while (modifiedString.charAt(0) === '#') {
			modifiedString = modifiedString.substr(1);
		}

		return modifiedString;
	}

	/**
	 * handler for the resize evet of the window
	 */
	_onResizeHandler = () => {
		this._resetNavigationType();
		this._prepareNavigationDisplay();
	};

	_resetNavigationType() {
		if (this.isBurgerMenu) {
			this.isBurgerMenu = false;
			this.isFlyoutOpen = false;
			this.navigationInitialWidth = this.navigation.offsetWidth;
		}
	}

	_onScrollHandler = () => {
		this._handleStickyPositioning();
	};

	_handleStickyPositioning() {
		if (!this.isSticky && window.pageYOffset > this.windowScrollPosition) {
			this.isSticky = true;
			this.style.top = this.stickyPosition + 'px';
		}
		else if (this.isSticky && window.pageYOffset <= this.windowScrollPosition) {
			this.isSticky = false;
			this.style.top = 'initial';
		}

		if (this.isSticky) {
			this.placeHolder.style.height = this.clientHeight + 'px';
		}
		else {
			this.placeHolder.style.height = 0;
		}
	}

	/**
	 * checks whether the navigation fits in the available space or the burger menu is to be shown
	 */
	_calculateNavigationType() {
		const availableWidthForNavigation = this.navigationWrapper.clientWidth * .92; // grid-layout 92% width

		if (availableWidthForNavigation >= this.navigationInitialWidth) {
			if (this.isBurgerMenu) {
				this.isBurgerMenu = false;
			}
		}
		else if (!this.isBurgerMenu) {
			this.isBurgerMenu = true;
		}
	}

	_calculateStickyPosition() {
		const headerElement = document.querySelector('.axs-j-header-wrapper');

		this.stickyPosition = 0;

		if (headerElement) {
			this.stickyPosition = headerElement.getBoundingClientRect().bottom;
		}

		this.windowScrollPosition = window.pageYOffset + this.getBoundingClientRect().top - this.stickyPosition;
	}

	get isFlyoutOpen() {
		return this.getAttribute('is-flyout-open') === 'true';
	}

	set isFlyoutOpen(val) {
		this.setAttribute('is-flyout-open', val);
	}

	get isBurgerMenu() {
		return this.getAttribute('is-burger-menu') === 'true';
	}

	set isBurgerMenu(val) {
		this.setAttribute('is-burger-menu', val);
	}

	get isSticky() {
		return this.getAttribute('is-sticky') === 'true';
	}

	set isSticky(val) {
		this.setAttribute('is-sticky', val);
	}
}

window.customElements.define('audi-anchor-navigation', AnchorNavigation);
