import {dom} from 'core-utils';
import {JScrollReveal} from 'global-bundle';

export default class ThumbnailText extends HTMLElement {
	static get CLASS_ITEM () {
		return 'axs-thumbnail-text__item';
	}

	static get SELECTOR_ITEM_HIDDEN () {
		return 'axs-thumbnail-text__item[data-scroll-reveal="hidden"]';
	}

	constructor () {
		super();
		this.items = [];
		this.setIsMultiline = dom.throttle(this._setIsMultiline.bind(this), 100);
	}

	connectedCallback () {
		this._initialize();
	}

	disconnectedCallback() {
		this._unbind();

		if (this.scrollReveal) {
			this.scrollReveal.destroy();
		}
	}

	_initialize () {
		this.items = this.querySelectorAll('.' + ThumbnailText.CLASS_ITEM);

		if (this._hasAnimation()) {
			this._bind();
			this._setIsMultiline();
			this.scrollReveal = new JScrollReveal({container: this, itemClass: ThumbnailText.CLASS_ITEM, percentage: 25});
		}
	}

	/**
	 * @returns {boolean} - should items be animated (true) or not (false)
	*/
	_hasAnimation() {
		return this.classList.contains('axs-thumbnail-text--with-animation');
	}

	_bind () {
		window.addEventListener('resize', this.setIsMultiline);
	}

	_unbind () {
		window.removeEventListener('resize', this.setIsMultiline);
	}

	_setIsMultiline () {
		this.setAttribute('data-isMultiline', this._isMultiline());
	}

	/**
	 * @returns {boolean} - is multiline or not
	 */
	_isMultiline () {
		if (this.items.length > 0) {
			const firstItemTop = this.items[0].getBoundingClientRect().top;
			const lastItemTop = this.items[this.items.length - 1].getBoundingClientRect().top;

			if (firstItemTop < lastItemTop) {
				return true;
			}
		}

		return false;
	}
}

if (window.customElements.get('audi-axs-thumbnail-text') === undefined) {
	window.customElements.define('audi-axs-thumbnail-text', ThumbnailText);
}
