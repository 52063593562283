export default class CountrySelectorZoomButton {

	constructor(countrySelector_) {
		this.CountrySelector = countrySelector_;
		this.zoomButton = this.CountrySelector.querySelector('.country-selector-map-zoomout-button');
	}

	_handleZoomOutClick = () => {
		this.CountrySelector.handleMapZoomOut();
		this.hide();
	};

	/**
	* _removeSelectsEventListeners (private) - remove events
	*/
	removeEventListeners() {
		this.zoomButton.removeEventListener('click', this._handleZoomOutClick);
	}

	/**
	* hide - hide Button and remove Listener
	*/
	hide() {
		this.zoomButton.setAttribute('data-visibility', 'hidden');
		this.zoomButton.removeEventListener('click', this._handleZoomOutClick);
	}

	/**
	* show - show Button and add Listener
	*/
	show() {
		this.zoomButton.setAttribute('data-visibility', 'visible');
		this.zoomButton.addEventListener('click', this._handleZoomOutClick);
	}
}
