export default class DropDownList {

	/**
	 * constructor
	 * @param {DropDown} dropdown_ - parent dropdown object
	 * @param {HTMLElement} dropDownList_ - dropdoen list elements
	 */
	constructor(dropdown_, dropDownList_) {
		this.dropDownList = dropDownList_;
		this.dropdown = dropdown_;
		this.selectedItem = null;
		this.handleClickOnItem = this._handleClickOnItem.bind(this);

		this._initialize();
	}
	_initialize() {
		this.selectedItem = this.dropDownList.querySelector('.axs-ondemand-configurator__modelfilter-dropdown-item-selected');
		this._addEvents();
	}

	_addEvents() {
		let items = this.dropDownList.querySelectorAll('.axs-ondemand-configurator__modelfilter-dropdown-item');
		let itemsLength = items.length;

		for (let i = 0; i < itemsLength; i++) {
			items[i].addEventListener('click', this.handleClickOnItem);
		}
	}

	_resetStyleSelectedItem() {
		this.selectedItem.classList.remove('axs-ondemand-configurator__modelfilter-dropdown-item-selected');
	}

	_setStyleSelectedItem() {
		this.selectedItem.classList.add('axs-ondemand-configurator__modelfilter-dropdown-item-selected');
	}

	/**
	 * @param {Event} event_ - click event
	 */
	_handleClickOnItem(event_) {
		let selectedItemValues = {};
		let clickedItem = this._getClickedItemByClickTarget(event_.target);

		this._selectItem(clickedItem);
		selectedItemValues.label = this.selectedItem.getAttribute('label');
		selectedItemValues.value = this.selectedItem.getAttribute('value');

		this.dropdown.updateDropdownStyleAfterItemSelection(selectedItemValues);
		this.dropdown._informModelfilterAboutSelectedValue(selectedItemValues);
	}

	/**
	 * @param {HTMLElement} item_ - the selected item
	 */
	_selectItem(item_) {
		if (this.selectedItem) {
			this._resetStyleSelectedItem();
		}

		this.selectedItem = item_;
		this._setStyleSelectedItem();
	}

	/**
	 * @param {HTMLElement} target_ - clicked target
	 * @returns {HTMLElement} clicked item (either target or it's parent node
	 */
	_getClickedItemByClickTarget(target_) {
		if (target_.classList.contains('axs-ondemand-configurator__modelfilter-dropdown-item')) {
			return target_;
		}
		else {
			return target_.parentNode;
		}
	}
}
