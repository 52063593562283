import {signal} from 'application-bundle';

import {accordion} from '../../../components/accordion/accordion';
import {api} from '../../../components/map/api';
import * as anchornavigation from '../../../components/anchor-navigation/anchor-navigation';
import {appmodule} from '../../../components/app-module/app-module';
import baseAccordion from '../../../components/base-accordion/base-accordion';
import baseCaroussel from '../../../components/base-caroussel/base-caroussel';
import {configuratorflat} from '../../../components/configurator-flat/configurator-flat';
import * as configuratorondemand from '../../../components/configurator-ondemand/configurator-ondemand';
import countryselector from '../../../components/country-selector/country-selector';
import {dshapi} from '../../../components/dealersearch/api';
import {dshdata} from '../../../components/dealersearch/data';
import {dshmap} from '../../../components/dealersearch/map';
import {faq} from '../../../components/faq/faq';
import faqSearchable from '../../../components/faq-searchable/faq-searchable';
import {form} from '../../../components/form/form';
import {header} from '../../../components/header/header';
import headerNavigation from '../../../components/header-navigation/header-navigation';
import hubsearch from '../../../components/hub-search/hub-search';
import icontextcondition from '../../../components/icon-text-condition/icon-text-condition';
import icontexthighlight from '../../../components/icon-text-highlight/icon-text-highlight';
import * as iframe from '../../../components/iframe/iframe';
import {infomodule} from '../../../components/info-module/info-module';
import {map} from '../../../components/map/map';
import {packageinformation} from '../../../components/packageinformation/packageinformation';
import {socialmedia} from '../../../components/socialmedia/socialmedia';
import {socialreader} from '../../../components/social/socialreader';
import {socialshare} from '../../../components/socialshare/socialshare';
import {stage} from '../../../components/stage/stage';
import {stories} from '../../../components/stories/stories';
import thumbnailtext from '../../../components/thumbnail-text/thumbnail-text';
import {usp} from '../../../components/usp-teaser/usp';

/**
 * modules to be initialized with event emitter
 */
const toBeInitialized = [
	accordion,
	api,
	appmodule,
	configuratorflat,
	dshapi,
	faq,
	form,
	header,
	infomodule,
	packageinformation,
	socialmedia,
	socialreader,
	socialshare,
	stage,
	stories,
	usp
];

/**
 * initialize modules and inject signal dependency
 */
const eventEmitter = signal.getEmitter();

toBeInitialized.forEach(module => {
	try {
		module.initialize(eventEmitter);
	}
	catch (err) {
		console.error(err);
	}
});

export {
	accordion,
	anchornavigation,
	api,
	appmodule,
	baseAccordion,
	baseCaroussel,
	configuratorflat,
	configuratorondemand,
	countryselector,
	dshapi,
	dshdata,
	dshmap,
	faq,
	faqSearchable,
	form,
	header,
	headerNavigation,
	hubsearch,
	icontextcondition,
	icontexthighlight,
	iframe,
	infomodule,
	map,
	packageinformation,
	socialmedia,
	socialreader,
	socialshare,
	stage,
	stories,
	thumbnailtext,
	usp
};
