export default class HubSearchAPI {
	/**
	 * constructor (private)
	 */
	constructor() {
		this.data = [];
		this.requestURL = this._getApiURL();
	}

	/**
	 * reads out HubSearchAPI Env Variable and use it as request url
	 * @returns {string} API url from SETUPS
	 */
	_getApiURL() {
		return SETUPS.get('HubSearchAPI');
	}

	/*
	* getHubSearchData
	* @returns {Promise} - could data be fetched (resolve) or not (reject)
	*/
	getHubSearchData() {
		let requestUrl;

		if (!this.requestURL) {
			requestUrl = SETUPS.get('DocrootAssets') + '/dummy-data/hubsearch/partners.json';
			console.warn('Component uses test data instead of hub search api data');
		}
		else {
			requestUrl = this.requestURL;
		}

		return this._requestApiData(requestUrl);
	}

	/**
	 * @param {string} requestURL_ - request
	 * @returns {Promise} - could data be fetched (resolve) or not (reject)
	 */
	_requestApiData(requestURL_) {
		return new Promise((resolve, reject) => {
			fetch(requestURL_)
				.then(response => {
					if (response.ok) {
						response.json()
							.then(data => {
								this.data = data;
								resolve();
							})
							.catch(error => {
								reject('HubSearch api data could not be used: ' + error);
							});
					}
					else {
						reject('HubSearch api data could not be loaded: no response');
					}
				})
				.catch((error) => {
					reject('HubSearch api data could not be loaded: ' + error);
				});
		});
	}

	/**
	 * getHubs
	 * @returns {Array} - array with all hub objects
	 */
	getHubs() {
		let {data} = this;
		let hubs = [];

		if (data.partners) {
			hubs = data.partners;
		}
		else {
			console.error('Missing data: partners');
		}

		return hubs;
	}

	/**
	 * @param {Array} types_ - hub type list
	 * @returns {Array} - array with all hub objects
	 */
	getHubsByTypes(types_) {
		if (this.data.partners) {
			return this.data.partners.filter(hub => {
				const hubType = this.getTypeOfHub(hub);

				return (hubType && types_.includes(hubType));
			});
		}
		else {
			console.error('Missing data: partners');
			return [];
		}
	}

	/**
	 * getNameOfHub
	 * @param {Object} hub_ - hub
	 * @returns {string} - hub name
	 */
	getNameOfHub(hub_) {
		const {name = ''} = hub_;

		return name;
	}

	/**
	 * _getAddressOfHub
	 * @param {Object} hub_ - hub
	 * @returns {Object} - hub address object
	 */
	_getAddressOfHub(hub_) {
		const {address} = hub_;

		return address;
	}

	/**
	 * getStreetOfHub
	 * @param {Object} hub_ - hub
	 * @returns {string} - hub street
	 */
	getStreetOfHub(hub_) {
		let address = this._getAddressOfHub(hub_);
		let street = '';

		if (address && address.street) {
			({street} = address);
		}

		return street;
	}

	/**
	 * getZipCodeOfHub
	 * @param {Object} hub_ - hub
	 * @returns {string} - hub zip code
	 */
	getZipCodeOfHub(hub_) {
		let address = this._getAddressOfHub(hub_);
		let zipCode = '';

		if (address && address.zipCode) {
			({zipCode} = address);
		}

		return zipCode;
	}

	/**
	 * getCityOfHub
	 * @param {Object} hub_ - hub
	 * @returns {string} - hub zip code
	 */
	getCityOfHub(hub_) {
		let address = this._getAddressOfHub(hub_);
		let city = '';

		if (address && address.city) {
			({city} = address);
		}

		return city;
	}

	/**
	 * getMapMarkerDataOfHub
	 * @param {Object} hub_ - hub
	 * @returns {Object} - marker
	 */
	getMapMarkerDataOfHub(hub_) {
		let marker = {};
		let address = this._getAddressOfHub(hub_);

		if (address && address.latitude && address.longitude) {
			marker.lat = address.latitude;
			marker.lng = address.longitude;
			marker.type = this.getTypeOfHub(hub_);
		}
		else {
			console.warn('Missing Marker Data: address.latitude + address.longitude');
		}

		return marker;
	}

	/**
	 * getMarkerDataOfHubs
	 * @param {Array} hubs_ - hubs array
	 * @returns {Array} - array with single marker data for each hub
	 */
	getMarkerDataOfHubs(hubs_) {
		let hubsLength = hubs_.length;
		let markers = [];

		for (let index = 0; index < hubsLength; index++) {
			let hub = hubs_[index];
			let marker = this.getMapMarkerDataOfHub(hub);

			markers.push(marker);
		}

		return markers;
	}

	/**
	 * getEmailOfHub
	 * @param {Object} hub_ - hub
	 * @returns {string} - email
	 */
	getEmailOfHub(hub_) {
		let contactDetails = this._getContactDetails(hub_);
		let email = '';

		if (contactDetails && contactDetails.email) {
			({email} = contactDetails);
		}

		return email;
	}

	/**
	 * getPhoneNumberInternationalOfHub
	 * @param {Object} hub_ - hub
	 * @returns {string} - internationalPhoneNumber
	 */
	getPhoneNumberInternationalOfHub(hub_) {
		let contactDetails = this._getContactDetails(hub_);
		let phoneInternational = '';

		if (contactDetails && contactDetails.phone.international) {
			phoneInternational = contactDetails.phone.international;
		}

		return phoneInternational;
	}

	/**
	 * getPhoneUrlOfHub
	 * @param {Object} hub_ - hub
	 * @returns {string} - phone url
	 */
	getPhoneUrlOfHub(hub_) {
		let contactDetails = this._getContactDetails(hub_);
		let phoneUrl = '';

		if (contactDetails && hub_.contactDetails.phone.url) {
			phoneUrl = hub_.contactDetails.phone.url;
		}

		return phoneUrl;
	}

	/**
	 * getNotesOfHub
	 * @param {Object} hub_ - hub
	 * @returns {string} - notes
	 */
	getNotesOfHub(hub_) {
		const {notes = ''} = hub_;

		return notes;
	}

	/**
	 * @param {Object} hub_ - hub
	 * @returns {Array} - opening times
	 */
	getOpeningHoursOfHub(hub_) {
		let openingHours = {};

		if (hub_.departments && hub_.departments.length > 0 && hub_.departments[0].openingHours) {
			openingHours.note = hub_.departments[0].openingHours.note;
			openingHours.openingHours = this._prepareOpeningHours(hub_.departments[0].openingHours.openingHours);
		}

		return openingHours;
	}

	/**
	 * @param {Object} openingHours_ - opening hours given by the api
	 * @returns {Array} - array of opening hours
	 */
	_prepareOpeningHours(openingHours_) {
		let openingHoursCombinedArray = this._getOpeningHoursCombinedArray(openingHours_);

		return this._getOpeningHoursSortedArray(openingHoursCombinedArray);
	}

	/**
	 * @param {Array} openingHoursCombinedArray_ - array of opening hours
	 * @returns {Array} - sorted array of opening hours
	 */
	_getOpeningHoursSortedArray(openingHoursCombinedArray_) {
		let currentDayOfWeek = this._getCurrentDayOfWeek();
		let openingHoursSortedArray = openingHoursCombinedArray_;
		let openingHoursSortedArrayPartOne = [];
		let openingHoursSortedArrayPartTwo = [];

		if (openingHoursCombinedArray_[currentDayOfWeek - 1].length > 0) {
			openingHoursSortedArrayPartOne = openingHoursCombinedArray_.slice(0, currentDayOfWeek - 1);
			openingHoursSortedArrayPartTwo = openingHoursCombinedArray_.slice(currentDayOfWeek - 1, openingHoursCombinedArray_.length);

			openingHoursSortedArray = openingHoursSortedArrayPartTwo.concat(openingHoursSortedArrayPartOne);
		}

		return openingHoursSortedArray;
	}

	/**
	 * @returns {string} dayname of today
	 */
	_getCurrentDayOfWeek() {
		let date = new Date();

		return date.getDay();
	}

	/**
	 *
	 * @param {Array} openingHours_ - array of opening hours
	 * @returns {Array} - array of combined opening hours (combined by day of week)
	 */
	_getOpeningHoursCombinedArray(openingHours_) {
		let openingHoursArray = [[], [], [], [], [], [], []];

		openingHours_.forEach((currentItem) => {
			openingHoursArray[currentItem.dow - 1].push(currentItem);
		});

		return openingHoursArray;
	}

	/**
	 * getImageUrlOfHub
	 * @param {Object} hub_ - hub
	 * @returns {string} - image url
	 */
	getImageUrlOfHub(hub_) {
		let imageUrl = '';

		if (hub_.imgUrl) {
			imageUrl = hub_.imgUrl;
		}

		return imageUrl;
	}

	/**
	 * getUrlOfHub
	 * @param {Object} hub_ - hub
	 * @returns {string} - hub url
	 */
	getUrlOfHub(hub_) {
		const {url = ''} = hub_;

		return url;
	}

	/**
	 * _getContactDetails
	 * @param {Object} hub_ - hub
	 * @returns {Object} - contact details object
	 */
	_getContactDetails(hub_) {
		let {contactDetails} = hub_;

		return contactDetails;
	}

	/**
	 * @param {Object} hub_ - hub
	 * @returns {string} lower case string of the hub's type: 'private' or 'public'
	 */
	getTypeOfHub(hub_) {
		let type = '';

		if (hub_.services) {
			let {services} = hub_;

			if (services.indexOf('PRIVATE_HUB') > -1) {
				type = 'private';
			}

			if (services.indexOf('PUBLIC_HUB') > -1) {
				type = 'public';
			}
		}
		else {
			console.warn('Missing hub data: services');
		}

		return type;
	}
}
