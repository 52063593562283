import CarousselSwipe from './caroussel-swipe';

export default class Caroussel extends HTMLElement {

	static get ANIMATION_TIME() { return 800; }

	constructor() {
		super();
		this.reachedBeginningOfCaroussel = this._reachedBeginningOfCaroussel.bind(this);
		this.reachedEndOfCaroussel = this._reachedEndOfCaroussel.bind(this);
		this.setCarousselWidthToInteger = this._setCarousselWidthToInteger.bind(this);
	}

	connectedCallback() {
		this._initialize();
	}

	disconnectedCallback() {
		this._removeEvents();
	}

	_initialize() {
		this.carousselInputs = this.querySelectorAll('[name="audi-axs-caroussel-pagination"]');

		if (this.carousselInputs.length > 0) {
			this._initializeCarousselSwipe();
			this._addEvents();
		}

		this._setCarousselWidthToInteger();
	}

	_initializeCarousselSwipe() {
		this.CarousselSwipe = new CarousselSwipe(this);
	}

	_addEvents() {
		let carousselFirstInput = this.carousselInputs[0];
		let carousselLastInput = this.carousselInputs[this.carousselInputs.length - 1];

		carousselFirstInput.addEventListener('change', this.reachedBeginningOfCaroussel);
		carousselLastInput.addEventListener('change', this.reachedEndOfCaroussel);

		window.addEventListener('resize', this.setCarousselWidthToInteger);
	}

	_removeEvents() {
		let carousselFirstInput = this.carousselInputs[0];
		let carousselLastInput = this.carousselInputs[this.carousselInputs.length - 1];

		carousselFirstInput.removeEventListener('change', this.reachedBeginningOfCaroussel);
		carousselLastInput.removeEventListener('change', this.reachedEndOfCaroussel);

		window.removeEventListener('resize', this.setCarousselWidthToInteger);
	}

	_reachedEndOfCaroussel() {
		this._setCarousselPositionWithoutTransition(1);
	}

	_reachedBeginningOfCaroussel() {
		this._setCarousselPositionWithoutTransition(this.carousselInputs.length - 2);
	}

	/**
	 * @param {number} inputPosition - the position of the input
	 */
	_setCarousselPositionWithoutTransition(inputPosition) {
		setTimeout(
			() => {
				this.isTransitionEnabled = false;
				this._setCarousselPosition(inputPosition);
				window.requestAnimationFrame(() => {
					this.isTransitionEnabled = true;
				});
			},
			Caroussel.ANIMATION_TIME
		);
	}

	/**
	 * @param {number} inputPosition - the position of the input
	 */
	_setCarousselPosition(inputPosition) {
		let carousselFirstInput = this.carousselInputs[inputPosition];

		carousselFirstInput.checked = true;
	}

	/**
	 * fix for the peep out of preceding and following images
	 */
	_setCarousselWidthToInteger() {
		this.style.width = '';
		this.style.width = this.clientWidth + 'px';
	}

	/**
	 * @returns {number} - index of active input
	 */
	_getActiveInputIndex() {
		let carousselInputsLength = this.carousselInputs.length;

		for (let key = 0; key < carousselInputsLength; key++) {
			if (this.carousselInputs[key].checked === true) {
				return key;
			}
		}

		return 0;
	}

	moveBack() {
		let nextActiveInputIndex = this._getActiveInputIndex() - 1;
		let nextActiveInput = this.carousselInputs[nextActiveInputIndex];

		nextActiveInput.checked = true;

		if (nextActiveInputIndex === 0) {
			this._setCarousselPositionWithoutTransition(this.carousselInputs.length - 2);
		}
	}

	moveForward() {
		let nextActiveInputIndex = this._getActiveInputIndex() + 1;
		let nextActiveInput = this.carousselInputs[nextActiveInputIndex];

		nextActiveInput.checked = true;

		if (nextActiveInputIndex === this.carousselInputs.length - 1) {
			this._setCarousselPositionWithoutTransition(1);
		}
	}

	get isTransitionEnabled() {
		return this.getAttribute('is-transition-enabled') === 'true';
	}
	set isTransitionEnabled(val) {
		this.setAttribute('is-transition-enabled', val);
	}
	get carousselId() {
		return this.id;
	}
}

if (window.customElements.get('audi-axs-caroussel') === undefined) {
	window.customElements.define('audi-axs-caroussel', Caroussel);
}
