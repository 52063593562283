import {dom} from 'core-utils';

var __ = {},
	exports = {__};

__.oDefaults = {
	shareToggleSelector: '#axs-md-share-toggle'
};

__.addEvents = function() {
	var domDelegate = dom.getEventDelegate('body');

	domDelegate.on('click', __.oDefaults.shareToggleSelector, __.handleToggleClick);
};

/**
 * v
 * @param {Event} event_ - Event
 */
__.handleToggleClick = function(event_) {
	var isChecked = event_.target.checked === true;

	document.removeEventListener('click', __.checkForOutsideClicks, true);

	// add body listener for outsideClicks
	if (isChecked) {
		document.addEventListener('click', __.checkForOutsideClicks, true);
	}
};

/**
 * checkForOutsideClicks
 * @param {Event} event_ - Event
 */
__.checkForOutsideClicks = function(event_) {
	var targetItem = event_.target,
		container = dom.closest(targetItem, '.axs-md-share-list'),
		label = dom.closest(targetItem, '.axs-md-share-toggle-label'),
		isOutsideFlyout = (!dom.isElement(container) && !dom.isElement(label));

	// uncheck flyout if click was outside flyout or checkbox/input
	if (isOutsideFlyout) {
		document.removeEventListener('click', __.checkForOutsideClicks, true);
		dom.getElement(__.oDefaults.shareToggleSelector).checked = false;
	}
};

/**
 * public initialize method
 * @param {EventBus} globalEventBus - globalEventBus
 * @returns {Promise} returns Promise
 */
exports.initialize = function(globalEventBus) {
	return new Promise(resolve => {
		__.globalEventBus = globalEventBus;
		__.addEvents();
		resolve('socialmedia.js');
	});
};

export {exports as socialmedia};
