import {dom} from 'core-utils';

export default class FAQSearchable extends HTMLElement {
	// constants
	static get CLASS_QUESTION_HIDDEN() {
		return 'axs-faq__question--hidden';
	}

	static get CLASS_RESULT_COUNT_TEXT_HIDDEN() {
		return 'axs-faq__result-count-text--hidden';
	}

	static get CLASS_FORM_SEARCHWORD_EMPTY() {
		return 'axs-faq__form--searchword-empty';
	}

	/**
	 * connectedCallback - callback for adding element to dom/shadow dom
	 */
	connectedCallback() {
		if (this._isSearchable()) {
			this._initialize();
			this._bindEvents();
		}
	}

	/**
	 * @returns {boolean} - true module if it has a search panel
	 */
	_isSearchable() {
		return (this.querySelector('.axs-faq__search-panel-wrapper') !== null);
	}

	async _initialize() {
		this.lastSearchWord = undefined;
		this.handleSearchWordInputChange = dom.debounce(this._handleSearchWordInputChange, 200).bind(this);
		this.handleReset = this._handleReset.bind(this);

		this._initializeElements();
		await this._initializeSynonymsLists();

		// perhaps we already have got one or more search words entered
		this._handleSearchWordInputChange();
	}

	async _initializeElements() {
		this.searchForm = this.querySelector('.axs-faq__search-form');
		this.searchInput = this.querySelector('.axs-faq__search-input');
		this.questions = this.querySelectorAll('.axs-faq__question');
		this.groups = this.querySelectorAll('.axs-faq__group');
		this.resetButton = this.querySelector('.axs-faq__reset-search');
	}

	/**
	 * @returns {Promise|undefined} - fetching synonyms list promise (or nothing without synonymsPath)
	 */
	_initializeSynonymsLists() {
		const synonymsPath = this.getAttribute('data-synonyms-path');

		this.synonymsLists = [];

		if (synonymsPath) {
			return fetch(synonymsPath)
				.then(response_ => response_.json())
				.then(({Synonyms: synonymsLists_}) => { // received JSON has "Synonyms" key
					synonymsLists_.forEach(synonymsList_ => {
						this.synonymsLists.push(FAQSearchable._createSynonymsRegexMatcher(synonymsList_));
					});
				})
				.catch((error_) => {
					console.error(`Loading of synonyms from ${synonymsPath} failed:`, error_);
				});
		}
	}

	/**
	 * Creates a regex snippet for matching synonyms. Expected format are CSV as supplied by the API.
	 * Commas are replaced by | (regex OR), useless spaces around commas and useless commas are stripped, intended spaces inside words are replaced with placeholders
	 * @param {string} synonyms_ - comma separated synonyms
	 * @returns {string} a regex matcher for synonyms
	 */
	static _createSynonymsRegexMatcher(synonyms_) {
		return synonyms_
			.replace(/(\s?,\s?){2,}/g, ',') // remove multiple commas
			.replace(/(^,|,$)/g, '') // remove starting or trailing commas
			.replace(/\s*,\s*/g, '|') // remove useless spaces around commas and replace commas with |
			.trim()
			.replace(/ /g, '.?') // Replace wanted spaces with .? matchers
			.replace(/([^|]+)/g, '\\b$1\\b'); // only match between word boundaries, not partial synonyms
	}

	_bindEvents() {
		this.searchForm.addEventListener('submit', this._handleFormSubmit);
		this.searchForm.addEventListener('reset', this.handleReset);
		this.searchInput.addEventListener('keyup', this.handleSearchWordInputChange);
		this.searchInput.addEventListener('change', this.handleSearchWordInputChange);
	}

	/**
	 * @param {HTMLElement} question_ - question item
	 * @returns {TextNode[]} - array with text nodes of questions
	 */
	static _getTextNodesForQuestion(question_) {
		const textNodes = [];
		const walker = document.createTreeWalker(question_, NodeFilter.SHOW_TEXT, null, false);
		let node;

		while ((node = walker.nextNode())) {
			const text = node.textContent.trim();

			if (text.length) {
				textNodes.push(node);
			}
		}

		return textNodes;
	}

	/**
	 * @param {Event} event_ - submit event
	 */
	_handleFormSubmit(event_) {
		event_.preventDefault();
	}

	/**
	 * Reset the input field (and show all the questions)
	 */
	_handleReset() {
		this.searchInput.value = '';
		this._handleSearchWordInputChange();
	}

	_handleSearchWordInputChange() {
		// sanitize string (clean possibly malicious characters and trim)
		const searchWords = FAQSearchable._escapeRegexCharacters(this.searchInput.value).trim();

		if (this.lastSearchWords === searchWords) {
			return;
		}

		this.lastSearchWords = searchWords;

		this._unHighlightMatches();

		if (searchWords.length > 1) {
			const searchWordsWithSynonyms = this._enrichWithSynonyms(searchWords);

			this._searchForWords(searchWordsWithSynonyms);
			this._updateResultCount();
		}
		else {
			this._showAllQuestions();
			this._hideResultCountTexts();
		}

		this._setGroupsVisibility();
		this._setResetButtonVisibility();
	}

	/**
	 * escapes characters which can interfere a later usage in regular expressions (like | [ ] \ { } etc.)
	 * @param {string} value_ - string to be cleaned
	 * @returns {string} cleaned string
	 */
	static _escapeRegexCharacters(value_) {
		return value_.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
	}

	/**
	 * @param {string} searchWords_ - search word(s)
	 * @returns {string} searchWords with synonyms if there are
	 */
	_enrichWithSynonyms(searchWords_) {
		let searchWords = searchWords_;
		const enrichedSearchWords = [];

		for (const synonymsRegexMatcher of this.synonymsLists) {
			const synonymsRegex = new RegExp(synonymsRegexMatcher, 'i');

			if (FAQSearchable._searchWordContainsSynonyms(synonymsRegex, searchWords)) {
				const replacedSearchWord = searchWords.match(synonymsRegex)[0].replace(/ /g, '.?'); // keep partial match

				enrichedSearchWords.push(synonymsRegexMatcher + '|' + replacedSearchWord);

				// remove words from the original search word string that will be processed by the freshly added synonymsRegexMatcher
				searchWords = FAQSearchable._stripMatchedSynonymWordsFromSearchWords(synonymsRegexMatcher, searchWords);
			}
		}

		if (searchWords.length > 0) {
			// push all the search words that are not affected by synonyms.
			enrichedSearchWords.push(searchWords);
		}

		return FAQSearchable._joinAndCleanEnrichedSearchWords(enrichedSearchWords);
	}

	/**
	 * @param {RegExp}_synonymsRegex - regular expression that should match if searchWords_ contain a synonymable word
	 * @param {string} searchWords_ - the search words
	 * @returns {boolean} true if searchWords_ contains synonym
	 */
	static _searchWordContainsSynonyms(_synonymsRegex, searchWords_) {
		return _synonymsRegex.test(searchWords_);
	}

	/**
	 * @param {string}_synonymsRegexMatcher - regular expression that matches if searchWords_ contain a synonym
	 * @param {string} searchWords_ - the search words
	 * @returns {string} stripped searchWords
	 */
	static _stripMatchedSynonymWordsFromSearchWords(_synonymsRegexMatcher, searchWords_) {
		return searchWords_.replace(new RegExp('(' + _synonymsRegexMatcher + ')', 'gi'), '').trim();
	}

	/**
	 * @param {string[]} enrichedSearchWords_ - array mixed of synonym matchers and non-synonym search words
	 * @returns {string} space-joined enrichedSearchWords_ string in which multiple white spaces are removed
	 */
	static _joinAndCleanEnrichedSearchWords(enrichedSearchWords_) {
		return enrichedSearchWords_.join(' ').replace(/\s+(?=\s)/g, '').trim();
	}

	/**
	 * @param {string} searchWords_ - search word(s)
	 */
	_searchForWords(searchWords_) {
		Array.from(this.questions).forEach(question_ => {
			if (FAQSearchable._questionContainsAllWords(question_, searchWords_)) {
				FAQSearchable._highlightMatches(question_, searchWords_);
				FAQSearchable._showQuestion(question_);
			}
			else {
				FAQSearchable._hideQuestion(question_);
			}
		});
	}

	/**
	 * Creates a regular expression object based on a given searchWords_ string. The string parameter
	 * is split at spaces and will be combined to an AND-matcher
	 * @param {string} searchWords_ - search word(s)
	 * @returns {RegExp} AND matching Regular Expression based on searchWords_
	 */
	static _regExAnd(searchWords_) {
		const rexStringUnwrapped = searchWords_.replace(/ /g, '))(?=.*(');
		const fullRex = '^(?=.*(' + rexStringUnwrapped + ')).*$';

		return RegExp(fullRex, 'gmi');
	}

	/**
	 * Creates a regular expression object based on a given searchWords_ string. The string parameter
	 * is split at spaces and will be combined to an OR-matcher
	 * @param {string} searchWords_ - search word(s)
	 * @returns {RegExp} OR matching Regular Expression based on searchWords_
	 */
	static _regExOr(searchWords_) {
		return RegExp(searchWords_.replace(/ /g, '|'), 'gmi');
	}

	/**
	 * Returns true if the question contains all the search words
	 * @param {HTMLElement} question_ - question section
	 * @param {string} searchWords_ - search word(s)
	 * @returns {boolean} - contains the search data entry the given search word
	 */
	static _questionContainsAllWords(question_, searchWords_) {
		const regex = FAQSearchable._regExAnd(searchWords_);

		return regex.test(question_.textContent.replace(/\s+(?=\s)/g, '').trim());
	}

	/**
	 * @param {HTMLElement} question_ - question element
	 * @param {string} searchWords_ - search word
	 */
	static _highlightMatches(question_, searchWords_) {
		const regex = FAQSearchable._regExOr(searchWords_);
		const textNodes = FAQSearchable._getTextNodesForQuestion(question_);
		const textNodesLength = textNodes.length;

		for (let i = 0; i < textNodesLength; i++) {
			let match;
			let node = textNodes[i];

			while ((match = regex.exec(node.textContent)) !== null) {
				let pos = match.index;

				node = FAQSearchable._markMatchInTextNode(node, pos, pos + match[0].length);
				regex.lastIndex = 0;
			}
		}
	}

	/**
	 * Wrap a TextNode in a mark HTML element
	 * @param {TextNode} node_ - TextNode with match
	 * @param {number} start_ - match start position
	 * @param {number} end_ - match end position
	 * @returns {TextNode} - remaining TextNode after match (after the new marker)
	 */
	static _markMatchInTextNode(node_, start_, end_) {
		const startNode = node_.splitText(start_);
		const remainNode = startNode.splitText(end_ - start_);
		const marker = document.createElement('mark');

		marker.classList.add('axs-faq__text-highlight');
		marker.textContent = startNode.textContent;
		startNode.parentNode.replaceChild(marker, startNode);

		return remainNode;
	}

	/**
	 * Removes all the mark elements and glues together the resulting text nodes
	 */
	_unHighlightMatches() {
		const markers = this.querySelectorAll('.axs-faq__text-highlight');
		const markersLength = markers.length;

		for (let i = 0; i < markersLength; i++) {
			let textNodeWithoutMarker = document.createTextNode(markers[i].textContent);

			markers[i].parentNode.replaceChild(textNodeWithoutMarker, markers[i]);
		}

		this.normalize();
	}

	/**
	 * @param {HTMLElement} question_ - question to show
	 */
	static _showQuestion(question_) {
		question_.classList.remove(FAQSearchable.CLASS_QUESTION_HIDDEN);
	}

	/**
	 * @param {HTMLElement} question_ - question to hide
	 */
	static _hideQuestion(question_) {
		question_.classList.add(FAQSearchable.CLASS_QUESTION_HIDDEN);
	}

	/**
	 * Applies the visibilty of the reset button based on the result count.
	 */
	_setResetButtonVisibility() {
		const searchWord = this.searchInput.value;

		if (searchWord.length > 0) {
			this._showResetButton();
		}
		else {
			this._hideResetButton();
		}
	}

	_showResetButton() {
		this.searchForm.classList.remove(FAQSearchable.CLASS_FORM_SEARCHWORD_EMPTY);
	}

	_hideResetButton() {
		this.searchForm.classList.add(FAQSearchable.CLASS_FORM_SEARCHWORD_EMPTY);
	}

	_updateResultCount() {
		const resultCount = this.querySelectorAll('.axs-faq__question:not(.axs-faq__question--hidden)').length;

		this._hideResultCountTexts();

		if (resultCount > 0) {
			this.querySelector('.axs-faq__result-count-text--with-result').classList.remove(FAQSearchable.CLASS_RESULT_COUNT_TEXT_HIDDEN);
			this.querySelector('.axs-faq__result-count-number').innerText = resultCount;
		}
		else {
			this.querySelector('.axs-faq__result-count-text--without-result').classList.remove(FAQSearchable.CLASS_RESULT_COUNT_TEXT_HIDDEN);
		}
	}

	_hideResultCountTexts() {
		this.querySelector('.axs-faq__result-count-text--with-result').classList.add(FAQSearchable.CLASS_RESULT_COUNT_TEXT_HIDDEN);
		this.querySelector('.axs-faq__result-count-text--without-result').classList.add(FAQSearchable.CLASS_RESULT_COUNT_TEXT_HIDDEN);
	}

	_showAllQuestions() {
		const hiddenQuestions = this.querySelectorAll('.' + FAQSearchable.CLASS_QUESTION_HIDDEN);
		const hiddenQuestionsLength = hiddenQuestions.length;

		for (let i = 0; i < hiddenQuestionsLength; i++) {
			FAQSearchable._showQuestion(hiddenQuestions[i]);
		}
	}

	/**
	 * This method checks if the group of questions has any visible questions inside. Hides the group if there are none.
	 */
	_setGroupsVisibility() {
		const groupsLength = this.groups.length;

		for (let i = 0; i < groupsLength; i++) {
			if (FAQSearchable._groupContainsVisibleQuestion(this.groups[i])) {
				this.groups[i].classList.remove('axs-faq__group--hidden');
			}
			else {
				this.groups[i].classList.add('axs-faq__group--hidden');
			}
		}
	}

	/**
	 * @param {HTMLElement} group_ - group section
	 * @returns {boolean} true if group contains a question which is not hidden
	 */
	static _groupContainsVisibleQuestion(group_) {
		const numberOfVisibleQuestions = group_.querySelectorAll('.axs-faq__question:not(.axs-faq__question--hidden)').length;

		return (numberOfVisibleQuestions > 0);
	}
}

customElements.define('audi-axs-faq', FAQSearchable);
