const template = `
<article>

	<div class="map-detail-header clearfix">
		{{?!!it.product.image}}
		<img src="{{=it.product.image}}" alt="{{=it.product.imagealttext}}">
		{{?}}
	</div>

	<div class="map-detail-main">
		{{?!!it.product}}
			<p class="axs-el-lbl axs-el-lbl-06">{{=it.product.label}}</p>
			<h3 class="axs-el-hdl axs-el-hdl-03">{{=it.product.headline}}</h3>
			<p class="axs-el-pg axs-el-pg-04">{{=it.product.text}}</p>
				{{?!!it.product.button.linklayer && it.product.button.linklayer !== "false"}}
					<a href="{{=it.product.button.url}}" target="{{=it.product.button.target}}" class="axs-layer-link axs-button-standard">{{=it.product.button.linktext}}</a>
				{{?? true}}
					<a href="{{=it.product.button.url}}" target="{{=it.product.button.target}}" class="axs-button-standard">{{=it.product.button.linktext}}</a>
				{{?}}
			{{?}}
	</div>

</article>
`;

export {template as mapPartnerDetailsTemplate};
